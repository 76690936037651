import React,{useEffect, useState} from 'react';
import { Box, Card, styled, CardContent, TextField, Grid, Button, Typography, InputAdornment, Snackbar, Alert, Link, Tabs, Tab } from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { checkImageFileExt, getTextColor, handleImageForCompress, isValidHttpUrl, validateImgDimension } from 'src/utils';
import CloseIcon from '@mui/icons-material/Close';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {connect} from 'react-redux';
import { saveSettingsPreviewData, updateRewardSettings, brandLogoUpload, brand_subdomain_availability_check, setToast } from 'src/services/actions'

import {gaEvent} from "../../utils";
import AdvancedSettings from './advanced';

const PreviewButton = styled(Button)(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    background: #ECFBE6;
    border-radius: 6px;
    color: #3BB900;
    &:hover{
        background: #ECFBE6;
    }
  `
)

const ColorTextField = styled(TextField)({
  '& [type="color"]': {
    height: "4.4375em",
    padding: "9px 0 0",
    '&::-webkit-color-swatch': {
      borderRadius: "4px",
      border: 'none',
    }
  }
})

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, px:1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const ClaimSettings = (props) => {
  const ALPHA_DASH_REGEX = /^[A-Za-z0-9]+$/;

  const vertical = 'top';
  const horizontal = 'center';
  
  const [tabValue, setTabValue] = useState(0)
  const [isSubmit, setIsSubmit] = useState(false);
  const [settingsUpdateAlert, setSettingsUpdateAlert] = useState({type: '', message: ''})
  
  const [currentSubDomain, setCurrentSubDomain] = useState("");
  
  const {user} = props
  const [validLogoUrl, setValidLogoUrl] = useState({val: user.data.logo_url, error: ""});


  useEffect(() => {
    gaEvent({
      category: "User Settings",
      label:`-${user.data.eth_address}-`,
      action: `user_settings`
    });
    setCurrentSubDomain(user.data.brand_handle);

    // clear all intervals
    for (let i = 0; i < 9999; i++) {
      window.clearInterval(i)
    }
  }, []);
  
  const [rewardPageSettings, setRewardPageSettings] = useState({
    brand_name: user.data?.brand_name,
    background: user.data?.brand_data?.background,
    primary: user.data?.brand_data?.primary,
    accent: user.data?.brand_data?.accent,
    image: user.data?.brand_logo,
    logoUrl: user.data?.logo_url
  });
  const [colorInvalid, setColorInvalid] = useState(false)
  const [colorData, setColorData] = useState({
    background: {val: user.data?.brand_data?.background, error: ""},
    primary: {val: user.data?.brand_data?.primary, error: ""},
    accent: {val: user.data?.brand_data?.accent, error: ""}
  })
  const [allowPreviewDisable, setAllowPreviewDisable] = useState(false)
  const [defaultLogoUrl, setDefaultLogoUrl] = useState('/images/dummy_logo_white.svg');
  const [imgPreviewUrl, setImgPreviewUrl] = useState(() => () => {
    if (rewardPageSettings.image == null){
      const txtColor = getTextColor(user.data.brand_data.background,"#ffffff");
      if (txtColor === '#000000'){
        return '/images/dummy_logo_black.svg';
      } else {
        return '/images/dummy_logo_white.svg';
      }
    } else {
      return rewardPageSettings.image
    }

    // // rewardPageSettings.image == null ? defaultLogoUrl : rewardPageSettings.image
  });
  useEffect(() => {
    if(user.type == "success"){
      setRewardPageSettings({
        ...rewardPageSettings, subdomain: user.data.brand_handle,background:user.data.brand_data?.background,primary:user.data.brand_data?.primary,accent:user.data.brand_data?.accent, image: user.data.brand_logo
      })
    }
  }, [user])

  // console.log("defaultLogoUrl =>",defaultLogoUrl)

  useEffect(() => {
    if(user.data.brand_logo != "" && user.data.brand_logo != rewardPageSettings.image) {
      if (rewardPageSettings.image == "" || rewardPageSettings.image == null){
        setAllowPreviewDisable(false);
        setImgPreviewUrl(defaultLogoUrl);
      } else {
        setAllowPreviewDisable(true)
        brandLogoUpload(rewardPageSettings.image,'logo', (res) => {
          if(res.type == 'success') {
            setImgPreviewUrl(res.s3url)
          } else {
            setImgPreviewUrl(defaultLogoUrl)
          }
          setAllowPreviewDisable(false);
        })
      }
    } else {
      if (user.data.brand_logo === "" || user.data.brand_logo === null) {
        setImgPreviewUrl(defaultLogoUrl);
      }
    }
  },[rewardPageSettings.image, user.data.brand_logo, defaultLogoUrl])

  // useEffect(() => {
  //   console.log("rewardPageSettings.background =>",rewardPageSettings.background)
  // },[rewardPageSettings.accent, rewardPageSettings.background, rewardPageSettings.primary])

  const handleColorSettings = (type, val) => {
    let existing_data = {...colorData}
    
    if (/^#[0-9A-F]{6}$/i.test(val)){
      existing_data[type] = {val, error: ""}
      // setColorData({...colorData, [type]: {val: val, error: ""}})
    } else {
      existing_data[type] = {val, error: "Please enter a valid colour code."}
      // setColorData({...colorData, [type]: {val: val, error: "Please enter a valid colour code."}})
    }
    setColorData(existing_data)
  }


  const handlePageSettings =async (type, val) => {
    let existing_data = {...rewardPageSettings};
    val = val.slice(0,1) === "#" ? val.slice(1) : val;
    existing_data[type] = val
    
    setRewardPageSettings(existing_data)
  }


  const handlePageSubdomain =async (val) => {
    let existing_data = {...rewardPageSettings};
    // if (val !== "" && !ALPHA_DASH_REGEX.test(val)) {
    //   return;
    // }
    existing_data['subdomain'] = val
    setRewardPageSettings(existing_data)
  }

  const handleLogoUrl =async (val) => {
    let existing_data = {...rewardPageSettings};
    
    existing_data['logoUrl'] = val
    setRewardPageSettings(existing_data)
  }

  const handleLogoUrlSetting = (logoUrl) => {
    var url_regex = /^(ftp|http|https):\/\/[^ "]+$/;
    if(logoUrl != null && logoUrl != ""){
      if(url_regex.test(logoUrl) == false){
        setValidLogoUrl({val: logoUrl, error: "Please enter a valid URL."})
      } else {
        setValidLogoUrl({val: logoUrl, error: ""})
      }
    } else {
      
      setValidLogoUrl({val: "", error: ""})
    }
  }

  const onSelectFileOld = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>{
        handleFileSelect('image', reader.result.toString())
      });

      reader.readAsDataURL(e.target.files[0]);
      document.getElementById('file_upload').value = '';
    }
  }

  const onSelectFile = (e) => {
    const selectedFile = e.target.files[0];
    validateImgDimension(selectedFile, 400, 250, 400, 250).then((res) => {
      if (res.type === 'error') {
        let message = "Upload an image with minimum size 400x250";
        props.setToast(true, { type: "end_error", message: message });
      } else {
        if (selectedFile) {
          handleImageForCompress(selectedFile, res.fileWidth, res.fileHeight)
            .then((compressedDataURL) => {
              // console.log('Compressed Image Data URL:', compressedDataURL);
              handleFileSelect('image', compressedDataURL)
            })
            .catch((error) => {
              console.error('Error compressing image:', error);
            });
        }
      }
    })
    
      
    document.getElementById('file_upload').value = '';
  };

  const handleFileSelect =(type,fileobj)=>{
    let existing_data = {...rewardPageSettings}
    existing_data[type] = fileobj
    setRewardPageSettings(existing_data)
  }
  const removeImage =(e)=>{
    let existing_data = {...rewardPageSettings}
    existing_data['image'] = ""
    setRewardPageSettings(existing_data)
  }


  const triggerImage = (e) => {
    let preview = document.getElementById("image_preview");
    if(e.target.id !="addImage" && e.target.id !="file_upload" && !preview.contains(e.target)){
      document.getElementById('addImage').click();
    }
  };

  const updateRewardPageSettings = () => {
    setIsSubmit(true)

    props.updateRewardSettings(rewardPageSettings, (resp) => {
      if (resp.type == 'success') {
        // for domain message
        // setCurrentSubDomain(rewardPageSettings.subdomain);
        props.setToast(true, {type: 'success', message: 'Your settings have been updated.'});
        // setSettingsUpdateAlert({type: 'success', message: 'Your settings have been updated.'})

        gaEvent({
          category: "Custom Reward Settings Updated",
          label:`-${user.data.eth_address}-`,
          action: `custom_reward_settings_updated`
        });

      } else {
        gaEvent({
          category: `Custom Reward Settings error ${resp.message}`,
          label:`-${user.data.eth_address}-`,
          action: `custom_reward_settings_update_error`
        });
        props.setToast(true, {type: 'error', message: resp.message});
        // setSettingsUpdateAlert({type: 'error', message: resp.message})
      }
      setIsSubmit(false)
    })

    props.saveSettingsPreviewData('membership', {brand_data: rewardPageSettings}, (resp) => {})
  }
  const resetRewardPageSettings = () => {
    setRewardPageSettings({
      subdomain: user.data.brand_handle,
      logoUrl: '',
      background: '0A2033',
      primary: '0C8BF4',
      accent: '79BFFA',
      image: ''
    })
  }

  const [isBrandSubdomainAvailable, setIsBrandSubdomainAvailable] = useState({type: 'success', message: ''})
  

  useEffect(() => {
    if (rewardPageSettings.background) {
      try{
        const txtColor = getTextColor(rewardPageSettings.background,"#ffffff");
        // console.log("txtColor =>",txtColor);
        if (txtColor === '#000000'){
          setDefaultLogoUrl('/images/dummy_logo_black.svg');
        } else {
          setDefaultLogoUrl('/images/dummy_logo_white.svg');
        }
      }catch(e) {
        setDefaultLogoUrl('/images/dummy_logo_white.svg');
      }
    }
  },[rewardPageSettings.background, rewardPageSettings.image])

  const checkBrandHandleAvailability = (val) => {
    if (val !== "" && !ALPHA_DASH_REGEX.test(val)) {
      setIsBrandSubdomainAvailable({type: 'error', message: 'Invalid Subdomain'});
      return
    }
    props.brand_subdomain_availability_check(val, (resp) => {
      if(resp.type == 'error'){
        setIsBrandSubdomainAvailable({type: 'error', message: 'Subdomain is not available'})
      } else {
        setIsBrandSubdomainAvailable({type: 'success', message: ''})
      }
    })
  }

  const previewBtnClick = () => {
    if (Object.values(colorData).filter((c) => c.error === "").length == 3) {
      window.open(`${user.data.full_brand_domain}/preview/?backgroundColor=${rewardPageSettings.background}&primaryColor=${rewardPageSettings.primary}&accentColor=${rewardPageSettings.accent}&bannerUrl=${user.data?.community_page_info?.banner_url == '' ? '' : user.data?.community_page_info?.banner_url}&pageTitle=${user.data?.page_title != undefined ? encodeURIComponent(user.data?.page_title) : ''}&pageSubtitle=${user.data?.page_subtitle != undefined ? encodeURIComponent(user.data?.page_subtitle) : ''}&logo=${encodeURIComponent(imgPreviewUrl)}`, '_blank')
    } else {
      setColorInvalid(true)
      setTimeout(() => {
        setColorInvalid(false);
      },5000);
    }
  }

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box className="design-settings">
      <Box>
        <Typography variant="h3">Design Options</Typography>
        <Typography variant='h6' mb="29px">These options will be applied to both your Community and Membership pages.</Typography>

        <form noValidate>
          {false &&
          <Box className='brand_name'>
            <TextField fullWidth margin="normal" label='Brand Name' name="brand_name" variant="outlined" InputLabelProps={{shrink: true}} inputProps={{ maxLength: 25, minLength: 3 }} defaultValue={user.data.brand_name} placeholder="What’s the name of your brand (or collection?)" helperText={""} error={false} onChange={(e) => handlePageSettings("brand_name", e.target.value)} FormHelperTextProps={{ style: { textAlign: 'right', color: 'rgba(34, 51, 84, 0.7)' } }}  />
          </Box>
          }
          
          <TextField sx={{mt:2}} fullWidth className='Add-image' variant="outlined" disabled placeholder={'Add a logo (optional)'} onClick={triggerImage} InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button variant="outlined" component="label" id="addImage" className="font-weight-600">
                  Browse... 
                  <input type="file" id="file_upload" accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml" onChange={(e) =>{
                    if (checkImageFileExt(e)){
                      onSelectFile(e)
                    } else {
                      props.setToast(true,{type:"end_error", message: 'Please choose a correct file type'});
                    }
                  }} hidden  />
                </Button>
              </InputAdornment>
            ),
            startAdornment:(
              <InputAdornment position="start">
                <Box sx={{display:"flex"}}>
                  <Box sx={{position: 'relative'}} id="image_preview">
                    {rewardPageSettings.image ?
                    <>
                    <img src={isValidHttpUrl(rewardPageSettings.image) ? rewardPageSettings.image : (rewardPageSettings.image)} height="20" alt="" />
                    <Button id="removeImage" sx={{position: 'absolute', top: '-5px',right:'-5px',padding:'3px',minWidth:'0',display:'flex',justifyContent:'center',alignItems:'center', backgroundColor:'rgba(0,0,0)',padding:0,color: '#fff',':hover': {backgroundColor: '#000'}}} onClick={removeImage}><CloseIcon sx={{fontSize: '1rem !important'}}/></Button>
                    </>
                    :
                    <>
                    <img src="/assets/images/empty-img.svg" height="20" alt=""/>
                    </>
                  }
                  </Box>
                  <Typography variant="body1" ml={1}>{rewardPageSettings.image?.name}</Typography>
                </Box>
              </InputAdornment>
            )
          }} />
          <Typography variant="body2" sx={{color: "#223354", opacity: "0.5", fontSize: "12px", mt: 0.5, mb: 1}}>Recommended dimensions: 400px x 250px</Typography>

          <TextField type="url" fullWidth margin="normal" label='Logo URL' name="logo_url" variant="outlined" InputLabelProps={{shrink: true}} inputProps={{ maxLength: 255 }} value={rewardPageSettings.logoUrl} placeholder="https://yoursite.com" error={validLogoUrl.error !== ""}  helperText={validLogoUrl.error} onChange={(e) => {
              handleLogoUrl(e.target.value);
            }} onBlur={(e) => handleLogoUrlSetting(e.target.value)}  />
          
          <Box key="bgcolor">
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={10}>
                <TextField fullWidth margin="normal" label='Background Color' required variant="outlined" InputLabelProps={{shrink: true}} value={`#${rewardPageSettings.background}`} placeholder="#<The Current Hex Number>" onChange={(e) => handlePageSettings("background", e.target.value)} inputProps={{maxLength: 7}} error={colorData.background.error != ""} helperText={colorData.background.error} onBlur={(e) => handleColorSettings('background', e.target.value)} />
              </Grid>
              <Grid item xs={2}>
                <ColorTextField fullWidth type="color" InputLabelProps={{shrink: true}} variant="standard" value={`#${rewardPageSettings.background}`} sx={{cursor: "pointer"}} InputProps={{ disableUnderline: true }} onChange={(e) => handlePageSettings("background", e.target.value)} onBlur={(e) => handleColorSettings('background', e.target.value)} />
              </Grid>
            </Grid>
          </Box>


          <Box key="primarycolor">
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={10}>
                <TextField fullWidth margin="normal" label='Primary Color' required variant="outlined" InputLabelProps={{shrink: true}} value={`#${rewardPageSettings.primary}`} placeholder="#<The Current Hex Number>" onChange={(e) => handlePageSettings("primary", e.target.value)} inputProps={{maxLength: 7}} error={colorData.primary.error !== ""} helperText={colorData.primary.error} onBlur={(e) => handleColorSettings('primary', e.target.value)} />
              </Grid>
              <Grid item xs={2}>
                <ColorTextField fullWidth type="color" InputLabelProps={{shrink: true}} variant="standard" value={`#${rewardPageSettings.primary}`} InputProps={{ disableUnderline: true }} onChange={(e) => handlePageSettings("primary", e.target.value)} onBlur={(e) => handleColorSettings('primary', e.target.value)} />
              </Grid>
            </Grid>
          </Box>

          <Box key="accentcolor">
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={10}>
                <TextField fullWidth margin="normal" label='Accent Color' required variant="outlined" InputLabelProps={{shrink: true}} value={`#${rewardPageSettings.accent}`} placeholder="#<The Current Hex Number>" onChange={(e) => handlePageSettings("accent", e.target.value)} inputProps={{maxLength: 7}} error={colorData.accent.error !== ""} helperText={colorData.accent.error} onBlur={(e) => handleColorSettings('accent', e.target.value)} />
              </Grid>
              <Grid item xs={2}>
                <ColorTextField fullWidth type="color" InputLabelProps={{shrink: true}} variant="standard" value={`#${rewardPageSettings.accent}`} sx={{cursor: "pointer"}} InputProps={{ disableUnderline: true }} onChange={(e) => handlePageSettings("accent", e.target.value)} onBlur={(e) => handleColorSettings('accent', e.target.value)} />
              </Grid>
            </Grid>
          </Box>

          <Box display="flex" justifyContent="space-between" sx={{mt: "40px", mb: 2}}>
            <Button sx={{borderRadius: '6px'}} onClick={resetRewardPageSettings} className='btn-text'>Reset</Button>
          
            <Box display="flex">
              <PreviewButton onClick={previewBtnClick} disabled={allowPreviewDisable} className="font-weight-600"><RemoveRedEyeIcon/>&nbsp;Preview </PreviewButton>
              <Button variant="contained" onClick={updateRewardPageSettings} color="primary" sx={{borderRadius: '6px', ml:1}} className='btn-text' disabled={isSubmit}>{isSubmit ? 'Updating...' : 'Update'}</Button>
            </Box>
          </Box>

        </form>
      </Box>
      {settingsUpdateAlert.type != "" && 
      <Snackbar sx={{zIndex: '7 !important', width:'300px'}} anchorOrigin={{ vertical, horizontal }} open={settingsUpdateAlert.type != ''} autoHideDuration={5000} key={1}>
        <Alert icon={<NotificationsIcon fontSize="inherit" />} severity={settingsUpdateAlert.type != "" ? settingsUpdateAlert.type : "" } sx={{ width: '100%' }}>{settingsUpdateAlert.message}</Alert>
      </Snackbar>
      }
      {
        colorInvalid &&
        <Snackbar sx={{zIndex: '7 !important', width:'300px'}} anchorOrigin={{ vertical, horizontal }} open={true} autoHideDuration={5000} key={1}>
          <Alert icon={<NotificationsIcon fontSize="inherit" />} severity="error">Please enter a valid colour code.</Alert>
        </Snackbar>
      }
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = {
  saveSettingsPreviewData,
  updateRewardSettings,
  brand_subdomain_availability_check,
  setToast
}

export default connect(mapStateToProps, mapDispatchToProps)(ClaimSettings)