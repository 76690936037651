import { useState, Children, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Typography, Button, Card, CircularProgress, Grid, Box, Step, StepLabel, Stepper, styled, CardMedia, CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Snackbar, Alert, Link as AnchorLink, MobileStepper } from "@mui/material";
import { DropTableRowSm } from "../../components/DropItem/tablerow";
import DropBreadCrumbs from "../../components/DropBreadCrumbs";
import { Form, Formik } from "formik";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import NewCollection from "src/components/Modals/new_collection";
import NewCollectionStep2 from "src/components/Modals/new_collection_step_2";
import CouponCode from "src/components/DropTypeForm/couponCode";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CouponCodeModal from "src/components/Modals/coupon_code";
import UrlForm from "src/components/DropTypeForm/urlForm";
import { getCollections } from "src/services/actions";
import { dropTypesData, isValidHttpUrl, ValidateEmail, validateEmailOrPhone } from "src/utils";
import GatedContentModal from "src/components/Modals/gated_content";
import AdditionalInfo from "src/components/FormPart/AdditionalInfo";
import ClaimCriteria from "src/components/FormPart/ClaimCriteria";
import PublishingOptions from "src/components/FormPart/PublishingOptionNew";
import EventPassForm from "src/components/DropTypeForm/event_pass";
import EventPassModal from "src/components/Modals/event_pass";
import DropComingSoon from "src/components/Modals/drop_coming_soon";

import { gaEvent } from "../../utils";
import MerchandiseForm from "src/components/DropTypeForm/merchandise";
import RaffleForm from "src/components/DropTypeForm/raffle";
import MerchandiseModal from "src/components/Modals/claim_merchandise";
import RaffleModal from "src/components/Modals/claim_raffle";
import AllowList from "src/components/DropTypeForm/allowList";
import AllowListModal from "src/components/Modals/allow_list";
import moment from "moment";
import Poll from "src/components/DropTypeForm/poll";
import PollModal from "src/components/Modals/poll";
import ContactUsCta from "src/components/ContactUsCta";
import Audience from "src/components/FormPart/Audience";
import CollectionTargeting from "src/components/FormPart/CollectionTargeting";
import CustomRewardForm from "src/components/DropTypeForm/custom";
import CircleIcon from "@mui/icons-material/Circle";
const BoxActions = styled(Box)(
  ({ theme }) => `
    background: ${theme.colors.alpha.black[5]}
`
);

const sleep = (time) => new Promise((acc) => setTimeout(acc, time));

function RegisterWizard(props) {
  const myRefname = useRef(null);
  const { collection, dropData, user } = props;
  const [collectionModal, setCollectionModal] = useState(false);
  const [openStep2, setOpenStep2] = useState(false);
  const [step2Addr, setStep2Addr] = useState("");
  const [step2Network, setStep2Network] = useState("");

  const [formPublished, setFormPublished] = useState(false);

  const [multiStep, setMultiStep] = useState(1);
  const [currentStepNo, setCurrentStepNo] = useState(0);

  const handleCollectionModal = () => setCollectionModal(true);

  // console.log("openstep2 =>",openStep2)
  const handleStep2Open = (contractAddress, network) => {
    setStep2Addr(contractAddress);
    setStep2Network(network);
    setOpenStep2(true);
  };

  const [sponsors, setSponsors] = useState(props.dropData.sponsors);

  const [nextTabErrors, setNextTabErrors] = useState({
    title: { val: props.dropData.title, error: "" },
    quantity: { val: props.dropData.quantity, error: "" },
    end_date: { val: props.dropData.end_date, error: "" },
    end_time: { val: props.dropData.end_date, error: "" },
    start_date: { val: props.dropData.published_date, error: "" },
    start_time: { val: props.dropData.published_date, error: "" },
    event_date: { val: props.dropData?.eventPass?.event_date, error: "" },
    event_time: { val: props.dropData?.eventPass?.event_date, error: "" },
    code: { val: props.dropData?.couponCodes?.code, error: "" },
    coupon_code_website: { val: props.dropData?.couponCodes?.website, error: "" },
    max_winners: { val: props.dropData?.raffle?.max_winners, error: "" },
    raffle_contact_email: { val: props.dropData?.raffle?.contact_email, error: "" },
    content_url: { val: props.dropData?.urls?.Url, error: "" },
    content_name: { val: props.dropData?.urls?.ContentName, error: "" },
    allow_list_website: { val: props.dropData?.allowList?.project_website, error: "" },
    allow_list_disord_url: { val: props.dropData?.allowList?.discord_url, error: "" },
    allow_list_price: { val: props.dropData?.allowList?.price, error: "" },
    mint_date: { val: props.dropData?.allowList?.mint_date, error: "" },
    mint_time: { val: props.dropData?.allowList?.mint_date, error: "" },
    merch_support_contact: { val: props.dropData?.merchandise?.support_contact, error: "" },
    // twitter_username: {val: props.dropData?.claim_condition_message?.twitter_username, error: ""},
    // twitter_like: {val: props.dropData?.claim_condition_message?.twitter_like, error: ""},
    // twitter_retweet: {val: props.dropData?.claim_condition_message?.twitter_retweet, error: ""},
    // instagram_view: {val: props.dropData?.claim_condition_message?.instagram_view, error: ""},
    // youtube_view: {val: props.dropData?.claim_condition_message?.youtube_view, error: ""},
    // tiktok_view: {val: props.dropData?.claim_condition_message?.tiktok_view, error: ""},
    // facebook_view: {val: props.dropData?.claim_condition_message?.facebook_view, error: ""},
    discord_server_url: { val: props.dropData?.claim_condition_message?.discord_join_server?.server_url, error: "" },
    discord_server_id: { val: props.dropData?.claim_condition_message?.discord_join_server?.server_id, error: "" },
    poll_question: { val: props.dropData?.poll?.question, error: "" },
    poll_choices:
      dropData.category == "poll" && dropData.poll?.choices?.length > 0
        ? dropData.poll?.choices?.map((d_u) => {
            return { choice: { value: d_u, error: "" } };
          })
        : [{ choice: { value: "", error: "" } }, { choice: { value: "", error: "" } }],
    gatedContent:
      dropData.category == "gated_content" && dropData.urls.length > 0
        ? dropData.urls.map((d_u) => {
            return { content_url: { value: d_u.Url, error: "" }, content_name: { value: d_u.ContentName, error: "" } };
          })
        : [{ content_url: { value: "", error: "" }, content_name: { value: "", error: "" } }],
  });

  const notAllowedToSubmit = (step, multiStep) => {
    if (multiStep == 1 && step == 0) {
      return props.dropData.category == "";
    } else if (multiStep == 2 && step == 0) {
      return props.dropData.sponsors.length == 0;
    } else if (multiStep == 3 && step == 0) {
      if (props.dropData.title == "" || props.dropData.quantity == "") {
        return true;
      } else {
        return false;
      }
    } else if (step == 1 && props.dropData.category == "coupon_code") {
      if (props.dropData.couponCodes.code !== "") {
        return false;
      } else {
        return true;
      }
    } else if (step == 1 && props.dropData.category == "event_pass") {
      if (props.dropData.eventPass.event_date !== "" && props.dropData.eventPass.event_date !== "Invalid date") {
        return false;
      } else {
        return true;
      }
    } else if (step == 1 && props.dropData.category == "poll") {
      if (props.dropData.poll?.question !== "" && props.dropData.poll?.question !== null && props.dropData.poll?.choices.filter((c_data) => c_data == "" || c_data == null).length < 1) {
        return false;
      } else {
        return true;
      }
    } else if (step == 1 && props.dropData.category == "gated_content") {
      let allowed_content = false;

      let url_data_empty = props.dropData.urls.map((urldata) => {
        return urldata.Url == "" || urldata.ContentName == "";
      });
      return url_data_empty.filter((u_d) => u_d == true).length > 0;
    } else if (step == 2) {
      return !(props.dropData.allow_reward_page && props.dropData.allow_embed_page);
    }
  };

  const setSponsor = async (set_type, sponsor) => {
    if (set_type == "add") {
      await setSponsors((previous) => [...previous, sponsor]);
    } else if (set_type == "remove") {
      await setSponsors(sponsors.filter((item) => item.id !== sponsor.id));
    }
    return;
    if (sponsors.filter((item) => item.id !== sponsor.id).length > 0) {
      setSponsors(sponsors.filter((item) => item.index !== sponsor.id));
    } else {
      setSponsors([...sponsors, sponsor]);
    }
  };
  useEffect(() => {
    props.handleChildData("sponsors", sponsors);
  }, [sponsors]);

  useEffect(() => {
    setSponsors(props.dropData.sponsors);
  }, [props.dropData.sponsors]);

  useEffect(() => {
    props.getCollections("all");
  }, []);
  useEffect(() => {
    if (collection.new_collection_status == "success") {
      setCollectionModal(false);
    }
  }, [collection]);

  const vertical = "top";
  const horizontal = "center";
  const [alertBar, setAlertBar] = useState(props.rewardCRD.type == "error");
  const [sponsorErrorAlert, setSponsorErrorAlert] = useState(false);
  const [audienceErrorAlert, setAudienceErrorAlert] = useState(false);
  const [categoryErrorAlert, setCategoryErrorAlert] = useState(false);
  const [embedErrorAlert, setEmbedErrorAlert] = useState(false);
  const [showDraftInfoAlert, setShowDraftInfoAlert] = useState(false);
  const [showInquiryAlert, setShowInquiryAlert] = useState(false);
  const handleClose = () => {
    setAlertBar({ ...alertBar, open: false });
  };

  useEffect(() => {
    props.rewardCRD.type == "error" && setAlertBar(true);
    setTimeout(() => {
      setAlertBar(false);
    }, 5000);
  }, [props.rewardCRD.type]);

  const handleDropTypeClick = () => {
    myRefname.current.click();
  };

  const supportClick = () => {
    if (document.getElementsByClassName("_hj-ETLL8__MinimizedWidgetMiddle__label").length > 0) {
      document.getElementsByClassName("_hj-ETLL8__MinimizedWidgetMiddle__label")[0].click();
    }
  };

  return (
    <>
      {props.rewardCRD.type == "error" && (
        <Snackbar sx={{ zIndex: "7 !important" }} anchorOrigin={{ vertical, horizontal }} open={alertBar} autoHideDuration={5000} onClose={handleClose}>
          <Alert severity="error" sx={{ width: "100%" }}>
            {props.rewardCRD.message}
          </Alert>
        </Snackbar>
      )}
      {showDraftInfoAlert && props.rewardList.data.length == 1 && (
        <Snackbar sx={{ zIndex: "7 !important" }} anchorOrigin={{ vertical, horizontal }} open={true} autoHideDuration={5000} onClose={handleClose}>
          <Alert severity="info" sx={{ width: "100%" }}>
            Reward saved to drafts
          </Alert>
        </Snackbar>
      )}
      {showInquiryAlert && (
        <Snackbar sx={{ zIndex: "7 !important" }} anchorOrigin={{ vertical, horizontal }} open={true} autoHideDuration={5000} onClose={handleClose}>
          <Alert severity="info" sx={{ width: "100%" }}>
            Inquiry submitted successfully.
          </Alert>
        </Snackbar>
      )}

      <Card sx={{ mt: "75px" }} className="multistep-form">
        <FormikStepper
          key="multistep-form-stepper"
          initialValues={""}
          onSubmit={async () => {
            await sleep(3000);
          }}
          user={user}
          multiStep={multiStep}
          setMultiStep={setMultiStep}
          setCurrentStepNo={setCurrentStepNo}
          setFormPublished={setFormPublished}
          formPublished={formPublished}
          submitFormData={props.submitFormData}
          setShowDraftInfoAlert={setShowDraftInfoAlert}
          updateFormData={props.updateFormData}
          dropDataId={props.dropData.reward_drop_id}
          rewardDropData={props.dropData}
          handleChildData={props.handleChildData}
          notAllowedToSubmit={notAllowedToSubmit}
          nextTabErrors={nextTabErrors}
          setNextTabErrors={setNextTabErrors}
          setSponsorErrorAlert={setSponsorErrorAlert}
          setAudienceErrorAlert={setAudienceErrorAlert}
          setCategoryErrorAlert={setCategoryErrorAlert}
          setEmbedErrorAlert={setEmbedErrorAlert}
          setShowInquiryAlert={setShowInquiryAlert}
          dropRewardCRD={props.rewardCRD}
          is_duplicate={props.is_duplicate}
          buttonRef={myRefname}
        >
          <FormikStep key="Basic Info" label="Basic Info">
            <Box p={4} className="step-1">
              {props.dropData.community_type !== "membership" && multiStep === 1 && (
                <>
                  <Typography variant="body1" textAlign="center" className="subtitle" lineHeight="normal" mb="20px">
                    CREATE REWARD
                  </Typography>
                  <Typography variant="h1" className="title" mb={3}>
                    Which digital community do you want to target?
                  </Typography>
                  <Box textAlign="center" sx={{ mb: 3 }}>
                    <Button onClick={handleCollectionModal} variant="outlined" sx={{ borderRadius: "6px" }} className="font-weight-600 btn-outlined">
                      <AddCircleOutlineIcon />
                      &nbsp;Add Collection
                    </Button>
                  </Box>
                  {collection.collections.length === 0 && (
                    <>
                      <Box textAlign="center">
                        <img variant="square" src={"/assets/img/no-collections.png"} alt="No Collections" width="95" style={{ marginTop: "1.25rem" }} />
                      </Box>

                      <Box mt={3.25} textAlign="center">
                        <ContactUsCta />
                      </Box>
                    </>
                  )}
                  {collectionModal && <NewCollection open={collectionModal} handleClose={() => setCollectionModal(false)} handleStep2Open={handleStep2Open} />}
                  {openStep2 && <NewCollectionStep2 open={openStep2} handleClose={() => setOpenStep2(false)} step2Addr={step2Addr} step2Network={step2Network} />}
                  {collection.collections.length !== 0 && (
                    <TableContainer component={Paper} sx={{ borderRadius: 0, boxShadow: 0, display: "flex", justifyContent: "center" }}>
                      <Box display="flex" justifyContent="center">
                        <Table className="table" aria-label="simple table">
                          <TableHead>
                            <TableRow>
                              <TableCell width={450} className="table-head">
                                Collection
                              </TableCell>
                              <TableCell width={150} className="table-head">
                                Size
                              </TableCell>
                              <TableCell width={300} className="table-head" sx={{ textAlign: "center" }}>
                                Network
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>{props.collection.collections && props.collection.collections && props.collection.collections.sort((a, b) => (a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1)).map((collection, index) => <DropTableRowSm key={index} drop={{ key: index, id: collection.id, img: collection.logo_image, title: collection.title, size: collection.total_supply, chain_id: collection.chain_id }} collection={collection} setSponsor={setSponsor} sponsors={sponsors} handleChildData={props.handleChildData} selected_sponsors={props.dropData.sponsors} />)}</TableBody>
                        </Table>
                      </Box>
                    </TableContainer>
                  )}
                </>
              )}

              {props.dropData.community_type !== "membership" && multiStep === 2 && (
                <>
                  <CollectionTargeting dropData={props.dropData} handleChildData={props.handleChildData} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} />
                </>
              )}
              {multiStep === 3 && (
                <>
                  <Typography variant="body1" textAlign="center" className="subtitle" lineHeight="normal" mb="20px">
                    CREATE REWARD
                  </Typography>
                  <Typography variant="h1" className="title" mb={3}>
                    Choose a reward type
                  </Typography>
                  <Box p="20px 80px" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <Grid container spacing="20px">
                      {dropTypesData.map((drop_type, index) => (
                        <Grid key={index} item xs={12} md={3}>
                          <Card
                            className={`drop-type-card ${props.dropData.category == drop_type.short_name ? "selected" : ""}`}
                            onClick={() => {
                              props.rewardCRD.type != "request" && props.handleChildData("category", drop_type.short_name);
                              handleDropTypeClick();
                              gaEvent({
                                category: `Type - ${drop_type.short_name}`,
                                action: "reward_type_select",
                                label: `-${user.data.eth_address} -`,
                              });
                            }}
                          >
                            <CardMedia className="card-media" component="img" height="72" image={drop_type.img_src} alt={drop_type.title} />
                            <CardContent className="card-content" sx={{ p: "5px !important" }}>
                              <Typography gutterBottom variant="h5" component="div" className="drop-title">
                                {drop_type.title}
                              </Typography>
                              <Typography variant="body2" className="drop-description" sx={{ wordBreak: "break-word !important" }}>
                                {drop_type.description}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </>
              )}

              {multiStep === 4 && (
                <>
                  <AdditionalInfo dropData={props.dropData} handleChildData={props.handleChildData} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} />
                </>
              )}
            </Box>
          </FormikStep>
          <FormikStep key="Claim Details" label="Claim Details">
            {multiStep === 5 && (
              <Box py={props.dropData.status == 1 ? 0 : 4} width={props.dropData.status == 1 ? "100%" : "712px"} mx="auto">
                {(() => {
                  if (props.dropData.category == "gated_content") {
                    return <UrlForm handleChildData={props.handleChildData} dropData={props.dropData} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} />;
                  } else if (props.dropData.category == "event_pass") {
                    return <EventPassForm handleChildData={props.handleChildData} dropData={props.dropData} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} />;
                  } else if (props.dropData.category == "coupon_code") {
                    return <CouponCode handleChildData={props.handleChildData} dropData={props.dropData} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} />;
                  } else if (props.dropData.category == "merchandise") {
                    return <MerchandiseForm handleChildData={props.handleChildData} dropData={props.dropData} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} />;
                  } else if (props.dropData.category == "raffle") {
                    return <RaffleForm handleChildData={props.handleChildData} dropData={props.dropData} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} />;
                  } else if (props.dropData.category == "allow_list") {
                    return <AllowList handleChildData={props.handleChildData} dropData={props.dropData} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} />;
                  } else if (props.dropData.category == "poll") {
                    return <Poll handleChildData={props.handleChildData} dropData={props.dropData} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} />;
                  } else if (props.dropData.category == "custom") {
                    return <CustomRewardForm handleChildData={props.handleChildData} dropData={props.dropData} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} />;
                  }
                })()}
              </Box>
            )}

            {/* Select additional claim criteria */}
            {multiStep === 6 && (
              <Box className="step-2" p={4}>
                <ClaimCriteria dropData={props.dropData} handleChildData={props.handleChildData} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} />
              </Box>
            )}
          </FormikStep>
          <FormikStep key="Publish" label="Publish">
            <Box className="step-3">
              <PublishingOptions dropData={props.dropData} handleChildData={props.handleChildData} handleMultipleChilddata={props.handleMultipleChilddata} dropId={props.dropData.reward_drop_id} nextTabErrors={nextTabErrors} setNextTabErrors={setNextTabErrors} />
            </Box>
          </FormikStep>
        </FormikStepper>

        {(props.dropData.sponsors.length === 0 || props.dropData.sponsors.length > process.env.REACT_APP_REWARD_COLLECTIOON_LIMIT) && (
          <Snackbar sx={{ zIndex: "7 !important" }} anchorOrigin={{ vertical, horizontal }} open={sponsorErrorAlert} autoHideDuration={5000} onClose={handleClose}>
            <Alert severity="error" sx={{ width: "100%" }}>
              {props.dropData.sponsors.length == 0 ? `Please select at least one collection.` : `Collections cannot exceed more than ${process.env.REACT_APP_REWARD_COLLECTIOON_LIMIT}.`}
            </Alert>
          </Snackbar>
        )}
        <Snackbar sx={{ zIndex: "7 !important" }} anchorOrigin={{ vertical, horizontal }} open={audienceErrorAlert} autoHideDuration={5000} onClose={handleClose}>
          <Alert severity="error" sx={{ width: "100%" }}>
            Please select audience to continue.
          </Alert>
        </Snackbar>
        <Snackbar sx={{ zIndex: "7 !important" }} anchorOrigin={{ vertical, horizontal }} open={categoryErrorAlert} autoHideDuration={5000} onClose={handleClose}>
          <Alert severity="error" sx={{ width: "100%" }}>
            Please select a reward type
          </Alert>
        </Snackbar>
        <Snackbar sx={{ zIndex: "7 !important" }} anchorOrigin={{ vertical, horizontal }} open={embedErrorAlert} autoHideDuration={5000}>
          <Alert severity="error" sx={{ width: "100%" }}>
            Please select at least one publishing option
          </Alert>
        </Snackbar>
      </Card>
      {currentStepNo == 1 && (
        <Box mt={3} mb={2} textAlign="center">
          <Typography>
            Not seeing what you need?{" "}
            <AnchorLink onClick={supportClick} sx={{ cursor: "pointer" }}>
              Tell us!
            </AnchorLink>
          </Typography>
        </Box>
      )}
    </>
  );
}

export function FormikStep({ children }) {
  return <>{children}</>;
}

export function FormikStepper({ children, ...props }) {
  const childrenArray = Children.toArray(children);
  const [step, setStep] = useState(0);
  const { user } = props;

  useEffect(() => {
    props.setCurrentStepNo(step);
  }, [step]);

  const currentChild = childrenArray[step];
  const [completed, setCompleted] = useState(false);
  const [coupon, setCoupon] = useState(false);
  const [gatedPreview, setGatedPreview] = useState(false);
  const [eventPassPreview, setEventPassPreview] = useState(false);
  const [merchModal, setMerchModal] = useState(false);
  const [raffleModal, setRaffleModal] = useState(false);
  const [allowListModal, setAllowListModal] = useState(false);
  const [pollModal, setPollModal] = useState(false);

  const [comingSoon, setComingSoon] = useState(false);
  const [formSteps, setFormSteps] = useState(props.rewardDropData.community_type === "membership" ? 5 : 7);

  const [breadcrumbData, setBreadcrumbData] = useState([""]);

  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  function isLastStep() {
    return step === childrenArray.length - 1 || (props.multiStep === 7 && props.rewardDropData.community_type === "membership");
  }

  const StepperBox = styled(Stepper)({
    "& .css-1mem4wb-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed": {
      color: "#51FD00",
    },
    backgroundImage: "url(/assets/img/stepper-line.png)",
  });

  const jumpToSpecifiedStep = (step_num, multi_step_num) => {
    const re_drop = location.state.reward_drop_data;
    if (re_drop.community_type !== "" && re_drop.sponsor_collections.length === 0) {
      setBreadcrumbData((prev) => [...prev, { name: "Target Community", step: 0, multiStep: 1 }]);
      setStep(0);
      props.setMultiStep(1);
    } else if (re_drop.sponsor_collections.length > 0 && re_drop.category === "") {
      setBreadcrumbData((prev) => [...prev, { name: "Target Community", step: 0, multiStep: 1 }, { name: "Advanced Targeting", step: 0, multiStep: 2 }, { name: "Type", step: 0, multiStep: 3 }]);
      setStep(0);
      props.setMultiStep(3);
    } else if (re_drop.sponsor_collections.length > 0 && (re_drop.title?.trim() == "" || re_drop.quantity == "")) {
      setBreadcrumbData((prev) => [...prev, { name: "Target Community", step: 0, multiStep: 1 }, { name: "Advanced Targeting", step: 0, multiStep: 2 }, { name: "Type", step: 0, multiStep: 3 }]);
      setStep(0);
      props.setMultiStep(3);
    } else if (re_drop.sponsor_collections.length > 0 && re_drop.title?.trim() != "" && (re_drop.drop_type_data.filter((tyd) => tyd.event_date !== "" && tyd.event_date !== null).length == 0 || re_drop.drop_type_data.filter((tyd) => tyd.content_url !== "" && tyd.content_url !== null).length == 0 || re_drop.drop_type_data.filter((tyd) => tyd.coupon_code !== "" && tyd.coupon_code !== null).length == 0)) {
      setBreadcrumbData((prev) => [...prev, { name: "Target Community", step: 0, multiStep: 1 }, { name: "Advanced Targeting", step: 0, multiStep: 2 }, { name: "Type", step: 0, multiStep: 3 }, { name: "Additional Info", step: 0, multiStep: 4 }]);
      setStep(0);
      props.setMultiStep(4);
    } else if (re_drop.sponsor_collections.length > 0 && re_drop.title?.trim() != "" && (re_drop.drop_type_data.filter((tyd) => tyd.event_date !== "" && tyd.event_date !== null).length > 0 || re_drop.drop_type_data.filter((tyd) => tyd.content_url !== "" && tyd.content_url !== null).length > 0 || re_drop.drop_type_data.filter((tyd) => tyd.coupon_code !== "" && tyd.coupon_code !== null).length > 0) && re_drop.claim_condition == 0) {
      setBreadcrumbData((prev) => [...prev, { name: "Target Community", step: 0, multiStep: 1 }, { name: "Advanced Targeting", step: 0, multiStep: 2 }, { name: "Type", step: 0, multiStep: 3 }, { name: "Additional Info", step: 0, multiStep: 4 }, { name: "Details", step: 1, multiStep: 5 }]);
      setStep(1);
      props.setMultiStep(5);
    } else if ((re_drop.sponsor_collections.length > 0 && re_drop.title?.trim() != "" && (re_drop.drop_type_data.filter((tyd) => tyd.event_date !== "" && tyd.event_date !== null).length > 0 || re_drop.drop_type_data.filter((tyd) => tyd.content_url !== "" && tyd.content_url !== null).length > 0 || re_drop.drop_type_data.filter((tyd) => tyd.coupon_code !== "" && tyd.coupon_code !== null).length > 0)) || re_drop.claim_condition > 0) {
      setBreadcrumbData((prev) => [...prev, { name: "Target Community", step: 0, multiStep: 1 }, { name: "Advanced Targeting", step: 0, multiStep: 2 }, { name: "Type", step: 0, multiStep: 3 }, { name: "Additional Info", step: 0, multiStep: 4 }, { name: "Details", step: 1, multiStep: 5 }, { name: "Claim Criteria", step: 1, multiStep: 6 }]);
      setStep(1);
      props.setMultiStep(6);
    }
  };

  const jumpMembershipToSpecifiedStep = (step_num, multi_step_num) => {
    const re_drop = location.state.reward_drop_data;
    if (re_drop.community_type !== "" && re_drop.sponsor_collections.length === 0) {
      setBreadcrumbData((prev) => [...prev, { name: "Type", step: 0, multiStep: 3 }]);
      setStep(0);
      props.setMultiStep(2);
    } else if (re_drop.sponsor_collections.length > 0 && re_drop.category === "") {
      setBreadcrumbData((prev) => [...prev, { name: "Type", step: 0, multiStep: 3 }]);
      setStep(0);
      props.setMultiStep(3);
    } else if (re_drop.sponsor_collections.length > 0 && (re_drop.title?.trim() == "" || re_drop.quantity == "")) {
      setBreadcrumbData((prev) => [...prev, { name: "Type", step: 0, multiStep: 3 }]);
      setStep(0);
      props.setMultiStep(3);
    } else if (re_drop.sponsor_collections.length > 0 && re_drop.title?.trim() != "" && (re_drop.drop_type_data.filter((tyd) => tyd.event_date !== "" && tyd.event_date !== null).length == 0 || re_drop.drop_type_data.filter((tyd) => tyd.content_url !== "" && tyd.content_url !== null).length == 0 || re_drop.drop_type_data.filter((tyd) => tyd.coupon_code !== "" && tyd.coupon_code !== null).length == 0)) {
      setBreadcrumbData((prev) => [...prev, { name: "Type", step: 0, multiStep: 3 }, { name: "Additional Info", step: 0, multiStep: 4 }]);
      setStep(0);
      props.setMultiStep(3);
    } else if (re_drop.sponsor_collections.length > 0 && re_drop.title?.trim() != "" && (re_drop.drop_type_data.filter((tyd) => tyd.event_date !== "" && tyd.event_date !== null).length > 0 || re_drop.drop_type_data.filter((tyd) => tyd.content_url !== "" && tyd.content_url !== null).length > 0 || re_drop.drop_type_data.filter((tyd) => tyd.coupon_code !== "" && tyd.coupon_code !== null).length > 0) && re_drop.claim_condition == 0) {
      setBreadcrumbData((prev) => [...prev, { name: "Type", step: 0, multiStep: 3 }, { name: "Additional Info", step: 0, multiStep: 4 }, { name: "Details", step: 1, multiStep: 5 }]);
      setStep(1);
      props.setMultiStep(5);
    } else if ((re_drop.sponsor_collections.length > 0 && re_drop.title?.trim() != "" && (re_drop.drop_type_data.filter((tyd) => tyd.event_date !== "" && tyd.event_date !== null).length > 0 || re_drop.drop_type_data.filter((tyd) => tyd.content_url !== "" && tyd.content_url !== null).length > 0 || re_drop.drop_type_data.filter((tyd) => tyd.coupon_code !== "" && tyd.coupon_code !== null).length > 0)) || re_drop.claim_condition > 0) {
      setBreadcrumbData((prev) => [...prev, { name: "Type", step: 0, multiStep: 3 }, { name: "Additional Info", step: 0, multiStep: 4 }, { name: "Details", step: 1, multiStep: 5 }, { name: "Claim Criteria", step: 1, multiStep: 6 }]);
      setStep(1);
      props.setMultiStep(6);
    }
  };

  useEffect(() => {
    if (props.multiStep == 1) {
      setBreadcrumbData([
        {
          name: "Target Community",
          step: 0,
          multiStep: 1,
        },
      ]);
    }
    if (params.type == "edit") {
      if (location.state.reward_drop_data.community_type === "membership") {
        jumpMembershipToSpecifiedStep(step, props.multiStep);
      } else {
        jumpToSpecifiedStep(step, props.multiStep);
      }
    } else {
      if (location.state?.reward_type === "membership") {
        setBreadcrumbData((prev) => [...prev, { name: "Type", step: 0, multiStep: 3 }]);
        setStep(0);
        props.setMultiStep(3);
      }
    }
    // }
  }, []);


  const saveDraftAndNavigate = async () => {
    if (!(props.rewardDropData.allow_reward_page || props.rewardDropData.allow_embed_page)) {
      props.setEmbedErrorAlert(true);
      setTimeout(() => {
        props.setEmbedErrorAlert(false);
      }, 5000);
      return false;
    } else {
      await props.updateFormData("not_publish", step, props.multiStep);
      navigate(props.rewardDropData.community_type === "membership" ? "/rewards/membership" : "/rewards/community", { state: { drop_rewards: "drafts" } });
    }
  };
  return (
    <Formik
      key="mainForm001"
      {...props}
      id="mainForm"
      onSubmit={async (values, helpers) => {
        if (step == 0 && props.multiStep == 3) {
          if (props.rewardDropData.category === "") {
            props.setCategoryErrorAlert(true);
            setTimeout(() => {
              props.setCategoryErrorAlert(false);
            }, 5000);
            return false;
          }
        }
        if (props.multiStep == 4 && step == 0) {
          if (props.rewardDropData.title.trim() == "") {
            if (props.rewardDropData.is_limited_quantity == "true" || props.rewardDropData.is_limited_quantity) {
              if (props.rewardDropData.quantity.trim() == "") {
              }
              if (parseInt(props.rewardDropData.quantity) < 1) {
                props.setNextTabErrors({ ...props.nextTabErrors, quantity: { value: props.rewardDropData.quantity, error: "Please enter a quantity greater than 0" }, title: { value: props.rewardDropData.title, error: "Title can't be blank" } });
              }
              if (parseInt(props.rewardDropData.quantity) > 100000000) {
                props.setNextTabErrors({ ...props.nextTabErrors, quantity: { value: props.rewardDropData.quantity, error: "Quantity can't exceed 100000000" }, title: { value: props.rewardDropData.title, error: "Title can't be blank" } });
              } else if (props.rewardDropData.quantity.trim() == "") {
                props.setNextTabErrors({ ...props.nextTabErrors, quantity: { value: props.rewardDropData.quantity, error: "Quantity can't be blank" }, title: { value: props.rewardDropData.title, error: "Title can't be blank" } });
              } else if (parseInt(props.rewardDropData.quantity) < 0) {
                props.setNextTabErrors({ ...props.nextTabErrors, quantity: { value: props.rewardDropData.quantity, error: "Quantity can't be negetive" }, title: { value: props.rewardDropData.title, error: "Title can't be blank" } });
              }
            } else {
              props.setNextTabErrors({ ...props.nextTabErrors, title: { value: props.rewardDropData.title, error: "Title can't be blank" } });
            }
            return false;
          }

          if (props.rewardDropData.is_limited_quantity == "true" || props.rewardDropData.is_limited_quantity) {
            if (props.rewardDropData.quantity.trim() == "") {
            }
            if (parseInt(props.rewardDropData.quantity) < 1) {
              props.setNextTabErrors({ ...props.nextTabErrors, quantity: { value: props.rewardDropData.quantity, error: "Please enter a quantity greater than 0" } });
              return false;
            }
            if (parseInt(props.rewardDropData.quantity) > 100000000) {
              props.setNextTabErrors({ ...props.nextTabErrors, quantity: { value: props.rewardDropData.quantity, error: "Quantity can't exceed 100000000" } });
              return false;
            } else if (props.rewardDropData.quantity.trim() == "") {
              props.setNextTabErrors({ ...props.nextTabErrors, quantity: { value: props.rewardDropData.quantity, error: "Quantity can't be blank" } });
              return false;
            } else if (parseInt(props.rewardDropData.quantity) < 0) {
              props.setNextTabErrors({ ...props.nextTabErrors, quantity: { value: props.rewardDropData.quantity, error: "Quantity can't be negative" } });
              return false;
            }
          }
        }

        if (step == 0 && props.multiStep == 1) {
          if (props.rewardDropData.sponsors.length === 0 || props.rewardDropData.sponsors.length > process.env.REACT_APP_REWARD_COLLECTIOON_LIMIT) {
            props.setSponsorErrorAlert(true);
            setTimeout(() => {
              props.setSponsorErrorAlert(false);
            }, 5000);
            return false;
          }
        }
        // console.log('step, multiStep =>',step, props.multiStep)
        if (step === 1 && props.multiStep === 6) {
          if (props.rewardDropData.claim_condition.length > 0 && props.rewardDropData.claim_condition.indexOf("email") > 0) {
            let claimTasksErr = [];
            for (let l = 0; l < props.rewardDropData.claim_condition.length; l++) {
              let errHash = {};
              const cCondn = props.rewardDropData.claim_condition[l];
              const condObj = props.rewardDropData.claim_condition_message;
              let condObjKey = condObj[cCondn];
              let nextTbErrorObj = props.nextTabErrors[cCondn];
              let conditionKeyName = cCondn;
              // if (cCondn === 'twitter') {
              //   conditionKeyName = 'twitter_username';
              //   condObjKey = condObj['twitter_username'];
              // }

              if (cCondn === "discord_join_server") {
                if (claimTasksErr.includes("discord_server_id")) {
                  conditionKeyName = "discord_server_url";
                } else {
                  conditionKeyName = "discord_server_id";
                }
                // if (typeof condObj['discord_join_server'] === 'string')
                // if (typeof props.rewardDropData.claim_condition_message?.discord_join_server === 'string' && props.rewardDropData.claim_condition_message?.discord_join_server === '') {
              }

              if (condObjKey === "") {
                errHash[conditionKeyName] = { value: condObjKey, error: "Please complete all required fields" };
                claimTasksErr.push(errHash);
              }
            }

            let errorData = { ...props.nextTabErrors };

            if (claimTasksErr.length > 0) {
              for (let m = 0; m < claimTasksErr.length; m++) {
                const errobjdata = claimTasksErr[m];
                errorData = { ...errorData, ...errobjdata };
              }

              if (props.rewardDropData.claim_condition.includes("discord_join_server") && !claimTasksErr.includes("discord_server_url")) {
                errorData = { ...errorData, ["discord_server_url"]: { value: "", error: "Please complete all required fields" } };
              }
              if (props.rewardDropData.claim_condition.includes("discord_join_server") && !claimTasksErr.includes("discord_server_id")) {
                errorData = { ...errorData, ["discord_server_id"]: { value: "", error: "Please complete all required fields" } };
              }

              props.setNextTabErrors(errorData);

              return false;
            }
          }
        }

        if (step == 2) {
          if (!(props.rewardDropData.allow_reward_page || props.rewardDropData.allow_embed_page)) {
            props.setEmbedErrorAlert(true);
            setTimeout(() => {
              props.setEmbedErrorAlert(false);
            }, 5000);
            return false;
          }
          if (!props.rewardDropData.schedule_now) {
            if (props.rewardDropData.startAt === "" || props.rewardDropData.startAt === "Invalid date") {
              props.setNextTabErrors({ ...props.nextTabErrors, start_date: { value: props.rewardDropData.startAt, error: "Start Date can't be blank" } });
              return false;
            }
            if (props.rewardDropData.startAt["$d"] == "Invalid Date") {
              props.setNextTabErrors({ ...props.nextTabErrors, start_date: { value: props.rewardDropData.startAt, error: "Invalid Date" } });
              return false;
            }
            if (props.rewardDropData.startAt["$d"] !== "Invalid Date" && (moment() > moment(props.rewardDropData.startAt["$d"]) || (moment() > moment(props.rewardDropData.startAt) && moment() > new Date(props.rewardDropData.startAt)))) {
              props.setNextTabErrors({ ...props.nextTabErrors, start_date: { value: props.rewardDropData.startAt, error: "Please select a date in the future" } });
              return false;
            }
            if (props.rewardDropData.startAt["$d"] !== "Invalid Date" && (props.nextTabErrors.start_time.val == null || props.nextTabErrors.start_time.val["$d"] == "Invalid Date")) {
              props.setNextTabErrors({ ...props.nextTabErrors, start_time: { value: props.nextTabErrors.start_time.val, error: "Invalid Time" } });
              return false;
            }
          }
          if (props.rewardDropData.allow_reward_end_date){
            if (props.rewardDropData.endsIn === "" || props.rewardDropData.endsIn === "Invalid date") {
              props.setNextTabErrors({ ...props.nextTabErrors, end_date: { value: props.rewardDropData.endsIn, error: "End Date can't be blank" } });
              return false;
            }
            if (props.rewardDropData.endsIn != null) {
              if (props.rewardDropData.endsIn["$d"] == "Invalid Date") {
                props.setNextTabErrors({ ...props.nextTabErrors, end_date: { value: props.rewardDropData.endsIn, error: "Invalid Date" } });
                return false;
              } else {
                if (moment() > moment(props.rewardDropData.endsIn["$d"])) {
                  props.setNextTabErrors({ ...props.nextTabErrors, end_date: { value: props.rewardDropData.endsIn, error: "Please select a date in the future" } });
                  return false;
                }
              }
            }
          }
        }

        if (step == 1 && props.rewardDropData.category == "coupon_code") {
          if (props.rewardDropData.couponCodes.code.trim() == "") {
            props.setNextTabErrors({ ...props.nextTabErrors, ["code"]: { value: props.rewardDropData.couponCodes.code, error: "Coupon Code can't be blank" } });
            return false;
          }
          if (props.rewardDropData.couponCodes.website != "" && props.rewardDropData.couponCodes.website != null && !isValidHttpUrl(props.rewardDropData.couponCodes.website)) {
            props.setNextTabErrors({ ...props.nextTabErrors, ["coupon_code_website"]: { value: props.rewardDropData.couponCodes.code, error: "Enter valid URL" } });
            return false;
          }
        }
        if (step == 1 && props.rewardDropData.category == "merchandise") {
          /* if(!ValidateEmail(props.rewardDropData.merchandise.support_contact)){
            props.setNextTabErrors({...props.nextTabErrors, ['merch_support_contact']: {value: props.rewardDropData.merchandise.support_contact, error: "Enter valid email address"}});
            return false;
          } */

          if (props.rewardDropData.merchandise.support_contact == "") {
            props.setNextTabErrors({ ...props.nextTabErrors, ["merch_support_contact"]: { value: props.rewardDropData.merchandise.support_contact, error: "Support Email or Phone Number can't be blank" } });
            return false;
          }

          /* if (props.rewardDropData.merchandise.support_contact !== "" && !validateEmailOrPhone(props.rewardDropData.merchandise.support_contact)) {
            props.setNextTabErrors({...props.nextTabErrors, ['merch_support_contact']: {value: props.rewardDropData.merchandise.support_contact, error: "Invalid Email or Phone Number"}})
            return false;
          } */
        }
        if (step == 1 && props.rewardDropData.category == "raffle") {
          if (props.rewardDropData.raffle.max_winners == "") {
            props.setNextTabErrors({ ...props.nextTabErrors, ["max_winners"]: { value: props.rewardDropData.raffle.max_winners, error: "MAX number of winners can't be blank" } });
            return false;
          }
          if (parseInt(props.rewardDropData.raffle.max_winners) < 1) {
            props.setNextTabErrors({ ...props.nextTabErrors, ["max_winners"]: { value: props.rewardDropData.raffle.max_winners, error: "MAX number of winners must be greater than 0" } });
            return false;
          }
          if (parseInt(props.rewardDropData.raffle.max_winners) > 999999999) {
            props.setNextTabErrors({ ...props.nextTabErrors, ["max_winners"]: { value: props.rewardDropData.raffle.max_winners, error: "MAX number of winners can't exceed 999999999" } });
            return false;
          }
          if (!ValidateEmail(props.rewardDropData.raffle.contact_email)) {
            props.setNextTabErrors({ ...props.nextTabErrors, ["raffle_contact_email"]: { value: props.rewardDropData.raffle.contact_email, error: "Enter valid email address" } });
            return false;
          }
        }
        if (step == 1 && props.rewardDropData.category == "poll") {
          let isError = false;
          let TabError = { ...props.nextTabErrors };
          if (props.rewardDropData.poll?.question == null || props.rewardDropData.poll?.question.trim() == "") {
            TabError["poll_question"] = { val: props.rewardDropData.poll.question, error: "Question can't be blank" };
            isError = true;
          }
          if (props.rewardDropData.poll?.choices.filter((item) => item == "").length > 0) {
            TabError["poll_choices"] = props.rewardDropData.poll?.choices.map((choice) => {
              return choice == "" ? { choice: { val: choice, error: "Choice can't be blank" } } : { choice: { val: choice, error: "" } };
            });
            isError = true;
          }

          if (isError) {
            props.setNextTabErrors(TabError);
            return false;
          }
        }
        if (step == 1 && props.rewardDropData.category == "event_pass") {
          if (props.rewardDropData.eventPass.event_date == "" || props.rewardDropData.eventPass.event_date == "Invalid date") {
            props.setNextTabErrors({ ...props.nextTabErrors, ["event_date"]: { value: props.rewardDropData.eventPass.event_date, error: "Event Date can't be blank" } });
            return false;
          }

          if (props.rewardDropData.eventPass.event_date["$d"] == "Invalid Date") {
            props.setNextTabErrors({ ...props.nextTabErrors, ["event_date"]: { value: props.rewardDropData.eventPass.event_date, error: "Invalid Date" } });
            return false;
          }

          if (props.rewardDropData.eventPass.event_date["$d"] !== "Invalid Date" && (moment() > moment(props.rewardDropData.eventPass.event_date["$d"]) || moment() > props.rewardDropData.eventPass.event_date)) {
            props.setNextTabErrors({ ...props.nextTabErrors, ["event_date"]: { value: props.rewardDropData.eventPass.event_date, error: "Event Date cannot be in past!" } });
            return false;
          }
        }
        if (step == 1 && props.rewardDropData.category == "gated_content") {
          if (props.rewardDropData.urls.filter((u_d) => u_d.Url == "").length > 0 || props.rewardDropData.urls.filter((u_d) => !isValidHttpUrl(u_d.Url)).length > 0 || props.rewardDropData.urls.filter((u_d) => u_d.ContentName == "").length > 0) {
            let filteredempty_urls = props.rewardDropData.urls;
            for (let a = 0; a < filteredempty_urls.length; a++) {
              let err_h = { ...props.nextTabErrors };
              if (filteredempty_urls[a].Url == "") {
                err_h["gatedContent"][a]["content_url"] = { value: filteredempty_urls[a].Url, error: "URL can't be blank" };
                props.setNextTabErrors(err_h);
              }

              if (filteredempty_urls[a].Url != "" && !isValidHttpUrl(filteredempty_urls[a].Url)) {
                err_h["gatedContent"][a]["content_url"] = { value: filteredempty_urls[a].Url, error: "Enter valid URL" };
                props.setNextTabErrors(err_h);
              }

              if (filteredempty_urls[a].ContentName == "") {
                err_h["gatedContent"][a]["content_name"] = { value: filteredempty_urls[a].ContentName, error: "Content Name can't be blank" };
                props.setNextTabErrors(err_h);
              }
            }
            return false;
          }
        }

        if (step == 1 && props.rewardDropData.category == "allow_list") {
          let filtered_list_urls = props.rewardDropData.allowList;

          if (filtered_list_urls.price != "" && parseFloat(filtered_list_urls.price) > 9999999) {
            props.setNextTabErrors({ ...props.nextTabErrors, ["allow_list_price"]: { value: filtered_list_urls.price, error: "Price can't exceed 9999999" } });
            return false;
          }
          if (filtered_list_urls.project_website != "" && !isValidHttpUrl(filtered_list_urls.project_website)) {
            props.setNextTabErrors({ ...props.nextTabErrors, ["allow_list_website"]: { value: filtered_list_urls.project_website, error: "Enter valid URL" } });
            return false;
          }
          if (filtered_list_urls.discord_url != "" && !isValidHttpUrl(filtered_list_urls.discord_url)) {
            props.setNextTabErrors({ ...props.nextTabErrors, ["allow_list_disord_url"]: { value: filtered_list_urls.discord_url, error: "Enter valid URL" } });
            return false;
          }
        }

        if (step == 1 && props.rewardDropData.category == "custom") {
          if (props.rewardDropData.custom.details.trim() == "") {
            props.setNextTabErrors({ ...props.nextTabErrors, ["custom_details"]: { value: props.rewardDropData.custom.details, error: "Details can't be blank" } });
            return false;
          }
        }

        if (["nfts", "reward_tokens"].includes(props.rewardDropData.category)) {
          setComingSoon(true);
          return;
        }

        if (props.multiStep >= 3) {
          if (props.multiStep == 3 && props.dropDataId == "") {
            await props.submitFormData();
            props.setShowDraftInfoAlert(true);
            setTimeout(() => {
              props.setShowDraftInfoAlert(false);
            }, 5000);
          } else {
            await props.updateFormData("not_publish", step, props.multiStep);
          }
        }

        // console.log("props.multiStep =>", step,props.multiStep, breadcrumbData)
        if (props.dropRewardCRD.api_error == true) {
          return false;
        } else {
          if (isLastStep()) {
            if (!props.formPublished) {
              gaEvent({
                category: `Reward Published - ${props.rewardDropData.category}`,
                action: "reward_published",
                label: `-${user.data.eth_address} -`,
                value: props.rewardDropData.id,
              });

              props.setFormPublished(true);
              await props.updateFormData("publish", step, props.multiStep);
              navigate(props.rewardDropData.community_type === "membership" ? "/rewards/membership" : "/rewards/community", { state: { published: true, is_scheduled: moment(props.rewardDropData.startAt) > moment(), data: props.rewardDropData } });

              // navigate(`/reward-drop/${props.rewardDropData.reward_drop_id}`);
            }
          } else if (step !== 2 && props.multiStep !== 4) {
            if (props.multiStep == 1 && props.rewardDropData.community_type === "membership") {
              props.setMultiStep(3);
            } else {
              props.setMultiStep(props.multiStep + 1);
            }

            if (props.multiStep == 1) {
              if (props.rewardDropData.community_type === "membership") {
                setBreadcrumbData((prev) => [...prev, { name: "Type", step: 0, multiStep: 3 }]);
                console.log("First");
              } else {
                setBreadcrumbData((prev) => [...prev, { name: "Advanced Targeting", step: 0, multiStep: 2 }]);
              }
            }
            if (props.multiStep === 2) {
              setBreadcrumbData((prev) => [...prev, { name: "Type", step: 0, multiStep: 3 }]);
            } else if (props.multiStep === 3) {
              setBreadcrumbData((prev) => [...prev, { name: "Additional Info", step: 0, multiStep: 4 }]);
            } else if (props.multiStep === 4) {
              setBreadcrumbData((prev) => [...prev, { name: "Details", step: 1, multiStep: 5 }]);
            } else {
              if (step == 1 && props.multiStep == 5) {
                setBreadcrumbData((prev) => [...prev, { name: "Tasks", step: 1, multiStep: 6 }]);
              } else if (step == 1 && props.multiStep == 6) {
                setStep((s) => s + 1);
                setBreadcrumbData((prev) => [...prev, { name: "Publishing Options", step: 2, multiStep: 7 }]);
              }
            }
          } else {
            setStep((s) => s + 1);

            if (step == 0 && props.multiStep == 4) {
              props.setMultiStep(props.multiStep + 1);
              setBreadcrumbData((prev) => [
                ...prev,
                {
                  name: "Details",
                  step: 1,
                  multiStep: 6,
                },
              ]);
            } else if (step == 1) {
              setBreadcrumbData((prev) => [
                ...prev,
                {
                  name: "Publishing Options",
                  step: 2,
                  multiStep: 8,
                },
              ]);
            }
            helpers.setTouched({});
          }
        }
      }}
    >
      {({ isSubmitting }) => (
        <Form autoComplete="off">
          <Box sx={{ background: "rgba(26, 22, 22, 0.02) !important", px: "2.5rem" }}>
            <StepperBox activeStep={step} sx={{ "&.MuiStepper-root": { display: "none" }, pb: "2rem", backgroundImage: "url(/assets/img/stepper-line.png)" }}>
              {childrenArray.map(
                (child, index) =>
                  child.props.label !== "It's Live" && (
                    <Step key={child.props.label} completed={step > index || completed}>
                      <StepLabel key={`steplabell-${child.props.label}`} className="steps_label" sx={{ color: "red" }}>
                        {child.props.label}
                      </StepLabel>
                    </Step>
                  )
              )}
            </StepperBox>
          </Box>

          {/* <DropBreadCrumbs breadCrumbs={breadcrumbData} setMultiStep={props.setMultiStep} setStep={setStep} setBreadcrumbData={setBreadcrumbData} rewardDropData={props.rewardDropData} /> */}
          {coupon && <CouponCodeModal open={coupon} handleClose={() => setCoupon(false)} rewardDropData={props.rewardDropData} />}
          {gatedPreview && <GatedContentModal open={gatedPreview} handleClose={() => setGatedPreview(false)} rewardDropData={props.rewardDropData} />}
          {eventPassPreview && <EventPassModal open={eventPassPreview} handleClose={() => setEventPassPreview(false)} rewardDropData={props.rewardDropData} />}
          {merchModal && <MerchandiseModal open={merchModal} handleClose={() => setMerchModal(false)} rewardDropData={props.rewardDropData} />}
          {raffleModal && <RaffleModal open={raffleModal} handleClose={() => setRaffleModal(false)} rewardDropData={props.rewardDropData} />}
          {allowListModal && <AllowListModal open={allowListModal} handleClose={() => setAllowListModal(false)} rewardDropData={props.rewardDropData} />}
          {pollModal && <PollModal open={pollModal} handleClose={() => setPollModal(false)} rewardDropData={props.rewardDropData} />}

          {comingSoon && <DropComingSoon open={comingSoon} handleClose={() => setComingSoon(false)} setShowInquiryAlert={props.setShowInquiryAlert} />}

          {currentChild}
          {!completed ? (
            <BoxActions p={4}>
              <Box display="flex" alignItems="center" justifyContent="space-between" width="712px" margin="auto">
                <Box width="233px">
                  <Box display={(props.rewardDropData.community_type === "membership" && props.multiStep !== 3) || ((props.rewardDropData.community_type === "" || props.rewardDropData.community_type === "community" || props.rewardDropData.community_type === "other") && props.multiStep != 1) ? "inline" : "none"}>
                    <Button
                      sx={{ borderRadius: "6px", fontSize: "1rem" }}
                      disabled={isSubmitting || props.multiStep === 1 || props.formPublished}
                      className="font-weight-600"
                      color="primary"
                      type="button"
                      onClick={async () => {
                        if (step === 0) {
                          if (props.rewardDropData.community_type === "membership" && props.multiStep === 3) {
                            props.setMultiStep(3);
                          } else {
                            props.setMultiStep(props.multiStep - 1);
                          }
                        } else {
                          props.setMultiStep(props.multiStep - 1);
                          if (props.multiStep !== 6) {
                            setStep((s) => s - 1);
                          }
                        }
                        setBreadcrumbData(breadcrumbData.filter((item, index) => index !== breadcrumbData.length - 1));
                      }}
                    >
                      Previous
                    </Button>
                  </Box>
                </Box>
                <Box className="dots" display="flex" flexDirection="row">
                  {Array.from({ length: formSteps }).map((c, i) => {
                    let indxNum = props.rewardDropData.community_type === "membership" ? i + 3 : i + 1;
                    return (
                      <StepLabel key={`stepes-${i}`} sx={{ pr: "10px" }}>
                        <CircleIcon fontSize="12px" sx={{ opacity: props.multiStep === indxNum ? 1 : 0.2, fill: indxNum > props.multiStep ? "#1A1616" : "#ED1F23" }} />
                      </StepLabel>
                    );
                  })}
                </Box>
                <Box width="233px" textAlign="right">
                  
                  {((props.rewardDropData.community_type === "membership" && step === 2) || ((props.rewardDropData.community_type === "" || props.rewardDropData.community_type === "community") && step == 2)) && (
                    <Button variant="outlined" color="primary" sx={{ mr: 1, backgroundColor: "transparent", borderRadius: "6px", height: '41px' }} onClick={() => saveDraftAndNavigate()} className="font-weight-600">
                      Save Draft
                    </Button>
                  )}
                  <Box display="inline">
                    <Button sx={{ borderRadius: "6px", pt: "9px", pb: "9px", fontSize: "1rem", height: '41px' }} startIcon={null} disabled={isSubmitting} variant="contained" color="primary" type="submit" ref={props.buttonRef} className="font-weight-600">
                      {isSubmitting ? "Submitting" : isLastStep() ? (props.rewardDropData.schedule_now ? "Publish" : "Schedule") : "Continue"}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </BoxActions>
          ) : null}
        </Form>
      )}
    </Formik>
  );
}

const mapStateToProps = (state) => ({
  rewardList: state.rewardList,
  collection: state.collections,
  rewardCRD: state.rewardCRD,
  user: state.user,
});

const mapDispatchToProps = {
  getCollections,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterWizard);
