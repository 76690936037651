import React, {useEffect, useState} from 'react';
import { Box, Typography, Button, Container, Card,  CardContent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow ,Paper,Alert,Snackbar } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import {DropTableRow} from 'src/components/DropItem/tablerow';
import  NewCollection from 'src/components/Modals/new_collection';
import  NewCollectionStep2 from 'src/components/Modals/new_collection_step_2';
import DeleteCollection from 'src/components/Modals/delete_collection';

import NoActiveCollection from 'src/components/NoActiveDrop/noActiveCollection';

import { getCollections,deleteCollections } from 'src/services/actions';
import {gtag} from "../../utils";

import { connect } from 'react-redux';

import SkeletonRowData from 'src/components/DropItem/skeletonRowData';

import {gaEvent} from "../../utils";
import ContactUsCta from 'src/components/ContactUsCta';


const Collections = (props) => {
  const [open, setOpen] = useState(false);
  const [openStep2, setOpenStep2] = useState(false);
  const [step2Addr, setStep2Addr] = useState('')
  const [step2Network, setStep2Network] = useState('')
  const [edit, setEdit] = useState(false);
  const [deleteBox, setDeleteBox] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [showSnackbar , setShowSnackbar] = React.useState(false)
  const {collection,user} =props
  const currentTab = window.location.pathname.split('/')[2];


  const handleOpen = () => {
    gaEvent({
      category: "Open Collection Model",
      label: `-${user.data.eth_address} -`,
      action: 'collection_created_popup'
    });

    setOpen(true)
  };
  // console.log("openstep2 =>",openStep2)
  const handleStep2Open = (contractAddress, network) => {
    setStep2Addr(contractAddress)
    setStep2Network(network)
    setOpenStep2(true)
  }

  const handleEdit = () => setEdit(true);

  const deleteTable = (id) => {
    setDeleteBox(true)
    setDeleteId(id)

    gaEvent({
      category: "Delete Collection",
      value: id,
      label: `-${user.data.eth_address} -`,
      action: 'collection_delete_popup'
    });
  }

  const deleteCollection = async() => {
    props.deleteCollections(deleteId)
  }
  useEffect(() => {
    if(collection.delete_collection_status =="success"){

      gtag('collection_deleted',{
        eth_address: ` ${user.data.eth_address} `
      });
      
      setDeleteBox(false)
    }
  }, [collection.delete_collection_status])

  useEffect(() => {
    props.getCollections(currentTab)
  }, [currentTab])

  const vertical =  'top';
  const horizontal = 'center';

  useEffect(() => {
    if(collection.collections.length == 0 && collection.new_collection_status == 'request'){
      setShowSnackbar(true)
      setTimeout(() => {
        setShowSnackbar(false)
      },6000)
    }
    if(collection.new_collection_status == 'success'){
      setOpen(false)
    }
  },[collection.collections])

  return (
    <>
        <Snackbar
          sx={{zIndex: '7 !important'}}
            anchorOrigin={{ vertical, horizontal }}
            open={showSnackbar}
            autoHideDuration={5000}
            key={vertical + horizontal}
          >
          <Alert severity="info" sx={{ width: '100%' }}>
            It may take a while
          </Alert>
        </Snackbar>
    <Box sx={{mx:11}} className='collections'>
      <Box sx={{pb: 3,pt:5, mb:7}} display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant='h1' className='title'>Community Collections</Typography>
        </Box>

        <Box>
          <Button variant="contained" onClick={handleOpen} className='btn'>
            <AddCircleOutlineIcon />&nbsp;Add New Collection
          </Button>
        </Box>
      </Box>     
      <Box sx={{ ml:3}} >
      {(collection.collections.length === 0) ?
        collection.type =='request' ? 
        <Card className='table-card'>
        <CardContent>
          <TableContainer component={Paper} sx={{borderRadius: 0, boxShadow: 0, py:5}}>
            <Container>
            <Table className='table' aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell width={300} className='table-head'>Collection</TableCell>
                  <TableCell width={150} className='table-head'>Size</TableCell>
                  <TableCell width={450} className='table-head'>Description</TableCell>
                  <TableCell width={300} className='table-head'>Network</TableCell>
                  <TableCell width={300} className='table-head'>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow className='collection-row'>
                <SkeletonRowData/>
                </TableRow>
              </TableBody>
            </Table>

            </Container>
          </TableContainer> 
        </CardContent>
        </Card>
        :
        <Box>
          <NoActiveCollection description="You don’t have any collections" onClick={handleOpen} addCollection={handleOpen}/>
          <Box textAlign="center">
            <ContactUsCta />
          </Box>
        </Box>
        :
        <Card className='table-card'>
          <CardContent>
            <TableContainer component={Paper} sx={{borderRadius: 0, boxShadow: 0, py:5}}>
              <Container>
                <Table className='table' aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell width={350} className='table-head'>Collection</TableCell>
                      <TableCell width={100} className='table-head'>Size</TableCell>
                      <TableCell width={650} className='table-head'>Description</TableCell>
                      <TableCell width={150} className='table-head' sx={{textAlign:"center"}}>Network</TableCell>
                      <TableCell width={250} className='table-head'>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody >
                    {collection.collections.sort((a,b) => a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1).map((item,index)=> {
                      return(
                        <DropTableRow key={item.id} rowData={item} handleOpen={handleEdit} onClick={deleteTable}/>    
                      )
                    })}
                  </TableBody>
                </Table>
              </Container>
            </TableContainer> 
          </CardContent>
        </Card>
        }
      </Box>
      { open &&   
      <NewCollection open={open} handleClose={() => setOpen(false)} handleStep2Open={handleStep2Open} />
      }
      { openStep2 &&   
      <NewCollectionStep2 open={openStep2} handleClose={() => setOpenStep2(false)} step2Addr={step2Addr} step2Network={step2Network}  />
      }
      <DeleteCollection open={deleteBox} handleClose={() => setDeleteBox(false)} delete={deleteCollection} />
    </Box>
    </>
  )
}



const mapStateToProps = (state) => ({
  collection : state.collections,
  user: state.user,
})

const mapDispatchToProps = {
  getCollections,
  deleteCollections
}

export default connect(mapStateToProps, mapDispatchToProps)(Collections)