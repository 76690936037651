import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, TextField, Grid, Button, Typography, Snackbar, Alert, Link } from "@mui/material";

import NotificationsIcon from '@mui/icons-material/Notifications';
import {connect} from 'react-redux';
import { updateAccountDetails, updateEmailResendVerificationAPI, updateRewardSettings, userAccountInfo, brandLogoUpload, brand_subdomain_availability_check,setToast, createOtp } from 'src/services/actions'
import { useLocation } from 'react-router-dom';

import {allowedCustomWallet, gaEvent} from "../../utils";
import OTPVerification from 'src/components/Modals/otp_verification';


const ProfileSettings = (props) => {
  const ALPHA_DASH_REGEX = /^[A-Za-z0-9]+$/;

  const location = useLocation();
  const navto = location?.state?.navto;
  const vertical = "top";
  const horizontal = "center";

  const [isSubmit, setIsSubmit] = useState(false);
  const [emailResend, setResendEmail] = React.useState({api:false,message:"",disableButton: false});
  const [open, setOpen] = useState(false);
  const [otpData, setOtpData] = useState({});
  const [accountUpdateAlert, setAccountUpdateAlert] = useState({ type: "", message: "" });
  const [settingsUpdateAlert, setSettingsUpdateAlert] = useState({ type: "", message: "" });
  const [confirmEmailAlert, setConfirmEmailAlert] = useState(false);
  const [emailChanged, setEmailChanged] = useState(false);
  const [newemailVerified, setNewemailVerified] = useState("unverified");
  const [updateInterval, setUpdateInterval] = useState(false);
  const [currentSubDomain, setCurrentSubDomain] = useState(false);

  const { user } = props;
  const [accountFormData, setAccountFormData] = useState({
    fname: user.data.fname,
    lname: user.data.lname,
    email: user.data.email,
    new_email: user.data.new_email,
  });

  useEffect(() => {
    if(accountFormData.email !== accountFormData.new_email && (accountFormData.new_email != null && accountFormData.new_email != '')){
      // setEmailChanged(true)
    } else {
      setEmailChanged(false);
    }
  }, [accountFormData]);

  const updateSettingsInfo = () => {
    props.userAccountInfo({}, (resp) => {
      if (resp.type == "success") {
        setAccountFormData({
          fname: resp.data.fname,
          lname: resp.data.lname,
          email: resp.data.email,
          new_email: resp.data.new_email,
        });
      }
    })
  }

  useEffect(() => {
    gaEvent({
      category: "User Settings",
      label: `-${user.data.eth_address}-`,
      action: `user_settings`,
    });
    setCurrentSubDomain(user.data.brand_handle);
  }, []);

  const [rewardPageSettings, setRewardPageSettings] = useState({
    background: user.data?.brand_data?.background,
    primary: user.data?.brand_data?.primary,
    accent: user.data?.brand_data?.accent,
    image: user.data?.brand_logo,
    logoUrl: user.data?.logo_url,
  });
  const [allowPreviewDisable, setAllowPreviewDisable] = useState(false);
  const [imgPreviewUrl, setImgPreviewUrl] = useState(rewardPageSettings.image == null ? "/images/dummy_logo.svg" : rewardPageSettings.image);
  useEffect(() => {
    if (user.type == "success") {
      setRewardPageSettings({
        ...rewardPageSettings,
        subdomain: user.data.brand_handle,
        background: user.data.brand_data?.background,
        primary: user.data.brand_data?.primary,
        accent: user.data.brand_data?.accent,
        image: user.data.brand_logo,
      });

      if (user.data.new_email_confirmation_token_expired) {
        setNewemailVerified("expired");
      } else {
        setNewemailVerified("unverified");
      }
    }
  }, [user]);

  useEffect(() => {
    if (user.data.brand_logo != "" && user.data.brand_logo != rewardPageSettings.image) {
      if (rewardPageSettings.image == "" || rewardPageSettings.image == null) {
        setAllowPreviewDisable(false);
        setImgPreviewUrl("/images/dummy_logo.svg");
      } else {
        setAllowPreviewDisable(true);
        brandLogoUpload(rewardPageSettings.image, "logo", (res) => {
          if (res.type == "success") {
            setImgPreviewUrl(res.s3url);
          } else {
            setImgPreviewUrl("/images/dummy_logo.svg");
          }
          setAllowPreviewDisable(false);
        });
      }
    }
  }, [rewardPageSettings.image]);

  const handleData = (type, val) => {
    let existing_data = { ...accountFormData };
    if (type == "new_email") {
      existing_data["email"] = val;
    } else {
      existing_data[type] = val;
    }
    setAccountFormData(existing_data);
  };

  const sendOtp = (emailValue=null) => {
    // console.log("accountFormData.email >",accountFormData.email)
    // console.log("accountFormData.new_email >",accountFormData.new_email)
    props.createOtp('email', {user_type: 'AdminUser', user_id: user.data.id, email: emailValue, themeInfo: { name: `step3`, logo: `${process.env.REACT_APP_BASE_API_URL}/assets/images/combomark.png`, bg_color: "#ED1F23", btn_color: '#ED1F23', btn_text_color: '#ffffff', txt_color: "#000000"}}, (resp) => {
      if (resp.type === 'success') {
        setOtpData(resp)
        setOpen(true)
      }
    })
  }

  const updateAccountSettings = () => {
    const u_data = {...accountFormData};
    console.log('udata =>',u_data)
    u_data['new_email'] = accountFormData.email;
    const userUpdte = props.updateAccountDetails(u_data, (resp) => {
      if (resp.type == "success") {
        gaEvent({
          category: "User Settings Updated",
          label: `-${user.data.eth_address}-`,
          action: `user_settings_updated`,
        });
        if(resp.data.new_email_update) {
          setEmailChanged(true)
          sendOtp(accountFormData.email)
          // props.setToast(true, {type: 'info', message: 'Please check your inbox and verify your new email address.'});
          // setAccountUpdateAlert({type: 'info', message: 'Please check your inbox and verify your new email address.'})
        } else {
          props.setToast(true, { type: "success", message: "Your settings have been updated." });
          // setAccountUpdateAlert({type: 'success', message: 'Your settings have been updated.'})
        }
      }
      console.log("resp.data =>",resp.data)
      if (resp.data.email != accountFormData.new_email) {
        let existingData = { ...accountFormData };
        existingData["email"] = resp.data.email;
        existingData["new_email"] = resp.data.new_email;
        setAccountFormData(existingData);

      }
    });
  };

  const resendConfirmation = ()=>{
    console.log("emailResend =>",emailResend)
    if(emailResend.api == false){
      setResendEmail({api:true,message:""})
      sendOtp(accountFormData.new_email);
      setResendEmail({api:false,message:"Email sent successfully.",disableButton:true});
      setNewemailVerified('unverified')
      setTimeout(() => {
        setResendEmail({...emailResend,disableButton:false});
      }, 180000)
      setOpen(true)
    }
  };

  const [allowedProfileEdit, setAllowedProfileEdit] = useState(["custom_wallet", "apple", "google"].includes(user.data?.wallet_type));

  return (
    <Box fixed mx={11} pt={4} className="settings">
      <Typography variant="h1" sx={{ mb: 1 }} className="title">
        Profile
      </Typography>
      <Typography variant="h4" sx={{ mb: 3 }} className="subhead">
        Need help with your account, or want to learn how to get the most out of Step3?{" "}
        <Link sx={{ cursor: "pointer" }} target="_blank" href="https://www.step3.io/bookacall">
          Talk to an expert
        </Link>
        .
      </Typography>
      <Box className="account-settings">
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={5}>
            <Card sx={{ borderRadius: 0, maxWidth: "475px", pt: 3, px: 3 }} className="account-card">
              <CardContent>
                <form noValidate style={{ height: "470px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                  <Box>
                    <TextField fullWidth margin="normal" label="First Name" name="fname" variant="outlined" InputLabelProps={{ shrink: true }} value={accountFormData.fname} placeholder="Enter First Name" onChange={(e) => handleData("fname", e.target.value)} />

                    <TextField fullWidth margin="normal" label="Last Name" name="lname" variant="outlined" InputLabelProps={{ shrink: true }} value={accountFormData.lname} placeholder="Enter Last Name" onChange={(e) => handleData("lname", e.target.value)} />
                    <TextField fullWidth margin="normal" required label="Email" name="email" type="email" variant="outlined" InputLabelProps={{ shrink: true }} value={accountFormData.email} placeholder="Enter Email" onChange={(e) => handleData("new_email", e.target.value)} disabled={allowedCustomWallet(user.data?.wallet_type)} />

                    {
                      !user.data.email_verified &&
                      <Box display="flex" mb={0.5} sx={{color: "#F49200", fontSize: '14px'}}>
                        Your email address is unverified.&nbsp;<Link onClick={() => sendOtp(user.data.email)} sx={{ color: "#000", textDecoration: 'underline', cursor: 'pointer' }}>Verify your email. </Link>
                      </Box>
                    }
                    {emailChanged && (
                      <Box display="flex" mb={0.5}>
                        <Typography variant="body1" sx={{ color: "#F49200" }}>
                          {accountFormData.new_email} ({newemailVerified})&nbsp;
                        </Typography>
                        {emailResend.disableButton ? (
                          <Link className="resend-btn" sx={{ cursor: "pointer" }}>
                            resend
                          </Link>
                        ) : (
                          <Link
                            className="resend-btn"
                            onClick={() => {
                              resendConfirmation();
                            }}
                            sx={{ cursor: emailResend.api ? "default" : "pointer" }}
                            disabled={emailResend.api}
                          >
                            {emailResend.api ? "sending..." : "resend"}
                          </Link>
                        )}
                      </Box>
                    )}

                    { user.data.wallet_type === 'mobile' &&
                    <TextField fullWidth margin="normal" inputProps={{ maxLength: 14 }} label="Phone" variant="outlined" InputLabelProps={{ shrink: true }} value={user.data.phone} placeholder={'(XXX)-XXX-XXXX'} disabled={user.data.wallet_type === 'mobile'} />
                    }

                    {(!allowedCustomWallet(user.data?.wallet_type) && user.data.wallet_type !== 'mobile') && <TextField fullWidth margin="normal" label="Wallet Address" variant="outlined" InputLabelProps={{ shrink: true }} inputProps={{ "aria-readonly": true }} value={user.data.eth_address} sx={{ input: { opacity: "0.2", color: "#223354" } }} disabled={true} />}
                  </Box>

                  <Box display="flex" justifyContent="space-between" sx={{ my: 2 }}>
                    <Box component="span">{/* <Button sx={{borderRadius: '6px', "&.Mui-disabled": {color: "#ED1F23 !important", opacity: "0.6"}}} className='btn-text'  disabled={allowedCustomWallet(user.data?.wallet_type)}>Clear</Button> */}</Box>
                    <Button onClick={updateAccountSettings} variant="contained" color="primary" className="btn-text" sx={{ borderRadius: "6px", "&.Mui-disabled": { bgcolor: "#ED1F23 !important", color: "#fff !important", opacity: "0.4" } }}>
                      Update
                    </Button>
                  </Box>
                </form>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        <Snackbar sx={{ zIndex: "7 !important", width: "auto" }} anchorOrigin={{ vertical, horizontal }} open={accountUpdateAlert.type != ""} autoHideDuration={5000} key={0}>
          <Alert icon={<NotificationsIcon fontSize="inherit" />} severity={accountUpdateAlert.type ? accountUpdateAlert.type : "success"} sx={{ width: "100%" }}>
            {accountUpdateAlert.message}
          </Alert>
        </Snackbar>
        <Snackbar sx={{ zIndex: "7 !important", width: "300px" }} anchorOrigin={{ vertical, horizontal }} open={settingsUpdateAlert.type != ""} autoHideDuration={5000} key={1}>
          <Alert icon={<NotificationsIcon fontSize="inherit" />} severity={settingsUpdateAlert.type ? settingsUpdateAlert.type : "success"} sx={{ width: "100%" }}>
            {settingsUpdateAlert.message}
          </Alert>
        </Snackbar>

        <Snackbar sx={{ zIndex: "7 !important" }} anchorOrigin={{ vertical, horizontal }} open={confirmEmailAlert} autoHideDuration={5000} key={2}>
          <Alert severity="success" sx={{ width: "100%" }}>
            Email sent successfully.
          </Alert>
        </Snackbar>

        {open && <OTPVerification open={open} handleClose={() => setOpen(false)} otpData={otpData} setOtpData={setOtpData} emailAddress={accountFormData.new_email ? accountFormData.new_email : user.data.email} updateSettingsInfo={updateSettingsInfo}  />}
      </Box>
    </Box>
  );
};
const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = {
  updateAccountDetails,
  updateRewardSettings,
  userAccountInfo,
  brand_subdomain_availability_check,
  setToast,
  createOtp
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileSettings);
