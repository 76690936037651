import React,{useEffect, useState} from 'react'
import { Modal, Box, Typography, TextField, Button, Snackbar, Alert, InputAdornment } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import {resetUpdateAdminUser, updateAdminUsers} from '../../../services/actions'
import { connect } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { isValidHttpUrl } from 'src/utils';


const MembershipBasicDetails = (props) => {
  const vertical =  'top';
  const horizontal = 'center';
  const { open, handleClose, user_data } = props;
  
  const [isSubmit, setIsSubmit] = useState(false);
  const [settingsUpdateAlert, setSettingsUpdateAlert] = useState({type: '', message: ''})
  const { userUpdate } = props;

  useEffect(() => {
    props.resetUpdateAdminUser()
  },[])
  

  const [pageInfoData, setPageInfoData] = useState({
    banner_url: user_data?.membership_page_info?.banner_url,
    page_title: {value:  user_data?.membership_page_info?.page_title, error: ""},
    page_subtitle: {value:  user_data?.membership_page_info?.page_subtitle, error: ""},
    membership_title: {value:  user_data?.membership_page_info?.membership_title, error: ""},
    membership_subtitle: {value:  user_data?.membership_page_info?.membership_subtitle, error: ""},
    membership_details: {value:  user_data?.membership_page_info?.membership_details, error: ""},
    other: {value: user_data?.membership_page_info?.other, error: ''},
  });

  const handlePageSettings =(type, val) => {
    let existing_data = {...pageInfoData};
    existing_data[type] = {value: val, error: ""}
    
    setPageInfoData(existing_data)
  }

  const updateRewardPageSettings = () => {
    let infoData = {...pageInfoData};
    /* if (pageInfoData.page_title.value === null || pageInfoData.page_title.value == "") {
      setPageInfoData({...pageInfoData, page_title: {value: pageInfoData.page_title.value, error: "Page title can't be blank"}});
      return false
    }

    if (pageInfoData.membership_title.value === null || pageInfoData.membership_title.value == "") {
      setPageInfoData({...pageInfoData, membership_title: {value: pageInfoData.membership_title.value, error: "Membership title can't be blank"}});
      return false
    } */

    try {
      JSON.parse(pageInfoData.other.value)
    } catch (e) {
      setPageInfoData({...pageInfoData, other: {value: pageInfoData.other.value, error: "Please enter a valid JSON."}});
      return false
    }
    
    setIsSubmit(true)
    let membership_page_settings = {};

    
    let dataKeys = Object.keys(infoData).filter((d) => d !== "banner_url")
    let newData = {}
    for (let i = 0; i < dataKeys.length; i++) {
      const keyName = dataKeys[i];
      if (keyName === 'other'){
        newData[keyName] = JSON.parse(pageInfoData[keyName].value)
      } else {
        newData[keyName] = pageInfoData[keyName].value
      }
    }
    newData['banner_url'] = infoData.banner_url
    membership_page_settings['membership_page_info'] = newData
    setIsSubmit(false)
    props.updateAdminUsers(membership_page_settings, user_data.id, (resp) => {
      if (resp.type == 'success') {
        setSettingsUpdateAlert({type: 'success', message: 'Your settings have been updated.'})
      } else {
        console.log("resp.message =>",resp.message)
        setSettingsUpdateAlert({type: 'error', message: resp.message})
      }
      setTimeout(() => {
        setSettingsUpdateAlert({type: '', message: ''});
      },5000);
      setIsSubmit(false)
      props.handleClose(true)
    })
  }

  const triggerImage = (e) => {
    let preview = document.getElementById("image_preview");
    if(e.target.id !="addImage" && e.target.id !="file_upload" && !preview.contains(e.target)){
      document.getElementById('addImage').click();
    }
  };
  const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>{
        handleFileSelect('banner_url', reader.result.toString())
      });

      reader.readAsDataURL(e.target.files[0]);
    }
  }
  const handleFileSelect =(type,fileobj)=>{
    let existing_data = {...pageInfoData}
    existing_data[type] = fileobj
    setPageInfoData(existing_data)
  }
  const removeImage =(e)=>{
    let existing_data = {...pageInfoData}
    existing_data['banner_url'] = ""
    setPageInfoData(existing_data)
  }

  return (
    <Box className="textareArea">
      <Box>

      <TextField sx={{mt:2}} fullWidth className='Add-image' variant="outlined" disabled placeholder={'Add a banner (optional)'} onClick={triggerImage} InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Button variant="outlined" component="label" id="addImage" className="font-weight-600">
              Browse... 
              <input type="file" id="file_upload" accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml" onChange={(e) => onSelectFile(e)} hidden  />
            </Button>
          </InputAdornment>
        ),
        startAdornment:(
          <InputAdornment position="start">
            <Box sx={{display:"flex"}}>
              <Box sx={{position: 'relative'}} id="image_preview">
                {pageInfoData.banner_url ?
                <>
                <img src={isValidHttpUrl(pageInfoData.banner_url) ? pageInfoData.banner_url : (pageInfoData.banner_url)} height="20" alt="" />
                <Button id="removeImage" sx={{position: 'absolute', top: '-5px',right:'-5px',padding:'3px',minWidth:'0',display:'flex',justifyContent:'center',alignItems:'center', backgroundColor:'rgba(0,0,0)',padding:0,color: '#fff',':hover': {backgroundColor: '#000'}}} onClick={removeImage}><CloseIcon sx={{fontSize: '1rem !important'}}/></Button>
                </>
                :
                <>
                <img src="/assets/images/empty-img.svg" height="20" alt=""/>
                </>
              }
              </Box>
              <Typography variant="body1" ml={1}>{pageInfoData.banner_url?.name}</Typography>
            </Box>
          </InputAdornment>
        )
      }} />
      <Typography variant="body2" sx={{color: "#223354", opacity: "0.5", fontSize: "12px", mt: 0.5, mb: 1}}>Recommended dimensions: 1700px x 215px</Typography>

        <TextField fullWidth margin="normal" label='Page TITLE' name="page_title" variant="outlined" InputLabelProps={{shrink: true}} inputProps={{ maxLength: 60, minLength: 3 }} value={pageInfoData.page_title.value} placeholder="Enter the page title" error={pageInfoData.page_title.error !== ""} onChange={(e) => handlePageSettings("page_title", e.target.value)} FormHelperTextProps={{ style: { textAlign: 'right', color: (pageInfoData.page_title.value?.length == 60 || pageInfoData.page_title.error !== "") ? 'red' : 'rgba(34, 51, 84, 0.7)' } }} helperText={<Box display="flex" justifyContent={"space-between"}>
          <Box>
            {pageInfoData.page_title.error == '' ? '' : pageInfoData.page_title.error}
          </Box>
          {pageInfoData.page_title.value?.length > 0 ? `${pageInfoData.page_title.value?.length} / 60` : false}
          </Box>
          }
        />
        
        <TextField fullWidth margin="normal" label='Page SubTitle' required={false} name="page_subtitle" variant="outlined" InputLabelProps={{shrink: true}} inputProps={{ maxLength: 84, minLength: 3 }} value={pageInfoData.page_subtitle.value} placeholder="Enter the page subtitle" error={pageInfoData.page_subtitle.error !== ""} onChange={(e) => handlePageSettings("page_subtitle", e.target.value)} FormHelperTextProps={{ style: { textAlign: 'right', color: (pageInfoData.page_subtitle.value?.length == 84 || pageInfoData.page_subtitle.error !== "") ? 'red' : 'rgba(34, 51, 84, 0.7)' } }} helperText={<Box display="flex" justifyContent={"space-between"}>
          <Box>
            {pageInfoData.page_subtitle.error == '' ? '' : pageInfoData.page_subtitle.error}
          </Box>
          {pageInfoData.page_subtitle.value?.length > 0 ? `${pageInfoData.page_subtitle.value?.length} / 84` : false}
          </Box>
          } />

        <TextField fullWidth margin="normal" label='Membership Title' name="membership_title" variant="outlined" InputLabelProps={{shrink: true}} inputProps={{ maxLength: 60, minLength: 3 }} value={pageInfoData.membership_title.value} placeholder="Membership Details" error={pageInfoData.membership_title.error !== ""} onChange={(e) => handlePageSettings("membership_title", e.target.value)} FormHelperTextProps={{ style: { textAlign: 'right', color: (pageInfoData.membership_title.value?.length == 60 || pageInfoData.membership_title.error !== "") ? 'red' : 'rgba(34, 51, 84, 0.7)' } }} helperText={<Box display="flex" justifyContent={"space-between"}>
          <Box>
            {pageInfoData.membership_title.error == '' ? '' : pageInfoData.membership_title.error}
          </Box>
          {pageInfoData.membership_title.value?.length > 0 ? `${pageInfoData.membership_title.value?.length} / 84` : false}
          </Box>
          } />

        <TextField fullWidth multiline inputProps={{maxLength: 150}} rows="3" margin="normal" required={false} label='Membership SubTitle' name="text" variant="outlined" InputLabelProps={{shrink: true}} placeholder='Become a member and unlock deals, perks and exclusive access!' onChange={(e) => handlePageSettings("membership_subtitle", e.target.value)} value={pageInfoData.membership_subtitle.value} FormHelperTextProps={{ style: { textAlign: 'right',color: (pageInfoData.membership_subtitle.value?.length == 150 || pageInfoData.membership_subtitle.error !== "") ? 'red' : 'rgba(34, 51, 84, 0.7)' } }} error={pageInfoData.membership_subtitle.error !== ""} helperText={<Box display="flex" justifyContent={"space-between"}>
          <Box>
            {pageInfoData.membership_subtitle.error == '' ? '' : pageInfoData.membership_subtitle.error}
          </Box>
          {pageInfoData.membership_subtitle.value?.length > 0 ? `${pageInfoData.membership_subtitle.value?.length} / 150` : false}
          </Box>
          } />

        <TextField fullWidth multiline inputProps={{maxLength: 150}} rows="3" margin="normal" required={false} label='Membership Details' name="text" variant="outlined" InputLabelProps={{shrink: true}} placeholder='Enter the details of your membership program.' onChange={(e) => handlePageSettings("membership_details", e.target.value)} value={pageInfoData.membership_details.value} FormHelperTextProps={{ style: { textAlign: 'right',color: (pageInfoData.membership_details.value?.length == 60 || pageInfoData.membership_details.error !== "") ? 'red' : 'rgba(34, 51, 84, 0.7)' } }} error={pageInfoData.page_subtitle.value?.length == 150 || pageInfoData.membership_details.error !== ""} helperText={<Box display="flex" justifyContent={"space-between"}>
          <Box>
            {pageInfoData.membership_details.error == '' ? '' : pageInfoData.membership_details.error}
          </Box>
          {pageInfoData.membership_details.value?.length > 0 ? `${pageInfoData.membership_details.value?.length} / 150` : false}
          </Box>
        } />
        
        <TextField fullWidth multiline rows={5} margin="normal" label='Advanced Settings' name="other" variant="outlined" InputLabelProps={{shrink: true}} onChange={(e) => handlePageSettings('other', e.target.value)} value={(typeof pageInfoData?.other?.value === 'object')? JSON.stringify(pageInfoData?.other?.value) : pageInfoData?.other?.value} error={pageInfoData.other.error !== ''} FormHelperTextProps={{ style: { wordWrap:'break-word' } }} helperText={pageInfoData.other.error !== '' ? pageInfoData.other.error : (`{"layout":"default/aiea/bg","logout_url":"https://www.step3.io/","is_home_page":false,"deem_login":true,"stripe_enable":true,"reward_ids":[1,2,3],"allow_static_data":false,"stripe_product":{"name":"Testing Product","price":"","application_fee_percent":0,"recurringType":"year"},"login_page_reward_id":3,"nft_settings":{"arts":{"default":"","xxx":"","yyy":""}}}`)}  />
      </Box>

      <Box display={'flex'} justifyContent={'space-between'} mt={3}>
        <Button variant="outlined" color="primary" onClick={handleClose}>Cancel</Button>
        <Button variant="contained" color="primary" sx={{cursor: userUpdate.type == "request" ? "not-allowed":"pointer", opacity:userUpdate.type == "request" ? '0.5':"1"}} onClick={userUpdate.type !== "request" ? updateRewardPageSettings : undefined}>{userUpdate.type == "request" ? "Updating..." : "Update"}</Button>
      </Box>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  userUpdate: state.userUpdate,
  adminUsers: state.adminUsers
})

const mapDispatchToProps = {
  resetUpdateAdminUser,
  updateAdminUsers
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipBasicDetails)