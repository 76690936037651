import React, {useEffect, useState} from 'react'
import { Box, Typography, Button, Card, CardContent, CardMedia, Grid } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import RewardsData from 'src/components/RewardsData';

import { resetRewardCRD, getDrops } from 'src/services/actions';
import ContactUsCta from 'src/components/ContactUsCta';

const MembershipRewards = (props) => {
  const { user } = props;
  const [isMembership, setIsMembership] = useState(false);
  const navigate = useNavigate()
  useEffect(() => {
    props.resetRewardCRD();
  },[]);

  useEffect(() => {
    props.getDrops('membership');
  }, []);

  useEffect(() => {
    if(user.data?.other_data?.allow_membership_settings){
      setIsMembership(true)
    }else{
      setIsMembership(false)
    }
  }, [user.data.membership_page_info])


  let disabledOutlineButtonStyling = {
    opacity: "0.5", cursor: "default", "&:hover":{background: "transparent", border: `1px solid rgba(237, 31, 35, 0.5)`}
  }
  let disabledContainedButtonStyling = {
    opacity: "0.5", cursor: "default", "&:hover":{background: "rgba(237, 31, 35, 1)"}, ml: 1
  }

  return (
    <Box sx={{mx:11}} className="rewards-section">
      <Box sx={{pb: 3,pt:5}} display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant='h1' className='title' sx={{mb:0.4}}>Membership Rewards{/*  & Benefits */}</Typography>
          <Typography variant='body' className='subtitle'>Create and manage rewards for your membership program.</Typography>
        </Box>
        <Box>
          <Button variant="outlined" sx={(!isMembership) && {...disabledOutlineButtonStyling}} onClick={() => {
            if(isMembership){
              window.open(user.data.full_brand_domain)
            }
          }} color='primary' className='btn'>View Membership Page</Button>

          <Button variant="contained" color='primary' sx={(!isMembership) ? {...disabledContainedButtonStyling } : {ml: "15px"}} onClick={() => {
            if(isMembership){
              navigate("/rewards/membership/new", {state: {reward_type: 'membership'}})
            }
          }} state={{reward_type: 'membership'}} className='btn' >Create Reward</Button>
        </Box>
      </Box>
      {
        (isMembership) ?
        <RewardsData community_type="membership" />
        :
        <Box height="600px" display="flex" flexDirection="column" justifyContent="space-between">
          <Box className="audience">
            <Typography textAlign="center" className='no-membership-title' mt={3.12} mb="30px" variant="h2">Your membership program needs to be enabled.</Typography>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item lg={2.5}>
                <Card className="no-membership" sx={{ cursor: "default !important", p: '22.405px 17.924px 22.405px 8.962px' }}>
                  <CardContent sx={{ textAlign: "center", pb:'0 !important', p: '0 !important' }}>
                    <Box  display="flex" justifyContent="center">
                      <CardMedia
                        component="img"
                        sx={{ objectFit: "contain", width: "97px" }}
                        image="/assets/img/onboarding-membership-overview.svg"
                        alt="membership"
                      />
                    </Box>
                    <Typography gutterBottom variant="h2" component="div" p={0} pt="17.92px">
                      Supercharge loyalty with a membership program
                    </Typography>
                    <Typography variant="body2" color="text.secondary" p={0}>
                      Learn how membership programs foster customer loyalty and build better customer relationships.
                    </Typography>
                      <Button target='_blank' href="https://www.step3.io/landing-pages/bookacall" variant="contained" size="small" color="info" sx={{"&:hover": {textDecoration: "none"}}}>
                        Learn More
                      </Button>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <ContactUsCta />
          </Box>
        </Box>
      }
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = {
  resetRewardCRD,
  getDrops,
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipRewards)