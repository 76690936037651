import React, {useEffect, useState} from 'react'
import { Box, Typography, Button, Link } from '@mui/material';

import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';

import RewardsData from 'src/components/RewardsData';

import { resetRewardCRD, getDrops } from 'src/services/actions';

const CommunityRewards = (props) => {
  const { user } = props;
  useEffect(() => {
    props.resetRewardCRD();
  },[]);

  useEffect(() => {
    props.getDrops('community');
  }, []);

  return (
    <Box sx={{mx:11}} className="rewards-section">
      <Box sx={{pb: 3,pt:5}} display="flex" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant='h1' className='title'>Community Page Rewards</Typography>
          <Typography variant='body1' className='subtitle'>Learn more about offering rewards to other digital communities.</Typography>
        </Box>
        <Box>
          <Button variant="outlined" href={`${user.data.full_brand_domain}/c`} target='_blank' component={Link} className='btn' sx={{ml: 1}}>View Community Page</Button>
          <Button variant="outlined" to="/collections" component={NavLink} className='btn' sx={{ml: "15px"}}>Manage Collections</Button>
          <Button variant="contained" to="/rewards/community/new" component={NavLink} state={{reward_type: 'community'}} className='btn' sx={{ml: "15px"}}>Create Reward</Button>
        </Box>
      </Box>
      <RewardsData community_type="community" />
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = {
  resetRewardCRD,
  getDrops,
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunityRewards)