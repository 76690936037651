import React, { useRef, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Box, Typography, Link as AnchorLink, Snackbar, Alert, Card, TextField, InputAdornment, Button, styled } from "@mui/material";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import { gaEvent, isValidHttpUrl, claimPgUrl, getTextColor, checkImageFileExt, validateImgDimension, handleImageForCompress } from "src/utils";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsIcon from "@mui/icons-material/Notifications";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { brandLogoUpload, updateCommunitySettings, setToast } from "src/services/actions";
import CropperModal from "src/components/Modals/img_cropper";
import ResetMembershipSettings from "src/components/Confirmation/reset_membership_settings";

const PreviewButton = styled(Button)(
  ({ theme }) => `
    display: flex;
    justify-content: center;
    background: #ECFBE6;
    border-radius: 6px;
    color: #3BB900;
    &:hover{
        background: #ECFBE6;
    }
  `
);

const CommunitySettings = (props) => {
  const vertical = "top";
  const horizontal = "center";
  const { user } = props;

  const [focused, setFocused] = useState({
    page_title: false,
    page_subtitle: false,
  });

  const handlfocus = (key, is_focus) => {
    const cFocused = { ...focused };
    cFocused[key] = is_focus;
    setFocused(cFocused);
  };
  const [isSubmit, setIsSubmit] = useState(false);
  const [settingsUpdateAlert, setSettingsUpdateAlert] = useState({ type: "", message: "" });
  const [allowPreviewDisable, setAllowPreviewDisable] = useState(false);
  const [openCopiedBar, setOpenCopiedBar] = useState(false);
  const [defaultLogoUrl, setDefaultLogoUrl] = useState(() => () => {
    const txtColor = getTextColor(user.data.brand_data.background, "#ffffff");
    if (txtColor === "#000000") {
      return "/images/dummy_logo_black.svg";
    } else {
      return "/images/dummy_logo_white.svg";
    }
  });
  const [imgLogoUrl, setImgLogoUrl] = useState(user.data.brand_logo == null ? defaultLogoUrl : user.data.brand_logo);
  const [imgPreviewUrl, setImgPreviewUrl] = useState(user.data?.community_page_info?.banner_url == "" ? "" : user.data?.community_page_info?.banner_url);
  const [resetDomainModal, setResetDomainModal] = useState(false);
  const [pageInfoData, setPageInfoData] = useState({
    banner_url: user.data?.community_page_info?.banner_url,
    page_title: { value: user.data?.community_page_info?.page_title === undefined ? "Community Rewards" : user.data?.community_page_info?.page_title, error: "" },
    page_subtitle: { value: user.data?.community_page_info?.page_subtitle === undefined ? null : user.data?.community_page_info?.page_subtitle, error: "" },
  });

  const copyToClipboard = (str) => {
    navigator.clipboard.writeText(str);

    props.setToast(true, { type: "success", message: "Copied to clipboard!" });
  };

  const triggerImage = (e) => {
    let preview = document.getElementById("image_preview");
    // console.log("preview.contains(e.target) =>",preview.contains(e.target))
    if (e.target.id != "addImage" && e.target.id != "file_upload" && !preview.contains(e.target)) {
      document.getElementById("addImage").click();
    }
  };
  /* const onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () =>{
        handleFileSelect('banner_url', reader.result.toString())
      });

      reader.readAsDataURL(e.target.files[0]);
    }
  } */
  const handleFileSelect = (type, fileobj) => {
    let existing_data = { ...pageInfoData };
    existing_data[type] = fileobj;
    setPageInfoData(existing_data);
  };
  const removeImage = (e) => {
    let existing_data = { ...pageInfoData };
    existing_data["banner_url"] = "";
    setPageInfoData(existing_data);
  };
  const previewBtnClick = () => {
    window.open(`${user.data.full_brand_domain}/preview/?backgroundColor=${user.data.brand_data.background}&primaryColor=${user.data.brand_data.primary}&accentColor=${user.data.brand_data.accent}&logo=${encodeURIComponent(imgLogoUrl)}&pageTitle=${pageInfoData.page_title.value != undefined ? encodeURIComponent(pageInfoData.page_title.value) : ""}&bannerUrl=${imgPreviewUrl}&pageSubtitle=${pageInfoData.page_subtitle.value != undefined ? encodeURIComponent(pageInfoData.page_subtitle.value) : ""}`, "_blank");
    // window.open(`${user.data.full_brand_domain}/preview/`, '_blank')
  };
  const resetRewardPageSettings = () => {
    setPageInfoData({
      banner_url: "",
      page_title: { value: "Community Rewards", error: "" },
      page_subtitle: { value: "", error: "" },
    });
  };
  const resetSettings = () => {
    console.log("Test");
    setResetDomainModal(true);
  };

  const handlePageSettings = (type, val) => {
    let existing_data = { ...pageInfoData };
    existing_data[type] = { value: val, error: "" };

    setPageInfoData(existing_data);
  };

  const updateRewardPageSettings = () => {
    /* if (pageInfoData.page_title.value === null || pageInfoData.page_title.value == "") {
      setPageInfoData({...pageInfoData, page_title: {value: pageInfoData.page_title.value, error: "Page title can't be blank"}});
      return false
    } */

    setIsSubmit(true);
    let newData = {};
    let infoData = { ...pageInfoData };
    let community_page_settings = {};
    newData["banner_url"] = infoData.banner_url;
    newData["page_title"] = infoData.page_title.value;
    newData["page_subtitle"] = infoData.page_subtitle.value;
    community_page_settings["community_page_info"] = newData;
    props.updateCommunitySettings("community", community_page_settings, (resp) => {
      if (resp.type == "success") {
        // setSettingsUpdateAlert({type: 'success', message: 'Your settings have been updated.'})
        props.setToast(true, { type: "success", message: "Your settings have been updated." });

        gaEvent({
          category: "Community Page Settings Updated",
          label: `-${user.data.eth_address}-`,
          action: `community_page_settings_updated`,
        });
      } else {
        gaEvent({
          category: `Community Page Settings error ${resp.message}`,
          label: `-${user.data.eth_address}-`,
          action: `community_page_settings_update_error`,
        });
        // setSettingsUpdateAlert({type: 'error', message: resp.message})
        props.setToast(true, { type: "error", message: resp.message });
      }
      setIsSubmit(false);
    });
  };
  useEffect(() => {
    if ((user.data?.community_page_info?.banner_url != undefined || user.data?.community_page_info?.banner_url != "") && user.data?.community_page_info?.banner_url != pageInfoData.banner_url) {
      if (pageInfoData.banner_url == "" || pageInfoData.banner_url == null) {
        setAllowPreviewDisable(false);
        setImgPreviewUrl("");
      } else {
        setAllowPreviewDisable(true);
        brandLogoUpload(pageInfoData.banner_url, "community", (res) => {
          if (res.type == "success") {
            setImgPreviewUrl(res.s3url);
          } else {
            setImgPreviewUrl("");
          }
          setAllowPreviewDisable(false);
        });
      }
    }
  }, [pageInfoData.banner_url]);

  const [open, setOpen] = useState(false);
  const imgRef = useRef(null);
  const [imgSrc, setImgSrc] = useState("");

  const [crop, setCrop] = useState({
    unit: "%",
    aspect: 7.9069,
    x: 0,
    y: 30,
    width: "100",
    height: 37.81,
  });

  const onSelectFile = (e) => {
    const selectedFile = e.target.files[0];
    validateImgDimension(selectedFile, 600, 215, 1700, 215).then((res) => {
      if (res.type === 'error') {
        let message = "Upload an image with minimum size 600x215";
        props.setToast(true, { type: "end_error", message: message });
      } else {
        if (selectedFile) {
          handleImageForCompress(selectedFile, res.fileWidth, res.fileHeight)
            .then((compressedDataURL) => {
              // console.log('Compressed Image Data URL:', compressedDataURL);
              handleFileSelect("banner_url", compressedDataURL);
              // You can use the compressedDataURL as needed
            })
            .catch((error) => {
              console.error('Error compressing image:', error);
            });
        }
      }
    })
    
      
    document.getElementById('file_upload').value = '';
  };

  // console.log("imgSrc ====>",(String(imgSrc)))
  const onImageLoaded = (image) => {
    imgRef.current = image;
  };

  const onCropComplete = (crop) => {
    makeClientCrop(crop);
  };

  const makeClientCrop = async (crop) => {
    if (imgRef.current && crop.width && crop.height) {
      const croppedImageUrl_val = await getCroppedImg(imgRef.current, crop, "newFile.png");
      handleFileSelect("banner_url", croppedImageUrl_val);
    }
  };

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width * scaleX, crop.height * scaleY);

    return new Promise((resolve, reject) => {
      resolve(canvas.toDataURL("image/png"));
    });
  };

  return (
    <Box className="design-settings">
      <Box>
        <Typography variant="h3">Community Rewards Page</Typography>
        <Typography variant="h6" mb="25px">
          Your community page lets you collaborate and offer rewards to other Web3 communities or brands with NFTs.{" "}
          <a href="https://stepthree.helpkit.so/community-and-membership-page-settings/bj8WWcbURnEJfX6E7YzaLR/community-page-settings/mLTfiAphzVqAiWnL1a6ycD" rel="noreferrer" style={{ color: "inherit" }} target="_blank">
            Learn more about community collaborations.
          </a>
        </Typography>

        <Box>
          <Typography variant="h4">Community Page URL</Typography>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h5">
              <a href={`${user.data.full_brand_domain}/c`} style={{ color: "inherit" }} target="_blank" rel="noreferrer">
                {`${user.data.full_brand_domain}/c`}
              </a>
            </Typography>
            <AnchorLink onClick={(e) => copyToClipboard(`${user.data.full_brand_domain}/c`)} sx={{ cursor: "pointer" }} underline="none">
              <Card sx={{ bgcolor: "#E8F1FF", padding: "10px", marginLeft: "11px", alignItems: "center", display: "flex", boxShadow: 'none !important' }}>
                <InsertLinkIcon sx={{ color: "#0061F4" }} />
              </Card>
            </AnchorLink>
          </Box>
        </Box>

        <Box mt="25px">
          <Typography variant="h4">Content Options</Typography>
          <form noValidate>
            <TextField
              sx={{ mt: "10px", "& .MuiInputBase-input": {py: '24.5px !important'} }}
              fullWidth
              className="Add-image"
              variant="outlined"
              disabled
              placeholder={"Add a banner (optional)"}
              onClick={triggerImage}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button variant="outlined" component="label" id="addImage" className="font-weight-600">
                      Browse...
                      <input
                        type="file"
                        id="file_upload"
                        accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
                        onChange={(e) => {
                          if (checkImageFileExt(e)) {
                            onSelectFile(e);
                          } else {
                            props.setToast(true, { type: "end_error", message: "Please choose a correct file type" });
                          }
                        }}
                        hidden
                      />
                    </Button>
                  </InputAdornment>
                ),
                startAdornment: (
                  <InputAdornment position="start">
                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ position: "relative" }} id="image_preview">
                        {pageInfoData.banner_url ? (
                          <>
                            <img src={isValidHttpUrl(pageInfoData.banner_url) ? pageInfoData.banner_url : pageInfoData.banner_url} height="20" alt="" />
                            <Button id="removeImage" sx={{ position: "absolute", top: "-5px", right: "-5px", padding: "3px", minWidth: "0", display: "flex", justifyContent: "center", alignItems: "center", backgroundColor: "rgba(0,0,0)", padding: 0, color: "#fff", ":hover": { backgroundColor: "#000" } }} onClick={removeImage}>
                              <CloseIcon sx={{ fontSize: "1rem !important" }} />
                            </Button>
                          </>
                        ) : (
                          <>
                            <img src="/assets/images/empty-img.svg" height="20" alt="" />
                          </>
                        )}
                      </Box>
                      <Typography variant="body1" ml={1}>
                        {pageInfoData.banner_url?.name}
                      </Typography>
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
            <Typography variant="body2" sx={{ color: "#223354", opacity: "0.5", fontSize: "13px", mt: 0.5, mb: 1 }}>
              Recommended dimensions:  1,500 x 300 pixels
            </Typography>

            <TextField
              fullWidth
              margin="normal"
              label="Page TITLE"
              name="page_title"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 60, minLength: 3 }}
              value={pageInfoData.page_title.value}
              placeholder="Enter the page title"
              error={pageInfoData.page_title.error !== ""}
              onFocus={() => handlfocus("page_title", true)}
              onBlur={() => handlfocus("page_title", false)}
              onChange={(e) => handlePageSettings("page_title", e.target.value)}
              FormHelperTextProps={{ style: { textAlign: "right", color: pageInfoData.page_title.value?.length == 60 || pageInfoData.page_title.error !== "" ? "red" : "rgba(34, 51, 84, 0.7)" } }}
              helperText={
                (focused.page_title) ?
                <Box display="flex" justifyContent={"space-between"}>
                  <Box>{pageInfoData.page_title.error == "" ? "" : pageInfoData.page_title.error}</Box>
                  <Box component="span" className="charTxt">
                    <Box component="span" display={focused.page_title ? "block" : "none"}>
                      {pageInfoData.page_title.value?.length > 0 ? `${pageInfoData.page_title.value?.length} / 60` : false}
                    </Box>
                  </Box>
                </Box>
                :
                <></>
              }
            />

            <TextField
              fullWidth
              margin="normal"
              label="Page SubTitle"
              name="page_subtitle"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 84, minLength: 3 }}
              value={pageInfoData.page_subtitle.value}
              placeholder="Enter the page subtitle"
              error={pageInfoData.page_subtitle.error !== ""}
              onFocus={() => handlfocus("page_subtitle", true)}
              onBlur={() => handlfocus("page_subtitle", false)}
              onChange={(e) => handlePageSettings("page_subtitle", e.target.value)}
              FormHelperTextProps={{ style: { textAlign: "right", color: pageInfoData.page_subtitle.value?.length == 84 || pageInfoData.page_subtitle.error !== "" ? "red" : "rgba(34, 51, 84, 0.7)" } }}
              helperText={
                focused.page_subtitle ?
                <Box display="flex" justifyContent={"space-between"}>
                  <Box>{pageInfoData.page_subtitle.error == "" ? "" : pageInfoData.page_subtitle.error}</Box>
                  <Box component="span" className="charTxt">
                    <Box component="span" display={focused.page_subtitle ? "block" : "none"}>
                      {pageInfoData.page_subtitle.value?.length > 0 ? `${pageInfoData.page_subtitle.value?.length} / 84` : false}
                    </Box>
                  </Box>
                </Box>
                :
                <></>
              }
            />

            <Box display="flex" justifyContent="space-between" sx={{ mt: "40px", mb: 2 }}>
              <Button sx={{ borderRadius: "6px" }} onClick={resetSettings} className="btn-text">
                Reset
              </Button>

              <Box display="flex">
                <PreviewButton onClick={previewBtnClick} disabled={allowPreviewDisable} className="font-weight-600">
                  <RemoveRedEyeIcon />
                  &nbsp;Preview{" "}
                </PreviewButton>
                <Button variant="contained" onClick={updateRewardPageSettings} color="primary" sx={{ borderRadius: "6px", ml: 1 }} className="btn-text" disabled={isSubmit}>
                  {isSubmit ? "Updating..." : "Update"}
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
      <Snackbar sx={{ zIndex: "7 !important" }} anchorOrigin={{ vertical, horizontal }} open={openCopiedBar} autoHideDuration={5000} key={vertical + horizontal}>
        <Alert severity="success" sx={{ width: "100%" }}>
          Copied to clipboard!
        </Alert>
      </Snackbar>

      <CropperModal open={open} handleClose={() => setOpen(false)} imgSrc={imgSrc} setCompletedCrop={onCropComplete} setCrop={setCrop} crop={crop} imgRef={imgRef} onImageLoaded={onImageLoaded} modal_title={"Banner"} />

      {settingsUpdateAlert.type != "" && (
        <Snackbar sx={{ zIndex: "7 !important", width: "300px" }} anchorOrigin={{ vertical, horizontal }} open={settingsUpdateAlert.type != ""} autoHideDuration={5000} key={1}>
          <Alert icon={<NotificationsIcon fontSize="inherit" />} severity={settingsUpdateAlert.type != "" ? settingsUpdateAlert.type : ""} sx={{ width: "100%" }}>
            {settingsUpdateAlert.message}
          </Alert>
        </Snackbar>
      )}

      {resetDomainModal && <ResetMembershipSettings open={resetDomainModal} handleClose={() => setResetDomainModal(false)} updateDomainSettings={resetRewardPageSettings} />}
    </Box>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});
const mapDispatchToProps = {
  updateCommunitySettings,
  setToast,
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunitySettings);
