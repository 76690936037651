import React, { useState} from 'react';
import { Modal, Box, Typography, TextField, Grid, Button, Snackbar, Alert, InputAdornment} from '@mui/material';
import { connect } from 'react-redux';
import { createNewPartner, updatePartner, setToast } from 'src/services/actions';
import { checkImageFileExt, handleImageForCompress, validateImgDimension } from 'src/utils';
import CloseIcon from '@mui/icons-material/Close';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  borderRadius: '8px',
  pt:5
};

const PartnerForm = (props) => {
  const [isSubmit, setIsSubmit] = useState(false);
  
  const { open, handleClose, user, formTitle, formData, setformData, partnerUser } = props;


  const handleChange = (type, val) => {
    const formvals = {...formData};
    if (type == 'email') {
      setformData({...formData, 'email':{value: '', error: ''}});
    }
    formvals[type] = {value: val, error: ''}
    setformData(formvals)
  }

  const onSelectFile = (imgType, e) => {
    const selectedFile = e.target.files[0];
    let tmpFileValidate = (file) => {
  return new Promise((resolve, reject) => {
      var reader = new FileReader();
      reader.onload = function() {
          var image = new Image();
          var fWidth = image.width;
          var fHeight = image.height;
          var width = image.width;
          var height = image.height;
  
          image.src = reader.result;
  
          image.onload = function() {
            if (image.width >= width && image.height >= height) {
              if (image.width >= (fWidth*2) || image.height >= (fHeight*2)) {
                fWidth = fWidth*2
                fHeight = fHeight*2
              }
              resolve({type: 'success', fileWidth: fWidth, fileHeight: fHeight})
            } else {
              resolve({type: 'error'})
            }
          };
  
      };
      reader.readAsDataURL(file);
    })
    }

    tmpFileValidate(selectedFile).then((res) => {
      if (res.type === 'error') {
        let message = "Upload an image with minimum size 600x215";
        props.setToast(true, { type: "end_error", message: message });
      } else {
        if (selectedFile) {
          handleImageForCompress(selectedFile, res.fileWidth, res.fileHeight)
            .then((compressedDataURL) => {
              handleChange(imgType, compressedDataURL);
            })
            .catch((error) => {
              console.error('Error compressing image:', error);
            });
        }
      }
    })
    
    if (imgType === 'image') {
      document.getElementById('file_upload').value = '';
      } else {
      document.getElementById('nav_file_upload').value = '';
    }
  };

  const removeImage =(imgType, e)=>{
    handleChange(imgType, '');
    if (imgType === 'image') {
      document.getElementById('file_upload').value = '';
    } else {
      document.getElementById('nav_file_upload').value = '';
    }
  }

  const triggerImage = (imgType, e) => {
    let preview;
    if (imgType === 'image') {
      preview = document.getElementById("image_preview");
      if(e.target.id !== "addImage" && e.target.id !== "file_upload" && !preview.contains(e.target)){
        document.getElementById('addImage').click();
      }
    } else {
      preview = document.getElementById("nav_image_preview");
      if(e.target.id !== "addNavImage" && e.target.id !== "nav_file_upload" && !preview.contains(e.target)){
        document.getElementById('addNavImage').click();
      }
    }
  };

  const submitPartnerData = async() => {
    const fObj = {...formData};
    setIsSubmit(true)
    let flag = true;

    if (fObj.stripe_product_id.value == null || fObj.stripe_product_id.value.trim() == "") {
      fObj.stripe_product_id = {value: fObj.stripe_product_id.value, error: 'Stripe Product ID is required.'}
      flag = false;
    }
    
    if (partnerUser === undefined){
      if (fObj.email.value == null || fObj.email.value.trim() == "") {
        fObj.email ={value: fObj.email.value, error: 'Email is required.'};
        flag = false;
      } else {
        const re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

        if (!fObj.email.value.toLowerCase().match(re)) {
          fObj.email = {value: fObj.email.value, error: 'Please enter a valid email address.'};
          flag = false;
        }
        fObj.email.value = fObj.email.value.trim();
      }
    }

    /* if (partnerUser.id) {
      if (fObj.brand_domain.value !== null || fObj.brand_domain.value.trim() !== "") {

      }
    } */

    if (flag) {
      let dataKeys = Object.keys(fObj)
      let newData = {}
      for (let i = 0; i < dataKeys.length; i++) {
        const keyName = dataKeys[i];
        if (keyName === 'brand_data'){
          if(typeof formData['brand_data'].value == "string"){
           newData[keyName] = JSON.parse((formData['brand_data'].value))
          }else{
            newData[keyName] = JSON.parse(JSON.stringify(formData['brand_data'].value))
          }
        } else if (keyName === 'other') {
          newData[keyName] = JSON.parse(JSON.stringify(formData['other'].value))
        } else {
          newData[keyName] = formData[keyName].value
        }
      }

      if (partnerUser){
        props.updatePartner(partnerUser.id, newData, (resp) => {
          setIsSubmit(false)
          if (resp.type == 'success') {
            props.setToast(true, { type:"success", message:"Partner has been saved successfully." });
            handleClose(true)
          } else {
            props.setToast(true, { type:"error", message: resp.message });
          }
        })
      } else {
        props.createNewPartner(newData, (resp) => {
          setIsSubmit(false)
          if (resp.type == 'success') {
            props.setToast(true, { type:"success", message:"Partner has been saved successfully." });
            handleClose(true)
          } else {
            props.setToast(true, { type:"error", message: resp.message });
          }
        })
      }
    } else {
      setIsSubmit(false)
      setformData(fObj)
    }
  }
  return (
    <Modal open={open} aria-labelledby="New Partner" className='modals'>
      <Box sx={style} className='modal-card textareArea'>
        <Typography variant="body2" sx={{fontFamily: "Roboto, sans-serif", fontWeight: "600", fontSize: "1.875rem", mb: 0}} align="center">{formTitle}</Typography>
        
        <Box display='flex' justifyContent="center" sx={{mt:"15px", height: '500px', overflow: 'auto'}}>
          <Grid container justifyContent="center">
            <Grid item lg={10}>
              <Box mb={1.5}>
                <Box mt={1}>
                  <Box component="strong">Contract Address:</Box>&nbsp;<Box component="span">{user.data.membership_nft_details?.contract_address}</Box>
                </Box>
                <Box mt={1}>
                  <Box component="strong">Chain ID:</Box>&nbsp;<Box component="span">{user.data.membership_nft_details?.chain_id}</Box>
                </Box>
              </Box>
              <TextField fullWidth margin="normal" name="fname" label={<span>First Name</span>} variant="outlined" InputLabelProps={{shrink: true}} placeholder='Enter first name' defaultValue={formData.fname.value} inputProps={{ maxLength: 255 }} onChange={(e)=>handleChange("fname", e.target.value)}  />
              
              <TextField fullWidth margin="normal" name="lname" label={<span>Last Name</span>} variant="outlined" InputLabelProps={{shrink: true}} placeholder='Enter last name' defaultValue={formData.lname.value} inputProps={{ maxLength: 255 }} onChange={(e)=>handleChange("lname", e.target.value)}  />
              
              <TextField fullWidth margin="normal" name="email" label={<span>Email Address</span>} sx={{"& .Mui-disabled": {"-webkit-text-fill-color": "initial !important"}}} disabled={partnerUser!==undefined} required variant="outlined" InputLabelProps={{shrink: true}} placeholder='Enter email address' defaultValue={formData.email.value} inputProps={{ maxLength: 255 }} onChange={(e)=>handleChange("email", e.target.value)} error={formData.email.error != ""} helperText={formData.email.error} />
              
              <Typography>Main Logo</Typography>
              <TextField margin="normal" sx={{width: '100%', "& .Mui-disabled": {"-webkit-text-fill-color": "initial !important"}}} className='Add-image' variant="outlined" disabled placeholder={'Add an Image'} onClick={(e) => {triggerImage('image', e)}} InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button variant="outlined" size="small" component="label" id="addImage" className="font-weight-600">
                      Browse... 
                      <input type="file" id="file_upload" name="banner" accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml" onChange={(e) => { 
                        if (checkImageFileExt(e)){
                          onSelectFile('image', e)
                        } else {
                          props.setToast(true,{type:"end_error", message: 'Please choose a correct file type'});
                        }
                        
                      }} hidden  />
                    </Button>
                  </InputAdornment>
                ),
                startAdornment:(
                  <InputAdornment position="start">
                    <Box sx={{display:"flex"}}>
                      <Box sx={{position: 'relative'}} id="image_preview">
                        {formData.image.value !== "" && formData.image.value !== undefined ?
                          <>
                          <img src={formData.image.value} height="20" alt="" />
                          {
                            formData.image !== "" &&
                            <Button id="removeImage" sx={{position: 'absolute', top: '-5px',right:'-5px', minWidth:'0',display:'flex',justifyContent:'center',alignItems:'center', backgroundColor:'rgba(0,0,0)',padding:0,color: '#fff',':hover': {backgroundColor: '#000'}}} onClick={(e) => {removeImage('image', e)}}><CloseIcon sx={{fontSize: '1rem !important'}}/></Button>
                          }
                          </>
                          :
                          <>
                          <img src="/assets/images/empty-img.svg" height="20" alt=""/>
                          </>
                        }
                      </Box>
                      <Typography variant="body1" ml={1}></Typography>
                    </Box>
                  </InputAdornment>
                )
              }} />

              <Typography>Nav Logo</Typography>
              <TextField margin="normal" sx={{width: '100%', "& .Mui-disabled": {"-webkit-text-fill-color": "initial !important"}}} className='Add-image' variant="outlined" disabled placeholder={'Add Nav Logo'} onClick={(e) => {triggerImage('nav_image', e)}} InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Button variant="outlined" size="small" component="label" id="addNavImage" className="font-weight-600">
                      Browse... 
                      <input type="file" id="nav_file_upload" name="nav_logo" accept="image/png, image/jpeg, image/jpg, image/gif, image/svg+xml" onChange={(e) => { 
                        if (checkImageFileExt(e)){
                          onSelectFile('nav_image', e)
                        } else {
                          props.setToast(true,{type:"end_error", message: 'Please choose a correct file type'});
                        }
                        
                      }} hidden  />
                    </Button>
                  </InputAdornment>
                ),
                startAdornment:(
                  <InputAdornment position="start">
                    <Box sx={{display:"flex"}}>
                      <Box sx={{position: 'relative'}} id="nav_image_preview">
                        {formData.nav_image.value !== "" && formData.nav_image.value !== undefined ?
                          <>
                          <img src={formData.nav_image.value} height="20" alt="" />
                          {
                            formData.nav_image !== "" &&
                            <Button id="removeImage" sx={{position: 'absolute', top: '-5px',right:'-5px', minWidth:'0',display:'flex',justifyContent:'center',alignItems:'center', backgroundColor:'rgba(0,0,0)',padding:0,color: '#fff',':hover': {backgroundColor: '#000'}}} onClick={(e) => {removeImage('nav_image', e)}}><CloseIcon sx={{fontSize: '1rem !important'}}/></Button>
                          }
                          </>
                          :
                          <>
                          <img src="/assets/images/empty-img.svg" height="20" alt=""/>
                          </>
                        }
                      </Box>
                      <Typography variant="body1" ml={1}></Typography>
                    </Box>
                  </InputAdornment>
                )
              }} />

              <TextField fullWidth margin="normal" name="contract_name" label={<span>Contract Name</span>} required variant="outlined" InputLabelProps={{shrink: true}} placeholder='Enter Contract Name' defaultValue={formData.contract_name.value}  onChange={(e)=>handleChange("contract_name", e.target.value)} error={formData.contract_name.error !== ""} helperText={formData.contract_name.error} />

              <TextField fullWidth margin="normal" name="stripe_product_id" label={<span>Stripe Product ID</span>} required variant="outlined" InputLabelProps={{shrink: true}} placeholder='Enter Stripe Product ID' defaultValue={formData.stripe_product_id.value}  onChange={(e)=>handleChange("stripe_product_id", e.target.value)} error={formData.stripe_product_id.error !== ""} helperText={formData.stripe_product_id.error} />

              <TextField fullWidth multiline rows={3} margin="normal" name="brand_settings" label={<span>Brand Data</span>} required variant="outlined" InputLabelProps={{shrink: true}} placeholder='Enter Brand Settings' defaultValue={JSON.stringify(formData.brand_data.value)}  onChange={(e)=>handleChange("brand_data", e.target.value)}  />

              <TextField fullWidth multiline rows={5} margin="normal" name="advanced_settings" label={<span>Advanced Settings</span>} required variant="outlined" InputLabelProps={{shrink: true}} defaultValue={JSON.stringify(formData.other.value)} FormHelperTextProps={{ style: { wordWrap:'break-word' } }} helperText={`{"layout":"gp","deem_login":true,"logout_url":"https://www.step3.io/","is_home_page":false,"stripe_enable":true,"stripe_product":{"name":"Testing Product","price":"","recurringType":"year","application_fee_percent":0},"allow_static_data":false,"login_page_reward_id":3,"webflow_url":"","shop_menu":{"name":"","link":""},"nft_settings":{"arts":{"default":"","xxx":"","yyy":""}}}`} onChange={(e)=>handleChange("other", e.target.value)}  />

              <TextField fullWidth multiline rows={5} margin="normal" name="nft_metadata" label={<span>NFT Metadata</span>} required variant="outlined" InputLabelProps={{shrink: true}} defaultValue={JSON.stringify(formData.nft_metadata.value)} FormHelperTextProps={{ style: { wordWrap:'break-word' } }}  onChange={(e)=>handleChange("nft_metadata", e.target.value)}  />

              {
                partnerUser?.id &&
                <TextField fullWidth margin="normal" name="brand_domain" label={<span>Brand Domain</span>} variant="outlined" InputLabelProps={{shrink: true}} placeholder='Enter Brand Domain' defaultValue={formData.brand_domain.value}  onChange={(e)=>handleChange("brand_domain", e.target.value)}  />
              }
            </Grid>
          </Grid>
        </Box>
        <Box display="flex" justifyContent="center">
          
        </Box>
        <Box sx={{mt:4,py:3,px:5,background:"rgba(34, 51, 84,0.02)"}}>
          <Box display="flex" justifyContent="space-between">
            <Button variant="outlined" color="primary" sx={{fontFamily: "Roboto, sans-serif", fontWeight: "700" ,fontSize: "15px"}} onClick={handleClose}>Cancel</Button>
            <Button variant="contained" color="primary" sx={{fontFamily: "Roboto, sans-serif", fontWeight: "700" ,fontSize: "15px"}} onClick={submitPartnerData} disabled={isSubmit}>{isSubmit ? 'Working..' : `Submit`}</Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
}


const mapStateToProps = (state) => ({
  page: state.page,
  user: state.user,
})

const mapDispatchToProps = {
  setToast,
  createNewPartner,
  updatePartner
}

export default connect(mapStateToProps, mapDispatchToProps)(PartnerForm)