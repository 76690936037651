import React, { useEffect, useState } from "react";
import { Typography, Button, Box, Accordion, AccordionSummary, AccordionDetails, TextField, InputAdornment, FormControl, FormControlLabel, Radio, styled, OutlinedInput, InputLabel, FormHelperText, Checkbox, Grid, Chip } from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/FacebookOutlined";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { connect } from "react-redux";
import { twitterUsernameCheck, socialValidationCheck } from "src/services/actions";
import { isValidHttpUrl, socialActivities, socialActivitiesObjs } from "src/utils";
import { EmailOutlined } from "@mui/icons-material";

const TikTokIcon = ({ color = "#000000" }) => {
  return (
    <svg fill={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="100%" height="100%">
      <path d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z" />
    </svg>
  );
};

const SocialAccordion = styled((props) => <Accordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  backgroundColor: "transparent",
  border: `0px solid #000`,
  boxShadow: "0px 0px",
  "&:first-child": {
    borderRadius: "0 0 0 0",
  },
  "&:last-child": {
    borderRadius: "0 0 0 0",
    borderTop: "0px solid",
  },
  "&:not(:last-child)": {
    borderBottom: "0",
  },
  "&:before": {
    display: "none",
  },
}));

const SocialAccordionSummary = styled((props) => <AccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />)(({ theme }) => ({
  backgroundColor: "none",
  borderBottom: "0px solid rgba(0, 0, 0, 0.1)",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
}));

const SocialAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: theme.spacing(0),
  paddingBottom: 0,
  borderTop: "0px solid rgba(0, 0, 0, .125)",
}));

const MAccordion = styled((props) => <Accordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
  border: `1px solid #000`,
  boxShadow: "0px 9px 16px rgba(159, 162, 191, 0.18), 0px 2px 2px rgba(159, 162, 191, 0.32)",
  "&:first-child": {
    borderRadius: "6px 6px 0 0 !important",
    borderTop: "1px solid !important",
    borderBottom: "0px",
  },
  "&:last-child": {
    borderTop: "0px solid",
    borderRadius: "0 0 6px 6px",
  },
  "&:not(:last-child)": {
    borderTop: "0px solid",
    borderRadius: "0 0 6px 6px",
  },
  "&:before": {
    display: "none",
  },
}));

const MAccordionSummary = styled((props) => <AccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />)(({ theme }) => ({
  backgroundColor: "rgba(243, 243, 243, 0.6)",
  borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
}));

const MAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  paddingBottom: theme.spacing(2),
  paddingTop: 0,
  paddingRight: 0,
  paddingLeft: 0,
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function ClaimCriteria(props) {
  const { handleChildData, dropData, nextTabErrors, setNextTabErrors } = props;
  const [claimCriterias, setClaimCriterias] = useState(dropData?.claim_condition);
  const [socialExpanded, setSocialExpanded] = useState("social_panel0");
  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleSocialChange = (panel) => (event, newExpanded) => {
    setSocialExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
    let claimCriteriasData = [...claimCriterias];
    /* let cc_msg = {...dropData?.claim_condition_message}
    if (claimCriteriasData.includes('facebook_follow')) {
      delete cc_msg['facebook_follow_page_id']
      delete cc_msg['facebook_follow_page_url']
    } 
    handleChildData('claim_condition_message', cc_msg) */
    handleChildData("claim_condition", claimCriteriasData);
  }, [claimCriterias]);
  const handleClaimCriteria = (item, checked) => {
    const cc = [...claimCriterias];
    if (checked) {
      setClaimCriterias((prev) => [...prev, item]);
    } else {
      if (item === "discord_join_server") {
        setNextTabErrors({ ...nextTabErrors, ["discord_server_url"]: { val: "", error: "" }, ["discord_server_id"]: { val: "", error: "" } });
      } else {
        setNextTabErrors({ ...nextTabErrors, [item]: { value: "", error: "" } });
      }
      let filterdCC = cc.filter((a) => a !== item);
      let cc_msg = { ...dropData?.claim_condition_message };
      if (item === "facebook_follow") {
        delete cc_msg["facebook_follow"];
      }
      handleChildData("claim_condition_message", cc_msg);
      handleChildData("claim_condition", filterdCC);
      setClaimCriterias(filterdCC);
    }
  };

  const check_for_twitter_username = (inp, val) => {
    setNextTabErrors({ ...nextTabErrors, [inp]: { value: typeof val == "string" ? String(val).trim() : val, error: "" } });
    if (val.trim() != "") {
      if (val.trim().startsWith("@")) {
        val = val.slice("@".length);
      }
      setNextTabErrors({ ...nextTabErrors, [inp]: { value: typeof val == "string" ? String(val).trim() : val, error: "Wait..." } });
      props.twitterUsernameCheck(val, (resp) => {
        if (resp.type === "error") {
          setNextTabErrors({ ...nextTabErrors, [inp]: { value: typeof val == "string" ? String(val).trim() : val, error: "Not a valid account" } });
        } else {
          setNextTabErrors({ ...nextTabErrors, [inp]: { value: typeof val == "string" ? String(val).trim() : val, error: "" } });
        }
      });
    } else {
      // setNextTabErrors({...nextTabErrors,['twitter_username']:{value: typeof val=="string" ?  String(val).trim() : val,error:""}});
    }
  };

  const check_for_twitter_tweets = (inp, val) => {
    setNextTabErrors({ ...nextTabErrors, [inp]: { value: typeof val == "string" ? String(val).trim() : val, error: "" } });
    if (val.trim() != "") {
      if (!isValidHttpUrl(val)) {
        setNextTabErrors({ ...nextTabErrors, [inp]: { value: typeof val == "string" ? String(val).trim() : val, error: "Not a valid URL" } });
      } else {
        setNextTabErrors({ ...nextTabErrors, [inp]: { value: typeof val == "string" ? String(val).trim() : val, error: "Wait..." } });
        props.socialValidationCheck("twitter", inp, val, (resp) => {
          if (resp.type === "error") {
            setNextTabErrors({ ...nextTabErrors, [inp]: { value: typeof val == "string" ? String(val).trim() : val, error: "Not a valid post" } });
          } else {
            setNextTabErrors({ ...nextTabErrors, [inp]: { value: typeof val == "string" ? String(val).trim() : val, error: "" } });
          }
        });
      }
    } else {
      // setNextTabErrors({...nextTabErrors,[inp]:{value: typeof val=="string" ?  String(val).trim() : val,error:""}});
    }
  };

  const check_valid_social_urls = (inp, val) => {
    if (inp !== "discord_join_server") {
      setNextTabErrors({ ...nextTabErrors, [inp]: { value: typeof val == "string" ? String(val).trim() : val, error: "" } });
      if (val.trim() !== "") {
        if (!isValidHttpUrl(val)) {
          setNextTabErrors({ ...nextTabErrors, [inp]: { value: typeof val == "string" ? String(val).trim() : val, error: "Not a valid URL" } });
        }
      }
    }
  };

  const handleSocialInputs = (inputname, val) => {
    const cc = { ...dropData?.claim_condition_message };
    cc[inputname] = val;
    handleChildData("claim_condition_message", cc);
    // setNextTabErrors({...nextTabErrors,['twitter_username']:{value: typeof val=="string" ?  String(val).trim() : val,error:""}});
  };

  const handleSocialSubInputs = (social_type, inputname, val) => {
    const cc = { ...dropData?.claim_condition_message };
    const re = cc[social_type] !== "" && JSON.stringify(cc[social_type]) !== "{}" && cc[social_type] !== undefined ? cc[social_type] : {};
    // console.log("re =>",social_type, inputname, val,cc[social_type],re)
    re[inputname] = val;
    cc[social_type] = re;
    handleChildData("claim_condition_message", cc);
  };

  const getSocialIcon = (social_item, size = "medium") => {
    if (social_item === "Twitter") {
      return <TwitterIcon sx={{ color: "#000" }} />;
    } else if (social_item === "Instagram") {
      return <InstagramIcon sx={{ color: "#000" }} />;
    } else if (social_item === "YouTube") {
      return <YouTubeIcon sx={{ color: "#000" }} />;
    } else if (social_item === "Tiktok") {
      return <img src="/assets/img/tiktok.svg" width="20" alt="" />;
    } else if (social_item === "Discord") {
      return <img src="/assets/img/discord.svg" width="20" alt="" />;
    } else {
      return <FacebookIcon sx={{ color: "#000" }} />;
    }
  };
  const selectedSocialActivity = (activity_arr) => {
    // let fSocialAct = [...socialActivities];
    // if (claimCriterias.includes('email')) {
    //   fSocialAct.push()
    // }
    let a = Object.values(socialActivities).map((c, i) => c.map((s) => (activity_arr.includes(s.key_name) ? { type: Object.keys(socialActivities)[i], val: s.heading } : null)));

    // console.log("selected_socials =>",a.flat(1).filter((vals) => vals !== null))
    return a.flat(1).filter((vals) => vals !== null);
  };

  const validateSocialInput = (inputField, value) => {
    let name = inputField.split("_");
    let key_name = inputField;
    if (name[name.length - 1] > 0) {
      name.splice(name.length - 1, 1);
      key_name = name.join("_");
    }
    switch (key_name) {
      case "twitter_follow":
        check_for_twitter_username(inputField, value);
        break;

      default:
        break;
    }
  };
  const validateSocialInputBlur = (inputField, value) => {
    let name = inputField.split("_");
    let key_name = inputField;
    if (name[name.length - 1] > 0) {
      name.splice(name.length - 1, 1);
      key_name = name.join("_");
    }
    switch (key_name) {
      case "twitter_like":
        check_for_twitter_tweets(inputField, value);
        break;
      case "twitter_retweet":
        check_for_twitter_tweets(inputField, value);
        break;
      case "facebook_view":
        check_valid_social_urls(inputField, value);
        break;
      case "instagram_view":
        check_valid_social_urls(inputField, value);
        break;
      case "youtube_view":
        check_valid_social_urls(inputField, value);
        break;
      case "tiktok_view":
        check_valid_social_urls(inputField, value);
        break;

      default:
        break;
    }
  };

  const socialInputError = (inputField) => {
    let errF = nextTabErrors[inputField];
    if (inputField === "server_id") {
      errF = nextTabErrors["discord_server_id"];
    }

    if (inputField === "server_url") {
      errF = nextTabErrors["discord_server_url"];
    }
    if (errF) {
      return errF.error !== "";
    } else {
      return false;
    }
  };

  return (
    <>
      <Typography variant="body1" textAlign="center" className='subtitle' lineHeight="normal" mb="20px">CREATE REWARD</Typography>
      <Typography variant="h1" textAlign="center" sx={{ mb: 1.5 }} className="title">
        Add claim conditions (optional)
      </Typography>
      <Typography variant="body2" align="center" className="description" mt={0}>
        Add additional tasks required for the user to redeem this reward. 
      </Typography>
      <Box display="flex" justifyContent="center" mt="20px">
        <Box width="559px">
          <MAccordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
            <MAccordionSummary aria-controls="panel1d-content" id="panel1d-header">
              <Typography display="flex" alignItems="center">
                <img src="/assets/img/claim-illustrations-1.png" alt="" width={48} />
                <Typography variant="body1" className="accordian_title" ml="5px">
                  User Details
                </Typography>
              </Typography>
            </MAccordionSummary>
            <MAccordionDetails sx={{paddingTop: '18px !important'}}>
              <Box px={3}>
                <FormControlLabel
                  control={
                    <Checkbox
                      icon={<img src="/assets/img/Uncheck.svg" width="100%" alt="Uncheck" />}
                      checkedIcon={<img src="/assets/img/Checkbox.svg" width="100%" alt="Checked" />}
                      checked={claimCriterias.includes("email")}
                      onClick={(e) => {
                        handleClaimCriteria("email", e.target.checked);
                      }}
                      disableRipple
                    />
                  }
                  label={
                    <Box>
                      <Typography variant="h4" sx={{ fontSize: "0.875rem", color: "#223354", fontFamily: "Roboto, sans-serif", fontWeight: "700" }}>
                        Email
                      </Typography>
                    </Box>
                  }
                />
                {claimCriterias.includes("email") && (
                  <Box display="flex" alignItems="start">
                    <img src="/assets/img/info-blue.svg" width={20} alt="" style={{ marginLeft: "-2px", marginTop: "3px" }} />
                    <Typography ml={1} className="claim_email_info">
                      The user will be required to verify their email address and opt-in to future emails before they can claim the reward.
                    </Typography>
                  </Box>
                )}
              </Box>
            </MAccordionDetails>
          </MAccordion>
          <MAccordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
            <MAccordionSummary aria-controls="panel2d-content" id="panel2d-header">
              <Typography display="flex" alignItems="center">
                <img src="/assets/img/claim-illustrations-2.png" alt="" width={48} />
                <Typography variant="body1" className="accordian_title" ml="5px">
                  Social Activities
                </Typography>
              </Typography>
            </MAccordionSummary>
            <MAccordionDetails>
              {Object.keys(socialActivities).map((social_item, index) => {
                return (
                  <SocialAccordion expanded={socialExpanded === `social_panel${index}`} onChange={handleSocialChange(`social_panel${index}`)}>
                    <SocialAccordionSummary aria-controls={`social_panel${index}d-content`} id={`social_panel${index}d-header`}>
                      <Typography display="flex" alignItems="center" justifyContent="center">
                        <Box display="flex" alignItems="center" width="30px">
                          {getSocialIcon(social_item)}
                        </Box>
                        <Typography variant="body1" className="accordian_title">
                          {social_item}
                        </Typography>
                      </Typography>
                    </SocialAccordionSummary>
                    <SocialAccordionDetails>
                      {socialActivities[social_item].map((item) => {
                        return (
                          <Box px={3}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  icon={<img src="/assets/img/Uncheck.svg" width="100%" alt="Uncheck" />}
                                  checkedIcon={<img src="/assets/img/Checkbox.svg" width="100%" alt="Checked" />}
                                  checked={claimCriterias.includes(item.key_name)}
                                  onChange={(e) => {
                                    handleClaimCriteria(item.key_name, e.target.checked);
                                  }}
                                  disableRipple
                                />
                              }
                              label={
                                <Box>
                                  <Typography variant="h4" sx={{ fontSize: "0.875rem", color: "#223354", fontFamily: "Roboto, sans-serif", fontWeight: "700" }}>
                                    {item.heading}
                                  </Typography>
                                </Box>
                              }
                            />
                            {claimCriterias.includes(item.key_name) && (
                              <>
                                {item.inputs !== undefined ? (
                                  <Box pl={2}>
                                    {item.inputs.map((cInput) => {
                                      return (
                                        <Box>
                                          <TextField
                                            fullWidth
                                            margin="normal"
                                            label={
                                              <span>
                                                {cInput.label}&nbsp;<span style={{ color: "red" }}>*</span>
                                              </span>
                                            }
                                            placeholder={cInput.placeholder}
                                            name={cInput.input_name}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true, style: {} }}
                                            onKeyUp={(e) => {
                                              validateSocialInput(cInput.input_name, e.target.value);
                                            }}
                                            onBlur={(e) => {
                                              validateSocialInputBlur(cInput.input_name, e.target.value);
                                            }}
                                            onChange={(e) => handleSocialSubInputs(item.input_name, cInput.input_name, e.target.value)}
                                            error={socialInputError(cInput.input_name)}
                                            helperText={nextTabErrors[`discord_${cInput.input_name}`] && nextTabErrors[`discord_${cInput.input_name}`]?.error === "" ? "" : nextTabErrors[`discord_${cInput.input_name}`]?.error}
                                            inputProps={{ maxLength: 10000 }}
                                            value={dropData?.claim_condition_message && dropData?.claim_condition_message[item.input_name] ? dropData?.claim_condition_message[item.input_name][cInput.input_name] : ""}
                                          />
                                        </Box>
                                      );
                                    })}
                                  </Box>
                                ) : (
                                  <>
                                    {[...Array(item.input_count)].map((e, i) => {
                                      let input_name = item.input_name + (i > 0 ? "_" + i : "");
                                      return (
                                        <Box display="flex" alignItems="start" pl={2}>
                                          <TextField
                                            fullWidth
                                            margin="normal"
                                            label={
                                              <span>
                                                {item.label}
                                                {item.input_count > 1 ? " " + (i + 1) : ""}&nbsp;{i === 0 ? <span style={{ color: "red" }}>*</span> : ""}
                                              </span>
                                            }
                                            placeholder={item.placeholder}
                                            name={input_name}
                                            variant="outlined"
                                            InputLabelProps={{ shrink: true, style: {} }}
                                            onKeyUp={(e) => {}}
                                            onBlur={(e) => {
                                              validateSocialInputBlur(input_name, e.target.value);
                                            }}
                                            onChange={(e) => {
                                              validateSocialInput(input_name, e.target.value);
                                              handleSocialInputs(input_name, e.target.value);
                                            }}
                                            error={socialInputError(input_name)}
                                            helperText={nextTabErrors[input_name] && nextTabErrors[input_name]?.error === "" ? "" : nextTabErrors[input_name]?.error}
                                            inputProps={{ maxLength: 10000 }}
                                            value={dropData?.claim_condition_message ? dropData?.claim_condition_message[input_name] : ""}
                                          />
                                        </Box>
                                      );
                                    })}
                                  </>
                                )}

                                {item.info && (
                                  <Box display="flex" alignItems="start">
                                    <img src="/assets/img/info-blue.svg" width={20} alt="" style={{ marginLeft: "-2px", marginTop: "3px" }} />
                                    <Typography ml={1} className="claim_email_info">
                                      <div dangerouslySetInnerHTML={{ __html: item.info }}></div>
                                    </Typography>
                                  </Box>
                                )}
                              </>
                            )}
                          </Box>
                        );
                      })}
                    </SocialAccordionDetails>
                  </SocialAccordion>
                );
              })}
            </MAccordionDetails>
          </MAccordion>
          {(selectedSocialActivity(dropData?.claim_condition).length > 0 || claimCriterias.includes("email")) && (
            <Box mt={2} className="selectedSocialActivity">
              <Typography textAlign="center" variant="h2">
                Selected
              </Typography>
              <Box display="flex" justifyContent="center" flexWrap="wrap" mt={1}>
                {dropData?.claim_condition.includes("email") && (
                  <Box className="activity-chip-box">
                    <Chip
                      label={
                        <Box display="flex" alignItems="center">
                          <Box display="flex" alignItems="center" justifyContent="center">
                            <Box mr={0.5} display="flex" alignItems="center" justifyContent="center" sx={{color: '#1A1616'}}>
                              <EmailOutlined color="#1A1616" />
                            </Box>
                            <Typography variant="body1">Email</Typography>
                          </Box>
                        </Box>
                      }
                      variant="outlined"
                    />
                  </Box>
                )}
                {selectedSocialActivity(dropData?.claim_condition).map((selected_socials) => {
                  return (
                    <Box className="activity-chip-box">
                      <Chip
                        label={
                          <Box display="flex" alignItems="center">
                            <Box display="flex" alignItems="center" justifyContent="center">
                              <Box mr={0.5} display="flex" alignItems="center" justifyContent="center" sx={{color: '#1A1616'}}>
                                {getSocialIcon(selected_socials.type, "small")}
                              </Box>
                              <Typography variant="body1">{selected_socials.type}:</Typography>
                            </Box>
                            <Typography variant="body2" ml={0.5}>
                              {selected_socials.val}
                            </Typography>
                          </Box>
                        }
                        variant="outlined"
                      />
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
}

const mapStateToProps = (state) => ({
  rewardCRD: state.rewardCRD,
  page: state.page,
});

const mapDispatchToProps = {
  twitterUsernameCheck,
  socialValidationCheck,
};

export default connect(mapStateToProps, mapDispatchToProps)(ClaimCriteria);
