import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Routes, Route, BrowserRouter, Navigate, useLocation } from "react-router-dom";

import { AccentHeaderLayout, MainAppLayout, PreLoginLayout } from "../layouts/AccentHeaderLayout";
import CountryNotAllowed from "./../pages/countryNotAllowed";
import Home from "./../pages/home";
import ConfirmEmail from "./../pages/home/confirmEmail";
import ConfirmNewEmail from "./../pages/home/ConfirmNewEmail";
import Registration from "./../pages/registration";
import DropDetail from "./../pages/drops/show";
import NewDrop from "./../pages/drops/new";
import Collections from "./../pages/collections";
import ProfileSettings from "src/pages/settings/profile";
import UserSettings from "src/pages/settings";
import MemberPartners from "src/pages/partners";
import AdminCollections from "src/pages/adminCollections";
import AdminUsers from "src/pages/adminUsers";
import OnBoarding from "src/pages/onBoarding";
import { checkCountryAllowed, setPageArea } from "../services/actions/api";
import { gtag } from "../utils";

import { userChkLogin } from "../services/actions/ethereum";
import MembershipRewards from "src/pages/rewards/membership";
import CommunityRewards from "src/pages/rewards/community";

function _ScrollToTop(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return props.children;
}
const ScrollToTop = _ScrollToTop;

function RouteWrapper({ component: Component, layout: Layout, ...rest }) {
  return (
    <Layout {...rest}>
      <Component {...rest} />
    </Layout>
  );
}

export const RoutesObj = (props) => {
  const { allowedCountry, user } = props;

  useEffect(() => {
    props.checkCountryAllowed();
    let jwt_token = window.localStorage.getItem("jwt_token");
    if (!user.login && jwt_token !== "" && jwt_token !== "undefined") {
      props.userChkLogin();
    }
  }, []);

  useEffect(() => {
    props.setPageArea();
    window.onresize = props.setPageArea;
    return () => {
      window.onresize = null;
    };
  }, [window.innerWidth]);

  useEffect(() => {
    if (user.type == "success" && user.data.email_verified == true) {
      gtag("account_logged_in", {
        eth_address: ` ${user.data.eth_address} `,
        wallet_type: user.data.wallet_type,
      });
    }
  }, [user.login]);
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Routes>
          {allowedCountry.data.type == "error" ? (
            <>
              <Route exact path="/not-allowed" element={<RouteWrapper exact component={CountryNotAllowed} layout={AccentHeaderLayout} {...props} />} />
              <Route path="*" element={<Navigate to="/not-allowed" replace />} />
            </>
          ) : user.login ? (
            <>
              <Route exact path="/collections" element={<RouteWrapper exact component={Collections} layout={MainAppLayout} {...props} />} />
              <Route exact path="/admin/collections" element={<RouteWrapper exact component={AdminCollections} layout={MainAppLayout} {...props} />} />
              <Route exact path="/admin/users" element={<RouteWrapper exact component={AdminUsers} layout={MainAppLayout} {...props} />} />
              <Route exact path="/profile" element={<RouteWrapper exact component={ProfileSettings} layout={MainAppLayout} {...props} />} />
              <Route exact path="/settings" element={<RouteWrapper exact component={UserSettings} layout={MainAppLayout} {...props} />} />
              <Route exact path="/partners" element={<RouteWrapper exact component={MemberPartners} layout={MainAppLayout} {...props} />} />
              <Route exact path="/rewards/:reward_type/:type" element={<RouteWrapper exact component={NewDrop} layout={MainAppLayout} {...props} />} />
              <Route exact path="/rewards/membership" element={<RouteWrapper exact component={MembershipRewards} layout={MainAppLayout} {...props} />} />
              <Route exact path="/rewards/community" element={<RouteWrapper exact component={CommunityRewards} layout={MainAppLayout} {...props} />} />
              <Route exact path="/rewards/:reward_type/detail/:id" element={<RouteWrapper exact component={DropDetail} layout={MainAppLayout} {...props} />} />
              <Route exact path="/onboarding" element={<RouteWrapper exact component={OnBoarding} layout={MainAppLayout} {...props} />} />
              <Route path="*" element={<Navigate to={user.data.completed_onboarding && user.data.terms ?  "/rewards/membership" : "/onboarding"} state={{ msg: user.account_type }} replace />} />
            </>
          ) : (
            <>
              <Route exact path="/register" element={<RouteWrapper exact component={Registration} layout={PreLoginLayout} {...props} />} />
              <Route exact path="/confirm_email/:token" element={<RouteWrapper exact component={ConfirmEmail} layout={PreLoginLayout} {...props} />} />
              <Route exact path="/confirm_new_email/:token" element={<RouteWrapper exact component={ConfirmNewEmail} layout={AccentHeaderLayout} {...props} />} />
              <Route exact path="/" element={<RouteWrapper exact component={Home} layout={PreLoginLayout} {...props} />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </>
          )}
        </Routes>
      </ScrollToTop>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  pageThoughts: state.pageThoughts,
  allowedCountry: state.allowedCountry,
});

const mapDispatchToProps = {
  checkCountryAllowed,
  setPageArea,
  userChkLogin,
};

export default connect(mapStateToProps, mapDispatchToProps)(RoutesObj);
