import React,{useEffect, useState} from 'react';
import { Box, Card, styled, CardContent, TextField, Grid, Button, Typography, InputAdornment, Snackbar, Alert, Link, Tabs, Tab } from '@mui/material';
import {connect} from 'react-redux';

import {gaEvent} from "../../utils";
import DomainSettings from './advandced_settings/domain';
import AnalyticsSettings from './advandced_settings/analytics';


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, px:1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const CommunitySettings = (props) => {
  
  const [tabValue, setTabValue] = useState(0)
  
  const {user} = props


  useEffect(() => {
    gaEvent({
      category: "Community Page Settings",
      label:`-${user.data.eth_address}-`,
      action: `community_settings`
    });
  }, []);
  

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `advanced-tab-${index}`,
      'aria-controls': `advanced-tabpanel-${index}`,
    };
  }

  return (
    <Box sx={{p:0}}>
      <Box sx={{ px: "0", pb: "15px", borderBottom: "1px solid rgba(0, 0, 0, 0.2)"}}>
        <Tabs value={tabValue} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
          <Tab disableRipple label={`Domain`} {...a11yProps(0)} />
          <Tab disableRipple label={`Google Analytics`} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <TabPanel key="domain-settings" value={tabValue} index={0}>
        <Box px="40px">
          <DomainSettings />
        </Box>
      </TabPanel>
      <TabPanel key="analytics-settings" value={tabValue} index={1}>
        <Box px="40px">
          <AnalyticsSettings />
        </Box>
      </TabPanel>
    </Box>
  )
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunitySettings)