import { combineReducers } from 'redux';

import {pageAreaReducer, rewardDropReducer,rewardListReducer,rewardCRDReducer, pageThoughtsReducer, countryAllowedReducer, toastReducer, raffleWinnersReducer, userUpdateReducer, adminUsersDataReducer, partnersDataReducer, partnerFormReducer} from './list';
import {userReducer} from './user';
import {collectionReducer, collectionTraitsReducer} from './collections';
import {adminCollectionReducer} from './adminCollections';

const rootReducer = combineReducers({
    page: pageAreaReducer,
    user: userReducer,
    collections : collectionReducer,
    rewardDrop: rewardDropReducer,
    rewardList: rewardListReducer,
    rewardCRD: rewardCRDReducer,
    pageThoughts: pageThoughtsReducer,
    showToast: toastReducer,
    adminCollections: adminCollectionReducer,
    allowedCountry: countryAllowedReducer,
    raffleWinners: raffleWinnersReducer,
    userUpdate: userUpdateReducer,
    adminUsers: adminUsersDataReducer,
    collectionTraits: collectionTraitsReducer,
    partners: partnersDataReducer,
    partnerForm: partnerFormReducer
});

export default rootReducer;