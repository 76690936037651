import { useRef, useState } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { Avatar, Box, Button, List, ListItem, ListItemText, Popover, Typography, styled, Link } from "@mui/material";
import ExpandMoreTwoToneIcon from "@mui/icons-material/ExpandMoreTwoTone";

import { shortEthAddress, shortEmailAddress, shortenPhoneNumber } from "../../../../utils/ethereum";
import { logoutUser } from "../../../../services/actions/api";
import { allowedCustomWallet } from "src/utils";

const UserBoxButton = styled(Button)(
  ({ theme }) => `
    padding-left: ${theme.spacing(1)};
    padding-right: ${theme.spacing(0)};
    color: ${theme.colors.alpha.trueWhite[70]};

    &:hover {
      color: ${theme.colors.alpha.trueWhite[100]};
      background: none;
    }
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: #F6F8FB;
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: #9EA4C1
`
);

function HeaderUserbox(props) {
  const { user } = props;
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    props.logoutUser();
  };
  const CustomPopover = styled(Popover)({
    "& .MuiBox-root.css-7ilvzl": {
      padding: "0%",
    },
    "& .MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper": {
      borderRadius: "0%",
      top: (user.data.is_paid || !user.login) ? "80px !important" : "144.2px !important",
      left: "unset !important",
      right: "1px !important",
    },
    "& .MuiList-root.MuiList-padding": {
      width: "100%",
    },
    "& .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters:hover": {
      background: "none",
    },
  });
  return (
    <Box className="headerBox" component="span" sx={{ display: { xs: "none", sm: "inline-block" } }}>
      <UserBoxButton color="secondary" ref={ref}>
        {/* {user.login && user.data.completed_onboarding && user.data.membership_page_info && user.data?.other_data?.allow_membership_settings && (
          <Link px={3} py={1} mr={2} className="btn-outlined-secondary" href={user.data.membership_page_info?.other ? user.data.membership_page_info?.other?.is_home_page !== true ? `${user.data.full_brand_domain}/member` : user.data.full_brand_domain : `${user.data.full_brand_domain}/member`} target="_blank">
            Membership Page
          </Link>
        )} */}
        {/* {user.login && user.data.completed_onboarding && (user.data?.other_data?.allow_membership_settings === false || user.data.membership_page_info == null || user.data.membership_page_info?.other == null || (user.data.membership_page_info?.other && user.data.membership_page_info?.other?.is_home_page !== true)) && (
          <Link px={3} py={1} mr={2} className="btn-outlined-secondary" href={`${user.data.full_brand_domain}`} target="_blank">
            Community Page
          </Link>
        )} */}
        <Avatar alt="John" src={user.data.image ? user.data.image : user.data.wallet_type === 'mobile' ? "/assets/images/phone-number-avatar.svg" : "/assets/images/profile_img.png"} variant="square" onClick={handleOpen} />
        <Box component="span" sx={{ display: { xs: "none", md: "inline-block" } }} onClick={handleOpen}>
          <UserBoxText>
            {user.login && (
              <UserBoxLabel variant="body1" className="page-text">
                {user.data.fname}
              </UserBoxLabel>
            )}
            <UserBoxDescription variant="body2" className="page-text">
              {allowedCustomWallet(user.data?.wallet_type) ? shortEmailAddress(user.data.email) : user.data?.wallet_type === 'mobile' ? shortenPhoneNumber(user.data.phone) : shortEthAddress(user.data.eth_address)}
            </UserBoxDescription>
          </UserBoxText>
        </Box>
        <Box component="span" sx={{ display: { xs: "none", sm: "inline-block" } }} onClick={handleOpen}>
          <ExpandMoreTwoToneIcon className="expand-icon" />
        </Box>
      </UserBoxButton>

      
      <CustomPopover
        disableScrollLock
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuUserBox className="dropdown-settings-popper" display="flex" sx={{ top: "100px !important", flexDirection: 'column' }}>
          {
            user.login && user.data.completed_onboarding &&
            <List sx={{pb:0}} component="nav">
              <ListItem
                onClick={() => {
                  handleClose();
                }}
                to={`/profile`}
                className="list"
                component={NavLink}
                sx={{borderBottom: user.data?.membership_program?.role === 'admin' ? "1px solid rgba(26, 22, 22, 0.1) !important" : "0px solid"}}
              >
                <ListItemText primary="Profile" sx={{ textAlign: "left" }} className="list-item" />
              </ListItem>
              {
                user.data?.membership_program?.role !== 'admin' &&
                <ListItem
                  onClick={() => {
                    handleClose();
                  }}
                  to={`/settings`}
                  className="list"
                  component={NavLink}
                  sx={{borderBottom: "1px solid rgba(26, 22, 22, 0.1) !important"}}
                >
                  <ListItemText primary="Settings" sx={{ textAlign: "left" }} className="list-item" />
                </ListItem>
              }
            </List>
          }
          <List sx={{pt: user.login && user.data.completed_onboarding ? 0 : '12px'}} component="nav">
            <ListItem onClick={() => { handleClose(); }} component={Link} href="https://help.step3.io" target="_blank" sx={{ cursor: "pointer", borderBottom: "1px solid rgba(26, 22, 22, 0.1) !important", '&:hover': {textDecoration: 'none'} }} className="list">
              <ListItemText primary="Support and FAQs" sx={{ textAlign: "left" }} className="list-item" />
            </ListItem>
            
            <ListItem onClick={handleLogout} sx={{ cursor: "pointer", borderBottom: "0px solid" }} className="list">
              <ListItemText primary="Log Out" sx={{ textAlign: "left", borderBottom: "0px solid" }} className="list-item" />
            </ListItem>
          </List>
        </MenuUserBox>
      </CustomPopover>
      
    </Box>
  );
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {
  logoutUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderUserbox);
