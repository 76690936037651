import React,{useEffect, useState} from 'react';
import {connect} from 'react-redux';
import { Container, Grid, Box, styled, Typography } from '@mui/material';
import PreviewDropBox from 'src/components/DropItem/preview';
import RegisterWizard from '../formwizard';
import { useTheme } from '@mui/material';
import { createRewardDrop, updateRewardDrop, setRewardCRD_DB, setRewardCRD, resetRewardCRD} from '../../services/actions'
import { strTosnakeCase } from 'src/utils';
import { useLocation } from 'react-router-dom';
import {gtag} from "../../utils";

const DropPreviewBox = styled(Box)(
  ({ theme }) => `
    background: ${theme.sidebar.background};
    height: calc(100vh - ${theme.header.height});
    overflow-y: auto;
    border-top: 1px solid black;
`
);
const NewDrop = (props) => {
  const location = useLocation();
  const rewardtype = location?.state?.reward_type;
  const reward_drop_data = location?.state?.reward_drop_data;
  const is_duplicate = location?.state?.is_duplicate;
  const theme = useTheme();
  const {user} = props;

  useEffect(()=>{
    if (typeof reward_drop_data === 'undefined' && rewardtype === 'membership'){
      let existing_data = {...props.rewardCRD.data};
      existing_data['community_type'] = 'membership';
      existing_data['sponsor_collections'] = [user.data?.membership_sponsordetail]
      props.setRewardCRD_DB(existing_data)
    } else {
      props.setRewardCRD_DB(reward_drop_data);
    }
  },[])

  const [crumbs, setCrumbs] = useState([])

  const [complete,setComplete] = useState(false)

  const handleData = (type, val) => {
    let existing_data = {...props.rewardCRD.data};
    let dropCommunityType = existing_data.community_type;

    if (type === 'community_type' && dropCommunityType !== '' && dropCommunityType !== val) {
      existing_data[type] = val
      existing_data['sponsors'] = []
      props.setRewardCRD(existing_data)
      return
    }
    existing_data[type] = val
    if (type === 'is_limited_quantity') {
      existing_data['is_limited_quantity'] = (val === 'true' || val === true)
    }

    if (type === 'schedule_now') {
      existing_data['schedule_now'] = (val === 'true' || val === true)
    }
    // if (type === 'is_coming_soon') {
    //   existing_data['is_coming_soon'] = (val === 'on')
    // }

    if(type=='image_remove' && val == true){
      existing_data['image'] = "";
    }
    if(type=='image'){
      existing_data['image'] = val;
    }
    props.setRewardCRD(existing_data)
  }

  const handleMultipleChilddata = (obj) => {
    let existing_data = {...props.rewardCRD.data};

    for (let key in obj) {
      existing_data[key] = obj[key]
    }

    props.setRewardCRD(existing_data)
  }

  const setBreadCrumbs = (text) => {
    return
  }

  const formPublished = () => {
    setComplete(true)
  }

  const submitFormData = async () => {
    const new_drop_data = {
      drop_type: props.rewardCRD.data.category.split(/\.?(?=[A-Z])/).join('_').toLowerCase(),
      community_type: props.rewardCRD.data.community_type
    }
    if(props.rewardCRD.data.sponsors.length > 0) {
      new_drop_data['sponsor_collections'] = props.rewardCRD.data.sponsors.map(sponsor => sponsor.id)
      new_drop_data['collection_with_traits'] = props.rewardCRD.data.sponsors
    }

    new_drop_data['is_one_time_use'] = true

    const reward_drop = await props.createRewardDrop(new_drop_data);
    if (reward_drop.type === "success") {
      gtag('reward_created',{
        reward_type: reward_drop.data.drop_type,
        eth_address: ` ${user.data.eth_address} `
      });

      setTimeout(()=>{
        handleData('reward_drop_id', reward_drop.data.id)
      },200)
      // for raffel reward  set All NFTs as quantity
      // handleData('is_limited_quantity', props.rewardCRD.data.is_limited_quantity ? "true" : "false")
    }
  };

  const updateFormData = async (allow_publish, step, multiStep) => {
    const new_drop_data = {
      drop_type: strTosnakeCase(props.rewardCRD.data.category),
      // sponsor_collections: props.rewardCRD.data.sponsors.map(sponsor => sponsor.id),
      community_type: props.rewardCRD.data.community_type,
      title: props.rewardCRD.data.title,
      details: props.rewardCRD.data.description,
      unit_cost: props.rewardCRD.data.unit_cost,
      quantity: props.rewardCRD.data.quantity && parseInt(props.rewardCRD.data.quantity),
      allow_reward_page: props.rewardCRD?.data?.allow_reward_page ? props.rewardCRD.data.allow_reward_page : true,
      allow_embed_page: props.rewardCRD?.data?.allow_embed_page ? props.rewardCRD.data.allow_embed_page : true,
      is_limited_quantity: (props.rewardCRD.data.is_limited_quantity === null) ? (props.rewardCRD.data.category === 'raffle') ? false : true : props.rewardCRD.data.is_limited_quantity,
      is_one_time_use: props.rewardCRD.data.is_one_time_use,
      allow_gift: props.rewardCRD.data.allow_gift,
      is_schedule_now: props.rewardCRD.data.schedule_now,
      allow_reward_end_date: props.rewardCRD.data.schedule_now,
      is_coming_soon: props.rewardCRD.data.is_coming_soon
    };
    if(props.rewardCRD.data.sponsors.length > 0) {
      new_drop_data['sponsor_collections'] = props.rewardCRD.data.sponsors.map(sponsor => sponsor.id)
      new_drop_data['collection_with_traits'] = props.rewardCRD.data.sponsors
    }
    new_drop_data['claim_condition'] = props.rewardCRD.data.claim_condition;
    new_drop_data['claim_condition_message'] = props.rewardCRD.data.claim_condition_message;
    if ((step == 0 && multiStep == 3) && props.rewardCRD.data.sponsors.length == 0){
      new_drop_data['sponsor_collections'] = []
    }
    if(props.rewardCRD.data.couponCodes && props.rewardCRD.data.category == 'coupon_code'){
      new_drop_data.drop_type_data = [props.rewardCRD.data.couponCodes]
    }
    if(props.rewardCRD.data.urls && props.rewardCRD.data.category == 'gated_content'){
      new_drop_data.drop_type_data = props.rewardCRD.data.urls;
    }
    if(props.rewardCRD.data.urls && props.rewardCRD.data.category == 'event_pass'){
      new_drop_data.drop_type_data = [props.rewardCRD.data.eventPass];
    }
    if(props.rewardCRD.data.merchandise && props.rewardCRD.data.category == 'merchandise'){
      new_drop_data.drop_type_data = [props.rewardCRD.data.merchandise];
    }
    if(props.rewardCRD.data.raffle && props.rewardCRD.data.category == 'raffle'){
      new_drop_data.drop_type_data = [props.rewardCRD.data.raffle];
    }
    if(props.rewardCRD.data.allowList && props.rewardCRD.data.category == 'allow_list'){
      new_drop_data.drop_type_data = [props.rewardCRD.data.allowList];
    }
    if(props.rewardCRD.data.poll && props.rewardCRD.data.category == 'poll'){
      new_drop_data.drop_type_data = [props.rewardCRD.data.poll];
    }
    if(props.rewardCRD.data.custom && props.rewardCRD.data.category == 'custom'){
      new_drop_data.drop_type_data = [props.rewardCRD.data.custom];
    }
    new_drop_data.end_date = (props.rewardCRD.data.endsIn == "Invalid date") ? "" : props.rewardCRD.data.endsIn;
    new_drop_data.start_date = (props.rewardCRD.data.startAt == "Invalid date") ? "" : props.rewardCRD.data.startAt;
    if (allow_publish === 'publish') {
      new_drop_data.publish_now = true;
    }

    if ( props.rewardCRD.data.image_remove || props.rewardCRD.data.image_remove === false) {
      new_drop_data.image_remove = props.rewardCRD.data.image_remove;
    }
    
    if (props.rewardCRD.data.image) {
      new_drop_data.image = props.rewardCRD.data.image;
    }
    const updated_reward_drop = await props.updateRewardDrop(new_drop_data, props.rewardCRD.data.reward_drop_id);
  };
  return (
    <>
    {props.rewardCRD.type!="" &&
    <Grid container>
      <Grid item xs={12} md={12} sx={{
      }}>
        <Container maxWidth="md">
        {Object.keys(props.rewardCRD.data).length > 0 &&
          <RegisterWizard rewardtype={rewardtype} setCrumbs={setBreadCrumbs} breadCrumbs={crumbs} key="a" formComplete={complete} setFormComplete={formPublished} handleChildData={handleData} handleMultipleChilddata={handleMultipleChilddata} dropData={props.rewardCRD.data} submitFormData={submitFormData} updateFormData={updateFormData} is_duplicate={is_duplicate} />
        }
        </Container>
      </Grid>
    </Grid>
    }
    </>
  )
}

const mapStateToProps = (state) => ({
  rewardCRD: state.rewardCRD,
  user: state.user,
})

const mapDispatchToProps = {
  createRewardDrop, updateRewardDrop, setRewardCRD_DB, setRewardCRD, resetRewardCRD
}

export default connect(mapStateToProps, mapDispatchToProps)(NewDrop)