import React, {useState,useEffect} from 'react';
import { Modal, Box, Typography, TextField, Button,Link,styled } from '@mui/material';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { connect } from 'react-redux';

import {useNavigate} from 'react-router-dom'

import { addCollection,resetCollectionCDState } from 'src/services/actions';

import {gtag} from "../../utils";
import NetworkSelectionOptions from '../NetworkSelectionOptns';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  border: '0px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: "6px"
};

const NewCollection = (props) => {
  const { open, handleClose, user, handleStep2Open } = props;
  const [network, setNetwork] = useState('');
  const [contractAddress, setContractAddress] = useState('');

  const [error,setError] = useState('')
  const [errorStatus,setErrorStatus] = useState(false)

  const {collection} = props

  const handleChange = (event) => {
    setNetwork(event.target.value)
  };

  const addCollectionHandler = async() => {
    if(!network == ''){
      setError('')
      setErrorStatus(false)
      const data = {
        chain_id : network,
        collection_address : contractAddress
      }

      props.addCollection(data);

      gtag('collection_created',{
        eth_address: ` ${user.data.eth_address} `,
        network_id: network
      });

      
      if (collection.contract_type == 'ERC1155') {
        console.log("open step2 modal")
        handleStep2Open()
      }
      if(collection.new_collection_status === 'success'){
        setNetwork('')
        setContractAddress('')
        
      }
    }else{
      setError('Please select a network')
      setErrorStatus(true)
    }

  }
  const SelectBox = styled(Select) ({
    '& .MuiSelect-select.MuiSelect-outlined': {
      display: 'flex',
    }
  });

  useEffect(()=>{
    props.resetCollectionCDState();
  },[]);
  // console.log("collection.new_collection_status =>",collection.new_collection_status)
  useEffect(() => {
    collection.new_collection_status == 'success' && contractAddress !== '' && collection.contract_type === 'ERC1155' && handleStep2Open(contractAddress,network)
  },[collection.new_collection_status])

 let close = ()=>{
  props.resetCollectionCDState();
  handleClose();
  
 }

  return (
    <Modal
      open={open}
      onClose={()=>{ return collection.new_collection_status == 'request' ?  false : close() }}
      aria-labelledby="New Form Field"
      className='preview-modal'
    >
      <Box sx={style} className='modal-border'>
        <Typography variant="h3" className="heading">Add a new collection</Typography>
        <Typography variant="body1" mt={2} sx={{lineHeight: "140%",fontFamily: "Roboto, sans-serif",fontWeight: "400", fontSize: "1rem",color: "#223354"}}>Paste the NFT collection address and we'll fill in the details. Not sure where to find the NFT collection address? <Link href="https://help.step3.io/managing-nft-collections/4KSRw7GELtfqFffE4SqkZ6/how-to-use-nft-collections-for-rewards/hqz6YYQ7EwWxXLJBVKYnkx" sx={{fontFamily:"Roboto, sans-serif", fontWeight: "700", fontSize: "1rem"}} target="_blank">Learn more about adding collections</Link>.</Typography>

        <Box mt={3}>
          
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label" sx={{mt: (network == "") ? "-7px" : ""}}>Network</InputLabel>
            <NetworkSelectionOptions handleChange={handleChange} errorStatus={errorStatus} network={network} />
            <Typography variant="body2" sx={{pl:1,mb:2,color: '#FF1A43', fontSize: '0.75rem'}}>{error}</Typography>

            <TextField sx={{mt:2}} required fullWidth label='Collection Address' name="quantity" variant="outlined" InputLabelProps={{shrink: true}} placeholder="Paste the NFT collection address or OpenSea URL" error={collection.new_collection_status == 'error'} value={contractAddress} onChange={(e) => {
              setContractAddress(e.target.value)
            }}/>

            {collection.new_collection_status == 'error' && <Typography variant="body2" sx={{pl:1,mb:2,color: '#FF1A43', fontSize: '0.75rem'}}>{collection.message}</Typography>}
            {collection.new_collection_status == 'request' && <Typography sx={{color:'#1A75FF', fontSize: '0.75rem',pt:2}} align="center">Stand by while we collect information from the blockchain...</Typography>}
          </FormControl>
        </Box>

        <Box display={'flex'} justifyContent={'space-between'} mt={5}>
          <Button color="primary" onClick={handleClose} className='btn-text'>Cancel</Button>
          {collection.new_collection_status == 'request' ?
            <Button variant="contained" color="primary" sx={{cursor: 'disabled', opacity: '0.5',borderRadius:' 6px', padding:'0.5rem 1.4rem',fontFamily: "Roboto,sans-serif", fontWeight: "700", fontSize: "0.938rem"}}>Adding...</Button>
            :
            <Button variant="contained" sx={{borderRadius:' 6px', padding:'0.5rem 1.4rem',fontFamily: "Roboto,sans-serif", fontWeight: "700", fontSize: "0.938rem", px:3}} onClick={addCollectionHandler}>Add Collection</Button>
          }
        </Box>
      </Box>
    </Modal>
  )
}


const mapStateToProps = (state) => ({
  collection : state.collections,
  user: state.user,
})

const mapDispatchToProps = {
  addCollection,
  resetCollectionCDState
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCollection)