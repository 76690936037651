import detectEthereumProvider from '@metamask/detect-provider';
import Web3 from 'web3';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk'
import WalletConnectProvider from "@walletconnect/web3-provider";
import { EthereumProvider } from '@walletconnect/ethereum-provider';


import chainObject from "../services/constants/chains.json";
const ethereum = window.ethereum;
export const web3 = new Web3();
window.web3Obj = web3;

export async function connectWalletv2(callback,dispatch){
  localStorage.clear();
  const projectId = process.env.REACT_APP_WALLET_CONNECT_V2;
  let chainObj = {}
  // for (let index = 0; index < chainObject.length; index++) {
  //   const element = chainObject[index];
  //     // chainObj[element.chainId] = element.rpc[0];
  //   // if(chain_id == element.chainId){
  //     chainObj[element.chainId] = element.rpc[0];
  //   // }
  // }
  const provider = await EthereumProvider.init({
    projectId:projectId, // REQUIRED your projectId
    chains:[1], // REQUIRED chain ids
    optionalChains: [5, 56, 137, 10, 100], // chains added to optional namespaces
    showQrModal:true, // REQUIRED set to "true" to use @web3modal/standalone,
    // methods, // OPTIONAL ethereum methods
    // events, // OPTIONAL ethereum events
    // rpcMap, // OPTIONAL rpc urls for each chain
    // metadata, // OPTIONAL metadata of your app
    // qrModalOptions, // OPTIONAL - `undefined` by default, see https://docs.walletconnect.com/2.0/web3modal/options
  });


  console.log("provider.session",provider.session);
  console.log("provider",provider);
  
  provider.modal.setTheme({
    themeVariables: {
      "--w3m-font-family": "Roboto, sans-serif",
      "--w3m-accent-color": "#ed2123",
      "--w3m-accent-fill-color": "#ed2123",
      "--w3m-background-color": "#ed2123",
      "--wcm-background-color": "#ed2123",
      "--wcm-accent-color": "#ed2123",
      "--w3m-z-index": "3000",
    },
  });

  try{
    await provider.connect()
  }catch(e){
    dispatch({type:"LOGIN_RESET"});
    return;
  }
//   .catch((error)=>{
//     // dispatch({type:"LOGIN_RESET"});
// });

  let accounts = await provider.enable().catch((error)=>{
      dispatch({type:"LOGIN_RESET"});
  });
console.log("accounts",accounts);

  // if(accounts && parseInt(provider.chainId) != parseInt(chain_id)){
  //   return callback({type:"error",message: parseInt(chain_id) ==1 ? "Please change your network to Ethereum Mainnet." : "Please change your network to Polygon Testnet Mumbai."});
  // }
  web3.setProvider(provider);  
  let a = await web3.eth.getAccounts();
  console.log("Web3",a);
  // let sig1 = await web3.eth.sign("Dhiraj", a[0]);
  // console.log("Web3",sig1);
  
  web3.eth.net.getNetworkType().then(async(network_type) => {
    let chain = chainObject.find(v=> v.chainId == provider.chainId);
    let network = {}
    network['type'] = chain.network;
    network['chainId'] = chain.chainId;
    callback({type:"success",data:{network:network,eth_address:accounts[0]}});
  })

  
  provider.on("chainChanged", (chainId)=>{
    console.log("chainChanged",chainId);
    let network = {}
    // web3.eth.net.getNetworkType().then(async(network_type) => {
    //   let chain = chainObject.find(v=> v.chainId == chainId);
    //   network['type'] = chain.network;
    //   network['chainId'] = chain.chainId;
    //   network['networkVersion'] = "";
    //   dispatch({type:"NETWORK_CHANGE",network:network});
    // });
  });
  // accounts changed
  provider.on("accountsChanged", (e)=>{
    console.log("accountsChanged",e);
    window.location.reload();
  });
  // session established
  provider.on("connect", (e)=>{
    console.log("connect",e);
  });
  // session event - chainChanged/accountsChanged/custom events
  provider.on("session_event", (e)=>{
    console.log("session_event",e);
  });
  // connection uri
  provider.on("display_uri", (uri)=>{
    console.log("display_uri",uri);
  });
  // session disconnect
  provider.on("disconnect", (e)=>{
    console.log("disconnect");
    localStorage.clear();
    window.location.reload();
  });
  window.addEventListener('logoutAll', async function(event) {
    await provider.disconnect()
  });

}

export async function connectWallet(callback,dispatch){
  let chainObj = {}
  for (let index = 0; index < chainObject.length; index++) {
    const element = chainObject[index];
      chainObj[element.chainId] = element.rpc[0];
  }

  let provider = new WalletConnectProvider({
    rpc: chainObj, 
  });
  
  await provider.close().catch(async(error)=>{
    
  })

  provider = new WalletConnectProvider({
    rpc: chainObj, 
  });
  let accounts = await provider.enable().catch((error)=>{
      dispatch({type:"LOGIN_RESET"});
  });
  web3.setProvider(provider);  

  web3.eth.net.getNetworkType().then(async(network_type) => {
    let chain = chainObject.find(v=> v.chainId == provider.chainId);
    let network = {}
    network['type'] = chain.network;
    network['chainId'] = chain.chainId;
    callback({type:"success",data:{network:network,eth_address:accounts[0]}});
  })

  provider.on("accountsChanged", (accounts) => {
    window.location.reload();
  });

  // Subscribe to chainId change
  provider.on("chainChanged", (chainId) => {
    let network = {}
    web3.eth.net.getNetworkType().then(async(network_type) => {
      let chain = chainObject.find(v=> v.chainId == chainId);
      network['type'] = chain.network;
      network['chainId'] = chain.chainId;
      network['networkVersion'] = "";
      dispatch({type:"NETWORK_CHANGE",network:network});
    });
  });
  // Subscribe to session disconnection
  provider.on("disconnect", (code, reason) => {
    window.location.reload();
  });
}


export async function connectMetamask(callback){
  if ((typeof window.ethereum === 'undefined' && typeof ethereum?.providers == 'undefined') || (ethereum.isCoinbaseWallet && !ethereum.isMetaMask)) {
    window.open("https://metamask.app.link/dapp/" +window.location.host);
    callback({type:"error",message: "Please install Metamask extension."});
    return
  }
  if (typeof ethereum.providers != 'undefined') {
    const walletProvider = ethereum.providers.find(({ isMetaMask }) => isMetaMask);
    if(ethereum.setSelectedProvider){ethereum.setSelectedProvider(walletProvider);}
  }
  
  ethereum.request({ method: 'eth_requestAccounts' }).then(accounts => {
    ethereum.on('accountsChanged', function (accounts) {
      window.location.reload();
    });

    if (ethereum.isMetaMask && ethereum.selectedAddress != null) { 
        detectEthereumProvider().then(async(provider)=>{
          web3.setProvider(provider);
          // web3.eth.net.getNetworkType().then(async(network_type) => {
            let network = {}
            // network['type'] = network_type;
            network['chainId'] = await ethereum.request({ method: 'eth_chainId' });
            network['networkVersion'] = await ethereum.request({ method: 'net_version' });
            let account = await ethereum.request({ method: 'eth_requestAccounts' });
            callback({type:"success",data:{network:network,eth_address:account[0]}});
          // })
        });
    }else{
      callback({type:"error",message: "Some error occurred!"});
    } 
  }).catch((error)=>{
    callback({type:"error",message: error.message});
  });
}

export async function connectCoinbase(callback){
  const coinbaseWallet = new CoinbaseWalletSDK({
    appName: "Step3",
    darkMode: false
  });

  const ethereumObj = coinbaseWallet.makeWeb3Provider("https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa44561611", 1);
  let chainObj = {}
  for (let index = 0; index < chainObject.length; index++) {
    const element = chainObject[index];
      chainObj[element.chainId] = element.rpc[0];
  }
  web3.setProvider(ethereumObj);
  ethereumObj.request({ method: 'eth_requestAccounts' }).then(async accounts => {
    
    ethereumObj.on('accountsChanged', function (accounts) {
      window.location.reload();
    });
    if(accounts.length > 0){
      let network = {}
      network['chainId'] = await ethereumObj.request({ method: 'eth_chainId' });
      network['networkVersion'] = await ethereumObj.request({ method: 'net_version' });
      let account = await ethereumObj.request({ method: 'eth_requestAccounts' });
      callback({type:"success",data:{network:network,eth_address:account[0]}});
    } else {
      callback({type:"error",message: "Some error occurred!"});
    }
  }).catch(error => {
    callback({type:"error",message: error.message});
  });
}

export async function web3signature(message,address,callback,type='personal'){
  try{
    var signature;
    if(type=='personal'){
      signature = await web3.eth.personal.sign(message, address);
    }else{
      try{
        signature = await web3.eth.sign(message, address);
      }catch(e){
        signature = await web3.eth.personal.sign(message, address);
      }
    }
   
   
    callback({type:"success",data:{signature}});
  }catch(error){
    callback({type:"error",message: error.message});
  }
}

export function getHaxChainId(id){
  return "0x"+(id).toString(16)
}

export async function walletAddEthereumChain(chainId,callback){
  chainId = parseInt(chainId);
  let chain = chainObject.find(v=> v.chainId == chainId);
  // wallet_addEthereumChain
  let object = { 
    chainId: getHaxChainId(chainId), // A 0x-prefixed hexadecimal string
    chain: "",
    nativeCurrency: {
      name: "",
      symbol: "",
      decimals: 18
    },
    rpcUrls: ["https://rpc-mainnet.maticvigil.com/"],
    blockExplorerUrls: ["https://polygonscan.com/"],
    iconUrls: ["https://polygon.technology/wp-content/uploads/2021/02/cropped-polygon-ico-32x32.png"]
  }

  if(!chain){
    callback({type:"error",message:"network chain - "+chainId+" not found!"});
    return 
  }else{
    object={ 
      chainId: getHaxChainId(chain.chainId), // A 0x-prefixed hexadecimal string
      chainName: chain.name,
      nativeCurrency: chain.nativeCurrency,
      rpcUrls: chain.rpc,
      blockExplorerUrls: (chain.explorers.length >0 ? chain.explorers.map((e)=>e.url) : null),
      iconUrls: ["https://polygon.technology/wp-content/uploads/2021/02/cropped-polygon-ico-32x32.png"]
    }
  }
  try {
    await ethereum.request({
      method: 'wallet_addEthereumChain',
      params: [object],
    });
    callback({type:"success",message:"wallet added to chain"});
  } catch (addError) {
    callback({type:"error",message:addError.message});
  }
}

export async function walletSwitchEthereumChain(chainID,provider,callback){
  if(provider == 'metamask'){
    try {
      await ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: getHaxChainId(parseInt(chainID)) }],
      });
      callback({type:"success",message:"network get switched to "+chainID});
    } catch (switchError) {
      if (switchError.code === 4902) {
        walletAddEthereumChain(chainID,(data)=>{
          if(data.type=='success'){
            walletSwitchEthereumChain(chainID,callback);
          }else{
            callback(data);
          }
        });
      }else{
        callback({type:"error",message:switchError.message});
      }
    }
  }
}

export async function getAllTransferPastEvents(contract,fromBlock,toBlock){
  fromBlock = parseInt(fromBlock);
  toBlock = parseInt(toBlock);
  let pastEvents =[];
  try{
   pastEvents = await contract.getPastEvents('Transfer', {fromBlock: fromBlock,toBlock: toBlock});
  }catch(e){
     if(['Returned error: query returned more than 10000 results','Returned error: context deadline exceeded','Returned error: request expired','query returned more than 10000 results'].includes(e.message)){
        const middle = Math.round((fromBlock + toBlock) / 2);
        let fEvents =  await getAllTransferPastEvents(contract,fromBlock, middle);
        let tEvents = await getAllTransferPastEvents(contract,middle + 1, toBlock);
        pastEvents = [...fEvents,...tEvents];
     }
  }
  pastEvents = pastEvents.sort((a,b) => (a.blockNumber > b.blockNumber) ? 1 : ((b.blockNumber > a.blockNumber) ? -1 : 0))
  return pastEvents
}

async function getOwnedTokens(contract,account) {
  let sentLogs = await contract.getPastEvents("Transfer",{
    filter: {from: account},
    fromBlock: 0,
    toBlock: 'latest'
   })

   let receivedLogs = await contract.getPastEvents("Transfer",{
    filter: {to: account},
    fromBlock: 0,
    toBlock: 'latest'
   })

   
  const logs = sentLogs.concat(receivedLogs)
    .sort(
      (a, b) =>
        a.blockNumber - b.blockNumber ||
        a.transactionIndex - b.TransactionIndex,
    );

  let owned = new Set();

  for (const log of logs) {
    const { from, to, tokenId } = log.returnValues;
    if (addressEqual(to, account)) {
      owned.add(tokenId.toString());
    } else if (addressEqual(from, account)) {
      owned.delete(tokenId.toString());
    }
  }
  owned = [...owned];
  return owned;
};

function addressEqual(to,from){
  return to.toString().toLowerCase() == from.toString().toLowerCase();
}

// helper function 

export function shortenPhoneNumber(num) {
  return `******${num.substr(num.length - 4, 4)}`;
}
export function shortEthAddress(address){
  let addr = web3.utils.toChecksumAddress(address);
  return addr.substr(0,6)+"..."+addr.substr(addr.length-4,4);
}
export function shortEmailAddress(email){
  let addr = email;
  const remaining_addr = addr.split('@').pop();
  if (email.indexOf("@") > 3){
    const name = email.substring(0, email.indexOf("@"));
    return `${name.substr(0,3)}...@${remaining_addr}`;
  } else {
    return email
  }

}

export function shortNum(num){
  if (num.length > 5){
    return num.substr(0,4)+"..."+num.substr(num.length-4,4);
  } else {
    return num
  }

}

export function explorerURL(chainId){
  chainId = parseInt(chainId);
  let chain = chainObject.find(v=> v.chainId == chainId);
  if(chain && chain.explorers.length > 0){
    return chain.explorers[0].url+"/";
  }else{
    return "#/";
  }
} 

export function etherUrl(chainD,url) {
  //  todo chain id base url setup
}

export function openseaUrl(chainD,url) {
    //  todo chain id base url setup
}


export function getNetworkName(chainId){
 chainId = parseInt(chainId);
  let chain = chainObject.find(v=> v.chainId == chainId);
  if(!chain){
    chain={
      "name": "Private",
      "chainId": chainId,
      "shortName": "eth",
      "chain": "ETH",
      "network": "Private",
      "networkId": "",
      "nativeCurrency": {"name":"Ether","symbol":"ETH","decimals":18},
      "rpc": ["https://mainnet.infura.io/v3/${INFURA_API_KEY}","wss://mainnet.infura.io/ws/v3/${INFURA_API_KEY}","https://api.mycryptoapi.com/eth","https://cloudflare-eth.com"],
      "faucets": [],
      "explorers": [],
      "infoURL": "https://ethereum.org"
    }
  }
  return chain
}


export function validateAddress(address){
  try{
     address = web3.utils.toChecksumAddress(address);
    if(web3.utils.checkAddressChecksum(address)){
      return address;
    } 
  } catch (error) {
    
  }
    return "";
  
}

export async function getCurrentBlockNumber(chain_id){
  let ch = chainObject.find(v=>v.chainId == chain_id)
  const web3 = new Web3(new Web3.providers.HttpProvider(ch.rpc[0]))
  const latest = await web3.eth.getBlockNumber();
  return latest
}
 
export function getSoliditySha3(data){
  if(Array.isArray(data)){
    return web3.utils.soliditySha3(...data)
  }else{
    return web3.utils.soliditySha3(data)
  }
}
export async function getWeb3Signature(message,eth_address){
   return await  web3.eth.personal.sign(message,eth_address);
}

export async function getRecoverAddress(message, signature){
  if(typeof message =='object'){
    return await web3.eth.accounts.recover(message);
  }else{
    return await web3.eth.accounts.recover(message, signature);
  }
}