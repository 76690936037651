import { Box, useTheme } from "@mui/material";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
export const AccentHeaderLayout = (props) => {
  const theme = useTheme();
  return (
    <>
      <Header />
      <Sidebar pageThoughtsHeading={props.pageThoughts?.heading} pageThoughtsTxt={props.pageThoughts?.txt} />
      <Box
        sx={{
          position: "relative",
          zIndex: 5,
          flex: 1,
          display: "flex",
          pl: `${parseInt(theme.sidebar.width) + 15}px`,
          overflowY: "auto",
          maxHeight: `calc(100vh - ${theme.header.height});`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Box flexGrow={1}>{props.children}</Box>
        </Box>
      </Box>
    </>
  );
};

export const MainAppLayout = (props) => {
  const theme = useTheme();

  return (
    <>
      <Header />
      <Box
        sx={{
          position: "relative",
          zIndex: 5,
          flex: 1,
          display: "flex",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Box flexGrow={1} sx={{}}>
            {props.children}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export const PreLoginLayout = (props) => {
  const theme = useTheme();

  return (
    <>
      <Header />
      <Box
        sx={{
          position: "relative",
          zIndex: 5,
          flex: 1,
          display: "flex",
          overflowY: "auto",
          maxHeight: `calc(100vh - ${theme.header.height});`,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            width: "100%",
          }}
        >
          <Box flexGrow={1}>
            {props.children}
          </Box>

          <Box component="footer" mt="82px">
            <Footer />
          </Box>
        </Box>
      </Box>
    </>
  );
};
